import React, { useState, useEffect, useRef } from 'react';
import { InputNumber, Select, Alert } from 'antd';
import { Hub } from '@aws-amplify/core';
import { measurementUnits } from '../state/units';
import { convertValueUnits } from '../utils/unit-conversion';
import _uniqueId from 'lodash/uniqueId';

const { Option } = Select;

// default to dimension if no type is provided
export const Measurement = ({
  step = '',
  label = '',
  value = measurementUnits.dimension,
  type = measurementUnits.dimension,
  onChange,
  minimum,
  maximum,
  disabled = false,
  validate = (value) => '',
}) => {
  // unique id so we can track validation globally
  const id = useRef(_uniqueId('measure-')).current;
  const [inputValue, setInputValue] = useState(value.value);
  const [errorMessage, setErrorMessage] = useState('');
  // dynamic precision - more digits for number < 1
  const precision = (value) => {
    const exponent = Number(Math.abs(value).toExponential().split('-')[1]);
    return isNaN(exponent) ? 1 : exponent + 1;
  };

  const roundTo = (value) => {
    // undefined values required for optional min/max numberInput attributes
    if (value === undefined) return value;
    const multiplier = 10 ** precision(value);
    return value < 0
      ? Math.round((value - Number.EPSILON) * multiplier) / multiplier
      : Math.round((value + Number.EPSILON) * multiplier) / multiplier;
  };

  const stepSize = (value) => {
    const p = precision(value);
    return p > 1 ? 1 / 10 ** p : p;
  };

  // width must be set when dynamically populating options
  // stop event propagation avoids problems in control is inside
  // a clickable parent (button)
  const addonAfter = (
    <Select
      style={{ width: '100px' }}
      value={value.units}
      disabled={disabled}
      onClick={(event) => event.stopPropagation()}
      onChange={(newUnits) => {
        const newValue = convertValueUnits({
            value: value.value,
            from: value.units,
            to: newUnits,
        });
        setInputValue(newValue);
        onChange({
          ...value,
          value: newValue,
          units: newUnits,
        });
      }}
    >
      {measurementUnits[type].map((unit) => (
        <Option key={unit} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );

  const convertedMin = minimum
    ? convertValueUnits({
        value: minimum.value,
        from: minimum.units,
        to: value.units,
      })
    : undefined;

  const convertedMax = maximum
    ? convertValueUnits({
        value: maximum.value,
        from: maximum.units,
        to: value.units,
      })
    : undefined;

  const dispatchError = ({ error = false }) => {
    // must have valid step
    if (step) {
      Hub.dispatch('ui', {
        event: 'validation',
        data: { step, id, error },
        message: step,
      });
    }
  };

  const validateMinMax = (value) => {
    if (convertedMin !== undefined && value.value < roundTo(convertedMin)) {
      setErrorMessage(
        `Value must be greater than or equal to ${roundTo(convertedMin)} ${
          value.units
        }`
      );
      dispatchError({ error: true });
      return false;
    }
    if (convertedMax !== undefined && value.value > roundTo(convertedMax)) {
      setErrorMessage(
        `Value must be less than or equal to ${roundTo(convertedMax)} ${
          value.units
        }`
      );
      dispatchError({ error: true });
      return false;
    }
    setErrorMessage('');
    dispatchError({ error: false });
    return true;
  };

  // Not checking NaN as this should be handled in the unit conversion
  // InputNumber control enforces numeric entry
  // InputNumber only calls onValueChange when min <= value <= max
  const onValueChange = (number) => {
    const newValue = { ...value, value: Number(number) };
    setInputValue(newValue.value);
    // min/max validation in part of the component
    if (validateMinMax(newValue)) {
      // optional external custom validator
      const externalValidatorError = validate(newValue);
      externalValidatorError === ''
        ? onChange(newValue)
        : setErrorMessage(externalValidatorError);
    }
  };

  // re-run validation if conditions change
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (disabled) {
      dispatchError({ error: false });
    } else {
      validateMinMax(value);
    }
  }, [disabled, value, minimum, maximum]);

  return (
    <div
      className={`number-input-wrapper ${
        !disabled && errorMessage !== '' ? 'error' : ''
      }`}
    >
      <div className="number-input">
        {label !== '' && <div className="number-input-label">{label}</div>}
        <div className="value-input">
          <InputNumber
            value={inputValue}
            step={stepSize(value.value)}
            formatter={(value) => roundTo(parseFloat(value))}
            onChange={onValueChange}
            disabled={disabled}
          />
        </div>
        <div className="units-select">{addonAfter}</div>
      </div>
      {!disabled && (
        <div className="error-message">
          <Alert type="error" message={errorMessage} />
        </div>
      )}
    </div>
  );
};

Measurement.Power = (props) => <Measurement {...props} type="power" />;
Measurement.Dimension = (props) => <Measurement {...props} type="dimension" />;
Measurement.FlowRate = (props) => <Measurement {...props} type="flowRate" />;
Measurement.FlowRateGas = (props) => (
  <Measurement {...props} type="flowRateGas" />
);
Measurement.FlowRateLiquid = (props) => (
  <Measurement {...props} type="flowRateLiquid" />
);
Measurement.Altitude = (props) => <Measurement {...props} type="altitude" />;
Measurement.Pressure = (props) => <Measurement {...props} type="pressure" />;
Measurement.FlowVelocity = (props) => (
  <Measurement {...props} type="flowVelocity" />
);
Measurement.Temperature = (props) => (
  <Measurement {...props} type="temperature" />
);
Measurement.ThermalResistance = (props) => (
  <Measurement {...props} type="thermalResistance" />
);
Measurement.ThermalImpedance = (props) => (
  <Measurement {...props} type="thermalImpedance" />
);
Measurement.ThermalConductivity = (props) => (
  <Measurement {...props} type="thermalConductivity" />
);

export default Measurement;
