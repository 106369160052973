import React from 'react';
import { Typography } from 'antd';
import './axis-label.less';

const { Text } = Typography;
const orientation = {
  vertical: 0,
  horizontal: 1,
};

const AxisLabel = ({ direction, label, selected }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction === orientation.horizontal ? 'row' : 'column',
        justifyContent: 'space-between',
        marginTop: '16px',
      }}
    >
      <div
        className={`arrow arrow-${
          direction === orientation.vertical ? 'up' : 'left'
        }`}
      />
      <div
        className={`dashed-line-${
          direction === orientation.vertical ? 'vertical' : 'horizontal'
        }`}
      />
      <Text
        strong
        style={{
          color: selected ? 'rgb(244, 106, 37)' : '#878787',
          padding: '16px',
          textAlign: 'center'
        }}
      >
        {label} {direction === orientation.vertical ? '(Y)' : '(X)'}
      </Text>
      <div
        className={`dashed-line-${
          direction === orientation.vertical ? 'vertical' : 'horizontal'
        }`}
      />
      <div
        className={`arrow arrow-${
          direction === orientation.vertical ? 'down' : 'right'
        }`}
      />
    </div>
  );
};

AxisLabel.Vertical = (props) => (
  <AxisLabel {...props} direction={orientation.vertical} />
);
AxisLabel.Horizontal = (props) => (
  <AxisLabel {...props} direction={orientation.horizontal} />
);

export default AxisLabel;
