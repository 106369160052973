import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { isoCountries } from '../../utils/country-codes';

const initialSelectedOption = isoCountries[0];
// onChange handler binds the selection to the wrapping Form.Item
export const CountrySelect = ({
  value = initialSelectedOption,
  onChange = () => {},
}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(value);
  // set initial value in Form.Item
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onChange(selectedOption);
  }, []);

  const selected = selectedOption.value === '' ? null : selectedOption.value;

  return (
    <Select
      defaultValue={selectedOption.value}
      value={selected}
      options={isoCountries}
      placeholder={t('Select country')}
      onChange={(value, option) => {
        setSelectedOption(option);
        onChange(option);
      }}
      showSearch={true}
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};
