// return the initial project state based on set unit type (metric/american)
// set validation ranges
import { envelopes } from '../../state/units';
import { extrusion, mbf, aquasurf } from '../../state/project';
import { convertUnits, convertValueUnitsTo } from '../../utils/unit-conversion';

export const initialSettings = (project) => {
  const { type, baseSize, boundaryConditions } = project;
  const envelope = envelopes[type.toLowerCase()];
  // envelope provides min/max/default values, we only want defaults to set in project
  const {
    ambientTemperature,
    altitude,
    maxTemperatureRise,
    maxPressureDrop,
    maxSize,
  } = envelope.boundaryConditions;

  const envelopeBoundaryConditions =
    project.units === 'METRIC'
      ? {
          ambientTemperature: ambientTemperature.default,
          altitude: altitude.default,
          maxTemperatureRise: maxTemperatureRise.default,
          maxPressureDrop: maxPressureDrop.default,
          maxSize: {
            width: maxSize.width.default,
            length: maxSize.length.default,
            height: maxSize.height.default,
          },
        }
      : {
          ambientTemperature: ambientTemperature.default,
          altitude: convertUnits({ value: altitude.default, to: 'ft' }),
          maxTemperatureRise: maxTemperatureRise.default,
          maxPressureDrop: convertUnits({
            value: maxPressureDrop.default,
            to: 'inH2O',
          }),
          maxSize: {
            width: maxSize.width.default,
            length: maxSize.length.default,
            height: maxSize.height.default,
          },
        };

  // convert to project selected units if not metric
  if (project.units !== 'METRIC') {
    ['width', 'length', 'height'].forEach((key) => {
      envelopeBoundaryConditions.maxSize[key] = convertUnits({
        value: envelopeBoundaryConditions.maxSize[key],
        to: 'in',
      });
    });
  }

  // boundaryConditions and baseSize common to all projects
  project.boundaryConditions = {
    ...boundaryConditions,
    ...envelopeBoundaryConditions,
  };

  const baseThicknessMap = new Map([
    ['AQUASURF', 14.1],
    ['EXTRUSION', 12.7],
    ['MBF', 12.7],
  ]);

  const defaultBaseThickness = baseThicknessMap.get(type); // original default was 20mm

  let baseThickness = {
    type: 'DIMENSION',
    value: defaultBaseThickness,
    units: 'mm',
  };

  if (project.units !== 'METRIC') {
    baseThickness = convertUnits({ value: baseThickness, to: 'in' });
  }

  project.baseSize = {
    ...baseSize,
    ...envelopeBoundaryConditions.maxSize,
    height: baseThickness,
  };

  // flow rate is calculated from base size
  // this is incorrect, flow rate uses frontal area (W x H) not base area (W x L)
  const calcDefaultFlowRate = (velocity) => {
    let hskHeight;
    if (project.type === 'EXTRUSION') {
      hskHeight = convertValueUnitsTo({
        value: project.boundaryConditions.maxSize.height,
        to: 'm',
      });
    } else {
      hskHeight =
        convertValueUnitsTo({
          value: project.boundaryConditions.maxSize.height,
          to: 'm',
        }) + convertValueUnitsTo({ value: project.baseSize.height, to: 'm' });
    }
    const value =
      convertValueUnitsTo({ value: velocity, to: 'm/s' }) *
      convertValueUnitsTo({ value: project.baseSize.width, to: 'm' }) *
      hskHeight;
    return { type: 'FLOWRATE', value, units: 'm3/s' };
  };

  // default values from envelope
  const { flowRateVelocity, velocity } = envelope.airFlow;
  // these settings are project type specific
  switch (type) {
    case 'EXTRUSION':
      project.extrusion = {
        ...extrusion,
        airFlow: {
          ...extrusion.airFlow,
          flowRate:
            project.units === 'METRIC'
              ? calcDefaultFlowRate(flowRateVelocity.default)
              : convertUnits({
                  value: calcDefaultFlowRate(flowRateVelocity.default),
                  to: 'cfm',
                }),
          velocity:
            project.units === 'METRIC'
              ? velocity.default
              : convertUnits({ value: velocity.default, to: 'ft/s' }),
        },
      };
      break;

    case 'MBF':
      project.mbf = {
        ...mbf,
        airFlow: {
          ...mbf.airFlow,
          flowRate:
            project.units === 'METRIC'
              ? calcDefaultFlowRate(flowRateVelocity.default)
              : convertUnits({
                  value: calcDefaultFlowRate(flowRateVelocity.default),
                  to: 'cfm',
                }),
          velocity:
            project.units === 'METRIC'
              ? velocity.default
              : convertUnits({ value: velocity.default, to: 'ft/s' }),
        },
      };
      break;

    case 'AQUASURF':
      project.aquasurf = aquasurf;
      break;

    default:
    // nothing
  }
  return project;
};
