import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectState } from '../../state/project';
import { ExtrusionSelector } from '../ExtrusionSelector';
import Baseplate from './Baseplate';
import Controls from './Controls';
import { viewState } from '../../state/view';

export const HeatSourceLayout = ({ disabled }) => {
  const project = useRecoilValue(projectState);
  const [view, setView] = useRecoilState(viewState);

  // if user cancels without selecting an extrusion take them back to boundary conditions
  const setSelectedStep = () =>
    setView({
      ...view,
      progress: 0,
      selectedStep: 'boundaryConditions',
    });

  // force user to select an extrusion as this is required for boundary conditions
  // settings are handled in the ExtrusionSelector handler
  const showExtrusionModal =
    project.type === 'EXTRUSION' &&
    view.selectedStep === 'sourceLayout' &&
    project.extrusion.id === 'new-extrusion';

  return (
    <div className="heat-source-layout-wrapper">
      <div />
      <div>
        {!disabled && showExtrusionModal && (
          <ExtrusionSelector
            showModal={showExtrusionModal}
            onCancel={setSelectedStep}
          />
        )}
        {!disabled && <Controls />}
        <Baseplate disabled={disabled} />
      </div>
      <div />
    </div>
  );
};

export default HeatSourceLayout;
