import React from 'react';
import { useRecoilValue } from 'recoil';
import { viewState } from '../../state/view';
import { projectState } from '../../state/project';
import { isSignedIn } from '../../state/user';
import { workflows } from './workflows';
import { Bar } from './Bar';
import { Navigation } from './Navigation';

export const Design = () => {
  const { progress } = useRecoilValue(viewState);
  const project = useRecoilValue(projectState);
  const userIsSignedIn = useRecoilValue(isSignedIn);
  const workflow = workflows[project.type];

  return userIsSignedIn ? (
    <>
      <div className="bar">
        <Bar workflow={workflow} />
      </div>
      <div className="main has-bar">
        <Navigation />
        <div className="content-wrapper">
          <div />
          <div className="content">
            {workflow.map(({ view }, index) => (
              <div
                key={`view-${index}`}
                style={{
                  display: progress === index ? 'block' : 'none',
                }}
              >
                {view}
              </div>
            ))}
          </div>
          <div />
        </div>
      </div>
    </>
  ) : null;
};
