import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import { projectState } from '../../../../state/project';
import { SectionHeading } from '../../../SectionHeading';
import { LabeledValue } from '../../LabeledValue';
import { convertToMillimeters } from '../../../../utils/unit-conversion';

const { Text } = Typography;

export const Pipes = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);

  const {
    tubeMaterial,
    fluid,
    percentageWater,
    inletTemperature,
    tubeOuterDiameter,
    pipeConfiguration,
    flowRate,
    embeddedPosition,
    pipes,
  } = project.aquasurf;

  const tubeOuterDiameterText = {
    '0.25': '1/4" (6.35mm)',
    '0.375': '3/8" (9.53mm)',
    '0.5': '1/2" (12.7mm)',
    '0.625': '5/8" (15.88mm)',
  };

  const sortedPipesByX = pipes
    .map((pipe) => pipe)
    .sort((a, b) => convertToMillimeters(a.x) - convertToMillimeters(b.x));

  return (
    <>
      <div className="section-wrapper">
        <SectionHeading label={t('Pipe Configuration')} />
        <section>
          <Row>
            <Col span={8}>
              <LabeledValue
                label={t('Inlet Temperature')}
                value={inletTemperature}
              />
              <LabeledValue label={t('Flow Rate')} value={flowRate} />
              <LabeledValue
                label={t('Embedded Position')}
                value={t(embeddedPosition)}
              />
            </Col>
            <Col span={8}>
              <LabeledValue
                label={t('Tube Outer Diameter')}
                value={tubeOuterDiameterText[tubeOuterDiameter.toString()]}
              />
              <LabeledValue
                label={t('Tube Material')}
                value={t(tubeMaterial)}
              />
              {['EGLYCOL', 'PGLYCOL'].includes(fluid) ? (
                <LabeledValue
                  size="200px"
                  label={t('Fluid')}
                  value={`${t(fluid)} ${percentageWater}%`}
                />
              ) : (
                <LabeledValue label={t('Fluid')} value={t(fluid)} />
              )}
            </Col>
          </Row>
        </section>
      </div>
      <div className="section-wrapper">
        <SectionHeading label={t('Pipe Layout')} />
        <section>
          <Row>
            <Col span={8}>
              <LabeledValue
                label={t('Configuration')}
                value={t(pipeConfiguration)}
              />
            </Col>{' '}
            <Col span={8}>
              <LabeledValue label={t('Number of Pipes')} value={pipes.length} />
            </Col>
          </Row>
        </section>
      </div>
      <div className="section-wrapper">
        <SectionHeading label={t('Pipes')} />
        <section>
          <Row>
            <Col span={4}>
              <Text strong>{t('Label')}</Text>
            </Col>
            <Col span={8}>
              <Text strong>{t('Position (X)')}</Text>
            </Col>
          </Row>
          {sortedPipesByX.map(({ x }, index) => (
            <Row key={index}>
              <Col span={4}>
                <Text>{index + 1}</Text>
              </Col>
              <Col span={8}>
                <Text>
                  {x.value.toFixed(1)}
                  {x.units}
                </Text>
              </Col>
            </Row>
          ))}
        </section>
      </div>
    </>
  );
};
