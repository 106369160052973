import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { hasPermission } from '../../utils/permissions';
import { useRecoilValue } from 'recoil';
import { userState } from '../../state/user';

// labels should be available in i18n translation
const options = [
  {
    value: 'MBF',
    label: 'Air Cooled Mechanically Bonded Fin (MBF)',
    tooltip:
      'Mechanically Bonded Fin (MBF) air cooled heatsinks are a construction of a baseplate and fins. Currently provided in R-TOOLS MAXX are heatsinks bonded by patented swaging technique, allowing metal on metal contact for optimal thermal performance, at larger fin height to fin gap ratios than extrusions can provide. If you require assistance on design or are interested in different bonding agents such as glue or soldering, please contact the appropriate engineering group via the “Help” tab in the top right of your screen.',
  },
  {
    value: 'EXTRUSION',
    label: 'Air Cooled Extrusion',
    tooltip:
      'Extruded heat sinks are unique profiles that provide the most cost-effective cooling solution. What is provided in R-TOOLS MAXX are standard profiles designed for specific requirements. If you require a custom profile or adjustment to provided standard profile, please contact the appropriate engineering group via the “Help” tab in the top right of your screen.',
  },
  {
    value: 'AQUASURF',
    label: 'Aquasurf (Liquid Cold Plates)',
    tooltip:
      'R-TOOLS MAXX Aquasurf (Liquid Cold Plates) allow for tube-based Coldplate design in both parallel and serpentine flow paths using a variety of customizable options such as tube material and type of liquid. If you require assistance on design or are interested in different liquid cooling options such as our vacuum brazed product line, please contact the appropriate engineering group via the “Help” tab in the top right of your screen.',
  },
];

const initialSelectedOption = options[0];

// onChange handler binds the selection to the wrapping Form.Item
export const ProjectTypeSelect = ({
  value = initialSelectedOption.value,
  onChange = () => {},
  reset = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  // set valid initial value object based on value (string) property
  const initialOption =
    options.filter((option) => option.value === value)[0] ||
    initialSelectedOption;
  const { roles } = useRecoilValue(userState);
  const [selectedOption, setSelectedOption] = useState(initialOption);

  // set initial value in Form.Item
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onChange(initialOption.value);
    setSelectedOption(initialOption);
  }, [reset]);

  return (
    <>
      <Radio.Group
        defaultValue={initialOption.value}
        value={selectedOption.value}
        onChange={({ target }) => {
          setSelectedOption(target);
          onChange(target.value);
        }}
        disabled={disabled}
      >
        {options.map(({ label, value, tooltip }) => (
          <Radio key={value} value={value}>
            {label}
            <Tooltip title={t(tooltip)}>
              <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
            </Tooltip>
          </Radio>
        ))}
      </Radio.Group>
    </>
  );
};
