/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const createLibraryExtrusion = /* GraphQL */ `
  mutation CreateLibraryExtrusion(
    $input: CreateLibraryExtrusionInput!
    $condition: ModelLibraryExtrusionConditionInput
  ) {
    createLibraryExtrusion(input: $input, condition: $condition) {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const updateLibraryExtrusion = /* GraphQL */ `
  mutation UpdateLibraryExtrusion(
    $input: UpdateLibraryExtrusionInput!
    $condition: ModelLibraryExtrusionConditionInput
  ) {
    updateLibraryExtrusion(input: $input, condition: $condition) {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const deleteLibraryExtrusion = /* GraphQL */ `
  mutation DeleteLibraryExtrusion(
    $input: DeleteLibraryExtrusionInput!
    $condition: ModelLibraryExtrusionConditionInput
  ) {
    deleteLibraryExtrusion(input: $input, condition: $condition) {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const createLibrarySurfaceFinish = /* GraphQL */ `
  mutation CreateLibrarySurfaceFinish(
    $input: CreateLibrarySurfaceFinishInput!
    $condition: ModelLibrarySurfaceFinishConditionInput
  ) {
    createLibrarySurfaceFinish(input: $input, condition: $condition) {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const updateLibrarySurfaceFinish = /* GraphQL */ `
  mutation UpdateLibrarySurfaceFinish(
    $input: UpdateLibrarySurfaceFinishInput!
    $condition: ModelLibrarySurfaceFinishConditionInput
  ) {
    updateLibrarySurfaceFinish(input: $input, condition: $condition) {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const deleteLibrarySurfaceFinish = /* GraphQL */ `
  mutation DeleteLibrarySurfaceFinish(
    $input: DeleteLibrarySurfaceFinishInput!
    $condition: ModelLibrarySurfaceFinishConditionInput
  ) {
    deleteLibrarySurfaceFinish(input: $input, condition: $condition) {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const createLibraryFin = /* GraphQL */ `
  mutation CreateLibraryFin(
    $input: CreateLibraryFinInput!
    $condition: ModelLibraryFinConditionInput
  ) {
    createLibraryFin(input: $input, condition: $condition) {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const updateLibraryFin = /* GraphQL */ `
  mutation UpdateLibraryFin(
    $input: UpdateLibraryFinInput!
    $condition: ModelLibraryFinConditionInput
  ) {
    updateLibraryFin(input: $input, condition: $condition) {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const deleteLibraryFin = /* GraphQL */ `
  mutation DeleteLibraryFin(
    $input: DeleteLibraryFinInput!
    $condition: ModelLibraryFinConditionInput
  ) {
    deleteLibraryFin(input: $input, condition: $condition) {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const createLibraryFan = /* GraphQL */ `
  mutation CreateLibraryFan(
    $input: CreateLibraryFanInput!
    $condition: ModelLibraryFanConditionInput
  ) {
    createLibraryFan(input: $input, condition: $condition) {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const updateLibraryFan = /* GraphQL */ `
  mutation UpdateLibraryFan(
    $input: UpdateLibraryFanInput!
    $condition: ModelLibraryFanConditionInput
  ) {
    updateLibraryFan(input: $input, condition: $condition) {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const deleteLibraryFan = /* GraphQL */ `
  mutation DeleteLibraryFan(
    $input: DeleteLibraryFanInput!
    $condition: ModelLibraryFanConditionInput
  ) {
    deleteLibraryFan(input: $input, condition: $condition) {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const createConfiguration = /* GraphQL */ `
  mutation CreateConfiguration(
    $input: CreateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    createConfiguration(input: $input, condition: $condition) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateConfiguration = /* GraphQL */ `
  mutation UpdateConfiguration(
    $input: UpdateConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    updateConfiguration(input: $input, condition: $condition) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfiguration = /* GraphQL */ `
  mutation DeleteConfiguration(
    $input: DeleteConfigurationInput!
    $condition: ModelConfigurationConditionInput
  ) {
    deleteConfiguration(input: $input, condition: $condition) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const createTerritory = /* GraphQL */ `
  mutation CreateTerritory(
    $input: CreateTerritoryInput!
    $condition: ModelTerritoryConditionInput
  ) {
    createTerritory(input: $input, condition: $condition) {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
export const updateTerritory = /* GraphQL */ `
  mutation UpdateTerritory(
    $input: UpdateTerritoryInput!
    $condition: ModelTerritoryConditionInput
  ) {
    updateTerritory(input: $input, condition: $condition) {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
export const deleteTerritory = /* GraphQL */ `
  mutation DeleteTerritory(
    $input: DeleteTerritoryInput!
    $condition: ModelTerritoryConditionInput
  ) {
    deleteTerritory(input: $input, condition: $condition) {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
