import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { CountrySelect } from './CountrySelect';
//import {LanguageSelect} from './LanguageSelect';

const { Item } = Form;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 20 },
};

export const ProfileForm = ({
  user,
  onFinish,
  onFinishFailed = () => {},
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const [stateRequired, setStateRequired] = useState(false);
  const [validCountry, setValidCountry] = useState(user.country);
  let { language } = user;

  return (
    <Form
      name="profile"
      size="small"
      initialValues={user}
      onFinish={(data) => {
        onFinish({ ...data, country: validCountry, language });
      }}
      onFinishFailed={onFinishFailed}
      {...layout}
    >
      <Item
        name="name"
        label={t('Name')}
        rules={[{ required: true, message: 'Please enter your name' }]}
      >
        <Input />
      </Item>

      <Item
        name="company"
        label={t('Company')}
        rules={[{ required: true, message: 'Please enter your company name' }]}
      >
        <Input />
      </Item>

      <Item
        name="phone"
        label={t('Phone Number')}
        rules={[
          {
            required: false,
            pattern: /^\d?([- +()]*\d){8,12}$/,
            message: 'Please enter a valid phone number'
          },
        ]}
      >
        <Input />
      </Item>

      <Item
        name="address"
        label={t('Address')}
        rules={[
          { required: true, message: 'Please enter your company address' },
        ]}
      >
        <Input />
      </Item>

      <Item
        name="city"
        label={t('City')}
        rules={[{ required: true, message: 'Please enter your city' }]}
      >
        <Input />
      </Item>

      <Item
        name="state"
        label={t('State/Province')}
        rules={[
          { required: stateRequired, message: 'Please enter your state' },
        ]}
      >
        <Input />
      </Item>

      <Item label={t('Country')}>
        <CountrySelect
          name="country"
          value={user.country}
          onChange={(selected) => {
            setValidCountry(selected);
            setStateRequired(
              selected.value === 'US' || selected.value === 'CA'
            );
          }}
        />
      </Item>
      {/*
      <Item label={t('Language')} rules={[{ required: false }]}>
        <LanguageSelect
          value={user.language}
          onChange={(selected) => (language = selected)}
        />
      </Item>
      */}
      <Item {...tailLayout}>
        <Button
          type="primary"
          size="medium"
          htmlType="submit"
          loading={isLoading}
          disabled={!validCountry.value}
        >
          {t('Save')}
        </Button>
      </Item>
    </Form>
  );
};
