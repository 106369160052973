import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

// labels should be available in i18n translation
const options = [
  { value: 'METRIC', label: 'Metric' },
  { value: 'AMERICAN', label: 'American' },
];

const initialSelectedOption = options[0];

// onChange handler binds the selection to the wrapping Form.Item
export const UnitsSelect = ({
  value = initialSelectedOption.value,
  onChange = () => {},
  reset = false,
  disabled = false,
}) => {
  const { t } = useTranslation();
  // set valid initial value object based on value (string) property
  const initialOption =
    options.filter((option) => option.value === value)[0] ||
    initialSelectedOption;

  const [selectedOption, setSelectedOption] = useState(initialOption);

  // set initial value in Form.Item
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onChange(initialOption.value);
    setSelectedOption(initialOption);
  }, [reset]);

  return (
    <Select
      defaultValue={initialOption.value}
      value={selectedOption.value}
      options={options.map(({ label, ...rest }) => ({
        ...rest,
        label: t(label),
      }))}
      placeholder={t('Select')}
      onSelect={(value, option) => {
        setSelectedOption(option);
        onChange(option.value);
      }}
      disabled={disabled}
    />
  );
};
