import React, { useState } from 'react';
import API from '@aws-amplify/api';
import { Hub } from '@aws-amplify/core';
import _throttle from 'lodash/throttle';
import _isEqual from 'lodash/isEqual';
import {
  useRecoilState,
  useSetRecoilState,
  useRecoilValue,
  useRecoilTransactionObserver_UNSTABLE,
} from 'recoil';
import { Modal } from 'antd';
import { updateProject } from '../graphql/mutations';
import { editProjectState } from '../state/app';
import { isSignedIn } from '../state/user';
import { viewState } from '../state/view';
import { projectState } from '../state/project';

const debounceWait = 3000;

const apiRequest = (
  { createdAt, updatedAt, owner, status, simulationStatus, ...rest },
  includeStatus = false
) => {
  const input = includeStatus ? { ...rest, status } : rest;
  Hub.dispatch('autosave', { status: 'SAVING' });
  return API.graphql({ query: updateProject, variables: { input } })
    .then((result) => {
      Hub.dispatch('autosave', { status: 'SAVED' });
      return result.data.updateProject;
    })
    .catch((error) => {
      Hub.dispatch('autosave', { status: 'ERROR' });
      console.error('persistProjectState', error);
      console.info('Project Snapshot', input);
      return error;
    });
};

const apiRequestThrottled = _throttle(apiRequest, debounceWait, {
  trailing: true,
});

let project = null;
let previousProject = null;
let viewStateJSON = null;

export function AutoSave() {
  const userIsSignedIn = useRecoilValue(isSignedIn);
  const setProject = useSetRecoilState(projectState);
  const [editProject, setEditProject] = useRecoilState(editProjectState);
  const [showModal, setShowModal] = useState(false);

  // status, simulationStatus are READONLY and updated by simulation service
  const persistProjectState = ({ snapshot, previousSnapshot }) => {
    project = snapshot.getLoadable(projectState).contents;
    previousProject = previousSnapshot.getLoadable(projectState).contents;
    // serialize and save view state
    const view = snapshot.getLoadable(viewState).contents;
    // autosave only used for project design view
    if (view.id === 'design') {
      if (project.id === previousProject.id) {
        setEditProject(project.status !== 'SOLUTION');
        if (!_isEqual(project, previousProject)) {
          if (editProject) {
            Hub.dispatch('autosave', { status: 'PENDING' });
            viewStateJSON = JSON.stringify(view);
            apiRequestThrottled({
              ...project,
              viewState: viewStateJSON,
            }).then((result) => {
              if (result.errors) {
                setEditProject(true);
              } else {
                setEditProject(result.status !== 'SOLUTION');
              }
            });
          } else {
            // do not show confirmation for new projects - solution = null
            setShowModal(project.solution !== null);
          }
        }
      }
    } else {
      project = null;
      previousProject = null;
      viewStateJSON = null;
      setEditProject(true);
    }
  };

  // this is an effect so cannot be called conditionally
  useRecoilTransactionObserver_UNSTABLE(({ snapshot, previousSnapshot }) => {
    // can only persist for valid signed in user
    if (userIsSignedIn) {
      persistProjectState({ snapshot, previousSnapshot });
    }
  });

  const handleOk = () => {
    setEditProject(true);
    const updatedProject = { ...project, status: 'INCOMPLETE' };
    setProject(updatedProject);
    apiRequest(
      { ...updatedProject, viewState: viewStateJSON },
      true
    ).finally(() => setShowModal(false));
  };

  const handleCancel = () => {
    setEditProject(false);
    setProject(previousProject);
    setShowModal(false);
  };

  return (
    <Modal
      title="Edit Project"
      visible={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      zIndex={1200}
    >
      <p>Your project has been solved</p>
      <p>Editing the project will invalidate the current solution</p>
      <p>
        <strong>Cancel</strong> to reject change and keep the current solution
      </p>
      <p>
        <strong>OK</strong> to accept change and delete the current solution
      </p>
    </Modal>
  );
}
