import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { Popover } from 'antd';
import { projectState } from '../../state/project';
import { viewState } from '../../state/view';
import { replaceItemAtIndex } from '../../utils/recoil-utils';
import { convertToMillimeters } from '../../utils/unit-conversion';
import AxisLabel from './AxisLabel';
import Pipe from './Pipe';
import HeatSource from '../HeatSourceLayout/HeatSource';
import { EditOutlined } from '@ant-design/icons';
import { ColdplatePopOver } from './ColdplatePopOver';
import './coldplate.less';
import { Hub } from '@aws-amplify/core';
import { hasValidPipes } from '../../utils/pipe-validators';
import _uniqueId from 'lodash/uniqueId';

const Coldplate = ({ disabled }) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const [invalidPipes, setInvalidPipes] = useState([]);
  const [project, setProject] = useRecoilState(projectState);
  const [view, setView] = useRecoilState(viewState);
  const { baseSize, heatSources, aquasurf } = project;
  const { pipes } = aquasurf;
  const setPipes = (pipes) =>
    setProject({ ...project, aquasurf: { ...aquasurf, pipes } });
  const updatePipe = (index, pipe) => {
    const newPipes = replaceItemAtIndex(pipes, index, {
      ...pipe,
    });
    setPipes(newPipes);
  };

  const id = useRef(_uniqueId('coldplate-')).current;

  const baseplateBorderSize = 2;
  const baseplateSelected = view.selectedStep === 'baseplate';
  const baseplateBorder = `${baseplateBorderSize}px solid ${
    baseplateSelected ? '#f46a25' : disabled ? '#d6d6d6' : '#c2c2c2'
  }`;

  let baseplateDisplayLength = convertToMillimeters(baseSize.length);
  let baseplateDisplayWidth = convertToMillimeters(baseSize.width);

  const scaleFactor = disabled
    ? Math.min(600 / baseplateDisplayLength, 350 / baseplateDisplayWidth)
    : view.pipeLayout.scaleFactor;

  baseplateDisplayLength *= scaleFactor;
  baseplateDisplayWidth *= scaleFactor;

  // tag inlet pipe - sort by xPos and track index
  const sortedPipeIndexes = [
    ...new Set(
      pipes
        .map(({ x }, index) => ({ index, x: convertToMillimeters(x) }))
        .sort((a, b) => a.x - b.x)
        .map(({ index }) => index)
    ),
  ];

  const isSerpentine = aquasurf.pipeConfiguration === 'SERPENTINE';

  const dispatchError = ({ id, error = false }) => {
    const step = 'pipeLayout';
    Hub.dispatch('ui', {
      event: 'validation',
      data: { step, id, error },
      message: step,
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatchError({ id, error: pipes.length === 0 });
  }, [pipes.length]);

  // overlapping heatsources
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const validPipes = hasValidPipes(project);
    if (validPipes && validPipes.split(':').length === 2) {
      setInvalidPipes(
        validPipes
          ?.split(':')[1]
          .split(', ')
          .map((i) => Number(i)) || []
      );
    } else {
      setInvalidPipes([]);
    }
    dispatchError({ id, error: !!validPipes });
  }, [hasValidPipes(project)]);

  return (
    <div
      className="coldplate-layout"
      style={{
        display: 'grid',
        gridTemplateColumns: !disabled ? '1fr 90px auto 90px 1fr' : 'auto',
        gridTemplateRows: !disabled ? 'auto 70px' : 'auto',
      }}
    >
      <div />
      {!disabled && (
        <AxisLabel.Vertical
          selected={baseplateSelected}
          label={`${Math.round(baseSize.length.value * 100) / 100} ${
            baseSize.length.units
          }`}
        />
      )}
      <div>
        <div
          className={`coldplate-top-edge ${disabled ? 'disabled' : ''}`}
          style={{
            width: `${baseplateDisplayWidth + baseplateBorderSize + 3}px`,
          }}
        />
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            className={`coldplate ${
              view.pipeLayout.snapToGrid && !disabled ? 'grid' : ''
            } ${!disabled ? 'active' : ''} ${
              baseplateSelected ? 'selected' : ''
            }`}
            style={{
              height: `${baseplateDisplayLength}px`,
              width: `${baseplateDisplayWidth}px`,
              border: baseplateBorder,
              backgroundSize: `${
                baseplateDisplayWidth / view.pipeLayout.gridChunkFactor
              }px ${
                baseplateDisplayLength / view.pipeLayout.gridChunkFactor
              }px`,
              boxSizing: 'content-box',
            }}
            onClick={(e) => {
              if (!disabled && e.target === e.currentTarget) {
                setView({
                  ...view,
                  selectedStep: baseplateSelected ? '' : 'baseplate',
                });
              }
            }}
          >
            {!disabled && (
              <div className="coldplate-popover-wrapper">
                <Popover
                  content={<ColdplatePopOver />}
                  title="Aquasurf"
                  trigger="click"
                  visible={showPopOver || baseplateSelected}
                  placement="right"
                  onVisibleChange={setShowPopOver}
                  arrowPointAtCenter
                  overlayStyle={{ width: '224px' }}
                >
                  <EditOutlined
                    onClick={() =>
                      setView({
                        ...view,
                        selectedStep: baseplateSelected ? '' : 'baseplate',
                      })
                    }
                  />
                </Popover>
              </div>
            )}
            {heatSources.map((heatSource, index) => (
              <HeatSource
                index={index}
                key={`heatsource-${index}`}
                heatSource={heatSource}
                disabled={true}
                scaleFactor={scaleFactor}
              />
            ))}
            {pipes.map((pipe, index) => {
              const id = `pipe-${index}`;
              const isInlet = isSerpentine
                ? index === sortedPipeIndexes[0]
                : true;

              const isFlowDown =
                isSerpentine &&
                sortedPipeIndexes.findIndex((i) => i === index) % 2;

              const isOutlet = isSerpentine
                ? index === sortedPipeIndexes[sortedPipeIndexes.length - 1]
                : true;

              const sortedIndex =
                sortedPipeIndexes.findIndex((item) => item === index) + 1;

              return (
                <Pipe
                  index={index}
                  label={sortedIndex}
                  key={id}
                  pipe={pipe}
                  disabled={disabled}
                  isSelected={view.selectedStep === id}
                  isInlet={isInlet}
                  isOutlet={isOutlet}
                  isFlowDown={isFlowDown}
                  isSerpentine={isSerpentine}
                  update={(p) => {
                    updatePipe(index, p);
                  }}
                  onMouseDown={() =>
                    setView({
                      ...view,
                      selectedStep: view.selectedStep === id ? 0 : id,
                    })
                  }
                  scaleFactor={scaleFactor}
                  invalid={invalidPipes.includes(sortedIndex)}
                />
              );
            })}
          </div>
          <div
            className={`coldplate-side-edge ${disabled ? 'disabled' : ''}`}
            style={{
              height: `${baseplateDisplayLength + baseplateBorderSize + 3}px`,
            }}
          />
        </div>
      </div>
      {!disabled && <div style={{ marginLeft: '16px' }} />}
      {!disabled && (
        <>
          <div />
          <div />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <div
              style={{
                color: baseplateSelected ? '#f46a25' : '#878787',
                margin: '8px 0 0 0',
                fontWeight: 600,
              }}
            >
              (0,0)
            </div>
          </div>
        </>
      )}
      {!disabled && (
        <AxisLabel.Horizontal
          selected={baseplateSelected}
          label={`${Math.round(baseSize.width.value * 100) / 100} ${
            baseSize.width.units
          }`}
        />
      )}
      <div />
      <div />

      <div />
      <div />
      <div>
        <div className="flow-labels">
          <div className="flow-inlet" />
          <div />
          <div className="flow-outlet" />
          <div>Fluid Inlet</div>
          <div />
          <div>Fluid Outlet</div>
        </div>
      </div>
      <div />
      <div />
    </div>
  );
};

export default Coldplate;

