import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { projectState } from '../../state/project';
import Measurement from '../Measurement';

export const PipePopOver = ({ index, pipe }) => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const [updatedPipe, setUpdatedPipe] = useState(pipe);

  // must update with local state as the popover does not update
  // correctly on subsequent launches
  useEffect(() => {
    setUpdatedPipe(pipe);
  }, [pipe]);

  const updatePipeProperty = (property) => {
    const p = { ...updatedPipe, ...property };
    const updatedPipes = [...project.aquasurf.pipes];
    updatedPipes.splice(index, 1, p);
    setProject({
      ...project,
      aquasurf: { ...project.aquasurf, pipes: updatedPipes },
    });
    setUpdatedPipe(p);
  };

  return (
    <div className="pipe-source-popover">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <label className="title">{t('Position')}</label>
        <Tooltip
          placement="topRight"
          title={t('Position is calculated using tube centerline')}
        >
          <QuestionCircleOutlined style={{ marginTop: '5px' }} />
        </Tooltip>
      </div>
      <Measurement.Dimension
        label={t('X')}
        value={updatedPipe.x}
        minimum={{ type: 'DIMENSION', value: 0, units: 'mm' }}
        onChange={(x) => {
          updatePipeProperty({ x: { ...updatedPipe.x, ...x } });
        }}
      />
    </div>
  );
};
