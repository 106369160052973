import API from '@aws-amplify/api';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { useRecoilState } from 'recoil';
import { userState } from '../state/user';
import { updateUser } from '../graphql/mutations';

export const IntroText = () => (
  <div>
    <p>
      Welcome to R-TOOLS MAXX Heat sink modeling software where you can create a
      thermal modeling of air and liquid cooled heat sinks for your specific
      application. Please note that all heat sinks models are based on using
      aluminum as heat sink material. Should you be interested in investigating
      other material such as copper, please contact our Application Engineering
      teams:
    </p>
    <table className="styled-table">
      <thead>
        <tr>
          <th>Region</th>
          <th>Email</th>
          <th>Call</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>North America</td>
          <td>
            <a href="mailto:cooling.roc@i.mersen.com">cooling.roc@i.mersen.com</a>
          </td>
          <td>1-978-465-4853</td>
        </tr>
        <tr>
          <td>Europe</td>
          <td>
            <a href="mailto:cooling.lmr@mersen.com">cooling.lmr@mersen.com</a>
          </td>
          <td>+33 (0) 4 76 81 45 45</td>
        </tr>
        <tr>
          <td>China</td>
          <td>
            <a href="mailto:sales.epchina@mersen.com">
              sales.epchina@mersen.com
            </a>
          </td>
          <td>+86 21 6760 2388</td>
        </tr>
      </tbody>
    </table>
    <h2>Quick Start</h2>
    <p>
      R-TOOLS MAXX is an easy to use modeling tool by following these simple
      five steps:
    </p>
    <p>
      <strong>Step 1:</strong> Define your project boundary conditions such as ambient temperature, the available footprint, and the fluid conditions (for liquid cooling).
    </p>
    <p>
      <strong>Step 2:</strong> Define your heat source layout and power.
    </p>
    <p>
      <strong>Step 3:</strong> For Air-Cooling, define your air-flow conditions. For Liquid-Cooling, define your pipe layout.
    </p>
    <p>
      <strong>Step 4 (Air-Cooling Only):</strong> Use the Fin Selector page to see the projected Temperature Delta, Pressure Drop, and Relative cost of each fin type.
    </p>
    <p>
      <strong>Step 5:</strong> Proceed to the Simulation.
    </p>
  </div>
);

export const ProjectsIntroModal = () => {
  const [user, setUser] = useRecoilState(userState);
  const [showModal, setShowModal] = useState(false);
  const [showIntro, setShowIntro] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const handleOK = () => setShowModal(false);

  const updateUserSettings = () => {
    const { createdAt, updatedAt, owner, projects, settings, ...rest } = user;
    const userSettings = JSON.parse(settings);
    if (showIntro === userSettings.showIntro) {
      setShowModal(false);
    } else {
      // removed readonly records keys
      const input = {
        ...rest,
        settings: JSON.stringify({ ...userSettings, showIntro }),
      };

      API.graphql({
        query: updateUser,
        variables: { input },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
        .then((result) => {
          const data = result.data.updateUser;
          // remove database created keys from response
          const { createdAt, updatedAt, owner, projects, ...rest } = data;
          setUser(rest);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(handleOK);
    }
  };

  useEffect(() => {
    const userSettings = JSON.parse(user.settings);
    setIsChecked(false);
    setShowModal(userSettings.showIntro);
  }, [user]);

  return (
    <Modal
      title="Mersen R-TOOLS MAXX"
      visible={showModal}
      closable={false}
      onOk={handleOK}
      width={640}
      footer={[
        <Checkbox
          key="intro-checkbox"
          checked={isChecked}
          onClick={() => {
            setIsChecked(true);
            setShowIntro(false);
          }}
        >
          Don't show this message again
        </Checkbox>,
        <Button key="intro-submit" type="primary" onClick={updateUserSettings}>
          Continue
        </Button>,
      ]}
    >
      <IntroText />
    </Modal>
  );
};
