import React from 'react';
import { Space } from 'antd';
import { Profile } from './Profile';
import { SignInModal } from './SignInModal';
import { MersenRToolsMaxxLogo } from './MersenRToolsMaxxLogo';
import { ProjectBar } from './Project/ProjectBar';
import { HelpDrawer } from './HelpDrawer';

export const AppHeader = () => {
  return (
    <div className="header">
      <div className="content">
        <div className="logo">
          <MersenRToolsMaxxLogo height={36} />
        </div>
        <ProjectBar />
        <div className="sign-in">
          <Space>
            <SignInModal />
            <Profile />
            <HelpDrawer />
          </Space>
        </div>
      </div>
    </div>
  );
};
