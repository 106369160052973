import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Measurement } from '../Measurement';
import { envelopes } from '../../state/units';
import { projectState } from '../../state/project';
import { Row, Col } from 'antd';
import { convertUnits } from '../../utils/unit-conversion';

export const AquasurfVolume = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { boundaryConditions, baseSize, aquasurf } = project;
  const envelope = envelopes[project.type.toLowerCase()];
  const { maxSize } = envelope.boundaryConditions;
  const { tubeMaterial, tubeOuterDiameter } = aquasurf;

  // copper tubes have minimum baseplate thickness for each tube diameter
  const minBTValue = {
    '0.25': { type: 'DIMENSION', value: 10.8, units: 'mm' },
    '0.375': { type: 'DIMENSION', value: 14.1, units: 'mm' },
    '0.5': { type: 'DIMENSION', value: 16.6, units: 'mm' },
    '0.625': { type: 'DIMENSION', value: 19.7, units: 'mm' },
  };

  const minimumBaseplateThickness = () => {
    switch (tubeMaterial) {
      case 'CU':
        return convertUnits({
          value: minBTValue[tubeOuterDiameter.toString()],
          to: maxSize.thickness.min.units,
        });

      default:
        return maxSize.thickness.min;
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12} offset={6}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Baseplate Width (X)')}
                value={baseSize.width}
                minimum={maxSize.width.min}
                maximum={maxSize.width.max}
                onChange={(width) => {
                  setProject({
                    ...project,
                    boundaryConditions: {
                      ...boundaryConditions,
                      maxSize: {
                        ...boundaryConditions.maxSize,
                        width,
                      },
                    },
                    baseSize: {
                      ...baseSize,
                      width,
                    },
                  });
                }}
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} offset={6}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Baseplate Length (Y)')}
                value={baseSize.length}
                minimum={maxSize.length.min}
                maximum={maxSize.length.max}
                onChange={(length) =>
                  setProject({
                    ...project,
                    boundaryConditions: {
                      ...boundaryConditions,
                      maxSize: {
                        ...boundaryConditions.maxSize,
                        length,
                      },
                    },
                    baseSize: {
                      ...baseSize,
                      length,
                    },
                  })
                }
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} offset={6}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Baseplate Thickness')}
                value={baseSize.height}
                minimum={minimumBaseplateThickness()}
                maximum={maxSize.thickness.max}
                onChange={(height) => {
                  setProject({
                    ...project,
                    baseSize: {
                      ...baseSize,
                      height,
                    },
                  });
                }}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
