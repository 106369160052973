import React from 'react';
import { Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { SectionHeading } from '../../../SectionHeading';

export const Images = ({ top, side, x3d, scalarbar }) => {
  const { t } = useTranslation();
  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Thermal Contours')} />
      <div className="simulation-images">
        <div
          style={{
            width: 'calc(100% - 25px)',
            paddingRight: '25px',
            backgroundImage: `url(${scalarbar.url})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 18px',
          }}
        >
          <iframe
            title="x3d"
            width="100%"
            height="340px"
            style={{ border: 0 }}
            src={`/x3d.html?url=${encodeURIComponent(x3d.url)}`}
          />
        </div>
        <div>
          <Image src={side.url} />
        </div>
        <div>
          <Image src={top.url} />
        </div>
      </div>
      <div className="simulation-images-text">
        <div>3D View (Select image to Zoom/Rotate)</div>
        <div>Side View (Click image to Zoom)</div>
        <div>Top View (Click image to Zoom)</div>
      </div>
    </div>
  );
};
