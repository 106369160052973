import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Button, Drawer } from 'antd';
import { IntroText } from './ProjectsIntroModal';

export const HelpDrawer = () => {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <>
      <Button
        type="secondary"
        onClick={() => {
          setShowDrawer(true);
        }}
      >
        {t('Help')}
      </Button>
      <Drawer
        title={t('Help')}
        placement="right"
        closable={true}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        width="45%"
      >
        <IntroText />
        <div className="help-drawer-content">
          <h2>Tutorials</h2>

          <div>
            <h3>1. Air Cooled Heat Sinks</h3>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=XghwxgFAnEE&t=26s"
            width="100%"
          />
          </div>

          {/*<div style={{marginTop: '16px'}}>
            <h3>2. Liquid Cooling Aquasurf</h3>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=gBOnZO7zUtc"
              width="100%"
            />
          </div>*/}

          <h2>Requirements</h2>
          <p>
            R-TOOLS MAXX requires a modern web browser. We support current
            versions of Chrome, Firefox, Safari, and Edge.
          </p>
          <p>
            We do not support older web browsers like Microsoft Internet
            Explorer.
          </p>
          <p>
            R-TOOLS MAXX is designed for desktop use and screens that support
            widths greater than 1440 pixels.
          </p>
        </div>
      </Drawer>
    </>
  );
};
