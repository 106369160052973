import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';

const { Text } = Typography;

export const Baseplate = () => {
  const { t } = useTranslation();
  const { baseSize } = useRecoilValue(projectState);

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Baseplate')} />
      <section>
        <Text strong>{t('Dimensions')}</Text>
        <LabeledValue
          label={t('Width')}
          value={baseSize.width}
        />
        <LabeledValue
          label={t('Length')}
          value={baseSize.length}
        />
        <LabeledValue
          label={t('Thickness')}
          value={baseSize.height}
        />
      </section>
    </div>
  );
};
