/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const estimatorGetRequest = /* GraphQL */ `
  query EstimatorGetRequest($url: String) {
    estimatorGetRequest(url: $url)
  }
`;

export const getUserProjects = /* GraphQL */ `
  query GetUserProjects($id: ID!, $nextToken: String, $limit: Int) {
    getUser(id: $id) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      auth {
        id
        username
      }
      settings
      projects(nextToken: $nextToken, limit: $limit) {
        items {
          id
          name
          description
          notes
          star
          type
          units
          heatSources {
            power {
              type
              value
              units
            }
          }
          userId
          extrusion {
            partId
            airFlow {
              type
              forcedConvectionType
              setup
            }
          }
          mbf {
            fin {
              type
            }
            airFlow {
              type
              forcedConvectionType
              setup
            }
          }
          aquasurf {
            id
            tubeMaterial
            fluid
            percentageWater
            pipeConfiguration
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        company
        phone
        address
        city
        state
        country {
          value
          label
        }
        language {
          value
          label
        }
        roles
        region
        ipregion
        auth {
          id
          username
        }
        settings
        projects {
          items {
            id
            name
            description
            notes
            star
            type
            units
            boundaryConditions {
              ambientTemperature {
                type
                value
                units
              }
              altitude {
                type
                value
                units
              }
              includeRadiation
              maxTemperatureRise {
                type
                value
                units
              }
              maxPressureDrop {
                type
                value
                units
              }
              maxSize {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
            }
            baseSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            heatSources {
              label
              power {
                type
                value
                units
              }
              position {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              size {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              rjc {
                type
                value
                units
              }
              interface
              material
            }
            userId
            extrusion {
              id
              partId
              surfaceFinish {
                id
                name
                emissivity
              }
              airFlow {
                type
                orientation
                forcedConvectionType
                velocity {
                  type
                  value
                  units
                }
                flowRate {
                  type
                  value
                  units
                }
                fan {
                  id
                  label
                  acdc
                  size
                  fanCurve
                  region
                }
                setup
                impingePercentage
              }
              region
            }
            mbf {
              id
              baseMaterial
              embeddedHeatPipes {
                id
                description
              }
              finOption
              fin {
                type
                height {
                  type
                  value
                  units
                }
                region
              }
              airFlow {
                type
                orientation
                forcedConvectionType
                velocity {
                  type
                  value
                  units
                }
                flowRate {
                  type
                  value
                  units
                }
                fan {
                  id
                  label
                  acdc
                  size
                  fanCurve
                  region
                }
                setup
                impingePercentage
              }
            }
            aquasurf {
              id
              baseMaterial
              tubeMaterial
              fluid
              percentageWater
              inletTemperature {
                type
                value
                units
              }
              headerSize
              tubeOuterDiameter
              pipeConfiguration
              embeddedPosition
              numberPasses
              pipes {
                x {
                  type
                  value
                  units
                }
                y {
                  type
                  value
                  units
                }
              }
              flowRate {
                type
                value
                units
              }
            }
            aquamax {
              id
              baseMaterial
            }
            status
            simulationStatus {
              progress
              message
              timeStamps
            }
            solution {
              id
              createdAt
              hskWeight {
                type
                value
                units
              }
              hskDimensions {
                width {
                  type
                  value
                  units
                }
                length {
                  type
                  value
                  units
                }
                height {
                  type
                  value
                  units
                }
              }
              maximumTemperature {
                type
                value
                units
              }
              fluidPressureDrop {
                type
                value
                units
              }
              fluidExitTemperature {
                type
                value
                units
              }
              perHeatSource {
                coveragePercentage
                hskAverageT {
                  type
                  value
                  units
                }
                hskMaximumT {
                  type
                  value
                  units
                }
                caseT {
                  type
                  value
                  units
                }
                junctionT {
                  type
                  value
                  units
                }
              }
            }
            viewState
            createdAt
            auth {
              id
              username
            }
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        notes
        star
        type
        units
        boundaryConditions {
          ambientTemperature {
            type
            value
            units
          }
          altitude {
            type
            value
            units
          }
          includeRadiation
          maxTemperatureRise {
            type
            value
            units
          }
          maxPressureDrop {
            type
            value
            units
          }
          maxSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
        }
        baseSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        heatSources {
          label
          power {
            type
            value
            units
          }
          position {
            x {
              type
              value
              units
            }
            y {
              type
              value
              units
            }
          }
          size {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          rjc {
            type
            value
            units
          }
          interface
          material
        }
        userId
        extrusion {
          id
          partId
          surfaceFinish {
            id
            name
            emissivity
          }
          airFlow {
            type
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
          region
        }
        mbf {
          id
          baseMaterial
          embeddedHeatPipes {
            id
            description
          }
          finOption
          fin {
            type
            height {
              type
              value
              units
            }
            region
          }
          airFlow {
            type
            orientation
            forcedConvectionType
            velocity {
              type
              value
              units
            }
            flowRate {
              type
              value
              units
            }
            fan {
              id
              label
              acdc
              size
              fanCurve
              region
            }
            setup
            impingePercentage
          }
        }
        aquasurf {
          id
          baseMaterial
          tubeMaterial
          fluid
          percentageWater
          inletTemperature {
            type
            value
            units
          }
          headerSize
          tubeOuterDiameter
          pipeConfiguration
          embeddedPosition
          numberPasses
          pipes {
            x {
              type
              value
              units
            }
            y {
              type
              value
              units
            }
          }
          flowRate {
            type
            value
            units
          }
        }
        aquamax {
          id
          baseMaterial
        }
        status
        simulationStatus {
          progress
          message
          timeStamps
        }
        solution {
          id
          createdAt
          hskWeight {
            type
            value
            units
          }
          hskDimensions {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          maximumTemperature {
            type
            value
            units
          }
          fluidPressureDrop {
            type
            value
            units
          }
          fluidExitTemperature {
            type
            value
            units
          }
          perHeatSource {
            coveragePercentage
            hskAverageT {
              type
              value
              units
            }
            hskMaximumT {
              type
              value
              units
            }
            caseT {
              type
              value
              units
            }
            junctionT {
              type
              value
              units
            }
          }
        }
        viewState
        createdAt
        auth {
          id
          username
        }
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLibraryExtrusion = /* GraphQL */ `
  query GetLibraryExtrusion($id: ID!) {
    getLibraryExtrusion(id: $id) {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const listLibraryExtrusions = /* GraphQL */ `
  query ListLibraryExtrusions(
    $filter: ModelLibraryExtrusionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLibraryExtrusions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        partNumber
        dimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        image
        shapeType
        baseWidth
        baseThick
        finThick
        finHeight
        leftOffset
        rightOffset
        leftThick
        rightThick
        numFins
        numFinsLeft
        numFinsRight
        numOuterFins
        numInnerFins
        gap
        outerGap
        innerGap
        areaGain
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLibrarySurfaceFinish = /* GraphQL */ `
  query GetLibrarySurfaceFinish($id: ID!) {
    getLibrarySurfaceFinish(id: $id) {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const listLibrarySurfaceFinishs = /* GraphQL */ `
  query ListLibrarySurfaceFinishs(
    $filter: ModelLibrarySurfaceFinishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLibrarySurfaceFinishs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        emissivity
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLibraryFin = /* GraphQL */ `
  query GetLibraryFin($id: ID!) {
    getLibraryFin(id: $id) {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const listLibraryFins = /* GraphQL */ `
  query ListLibraryFins(
    $filter: ModelLibraryFinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLibraryFins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        description
        type
        style
        finPitch
        finThickness
        standardHeights
        cost
        costPerHeight
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLibraryFan = /* GraphQL */ `
  query GetLibraryFan($id: ID!) {
    getLibraryFan(id: $id) {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const listLibraryFans = /* GraphQL */ `
  query ListLibraryFans(
    $filter: ModelLibraryFanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLibraryFans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        acdc
        size
        fanCurve
        region
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($id: ID!) {
    getConfiguration(id: $id) {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTerritory = /* GraphQL */ `
  query GetTerritory($id: ID!) {
    getTerritory(id: $id) {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
export const listTerritorys = /* GraphQL */ `
  query ListTerritorys(
    $filter: ModelTerritoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTerritorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        countries
        states
        zipcodes
        agents
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
