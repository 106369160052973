import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Space } from 'antd';

export const Contact = ({ handleContactButton }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 16]}>
      <Col span={16}>
        <div className="section-wrapper">
          <Space direction="vertical">
            <div>
              Technical support is available if you need help finding a solution
              for your application or if you have any questions or comments
              about R-TOOLS MAXX.
            </div>
            <div>
              Additional Mersen products and technology not available on this
              platform can help you meet the performance requirements you need.
            </div>
            <div>
              <Button onClick={handleContactButton}>{t('Contact Me')}</Button>
            </div>
          </Space>
        </div>
      </Col>
    </Row>
  );
};
