import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { projectState } from '../../state/project';
import {
  convertToMillimeters,
  convertValueUnitsTo,
} from '../../utils/unit-conversion';
import { Movable } from './Movable';
import { PipePopOver } from './PipePopOver';
import './pipe.less';

const Pipe = ({
  index,
  label,
  isSelected,
  pipe,
  update,
  onMouseDown,
  disabled,
  scaleFactor,
  isInlet,
  isOutlet,
  isFlowDown,
  isSerpentine,
  invalid = false,
}) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const project = useRecoilValue(projectState);
  const { baseSize } = project;
  const { tubeOuterDiameter } = project.aquasurf;

  const height = {
    type: 'DIMENSION',
    value: convertToMillimeters(baseSize.length),
    units: 'mm',
  };
  const width = {
    type: 'DIMENSION',
    value: convertToMillimeters({
      value: tubeOuterDiameter,
      units: 'in',
    }),
    units: 'mm',
  };

  // position origin is 0,0 and for pipe we need to add/remove centerline (offset)
  // render = saved - offset
  // saved = render + offset

  const tubeValue = {
    type: 'DIMENSION',
    value: tubeOuterDiameter / 2,
    units: 'in',
  };

  const offset = convertValueUnitsTo({ value: tubeValue, to: pipe.x.units });
  const xPos = { ...pipe.x, value: pipe.x.value - offset };

  return (
    <Movable
      scaleFactor={scaleFactor}
      height={height}
      width={width}
      xPos={xPos}
      yPos={pipe.y}
      disabled={disabled}
      horizontalOnly={true}
      onUpdate={(pos) => {
        // adjust with tube center line
        pos.x.value = pos.x.value + offset;
        update({ ...pipe, ...pos });
      }}
      onMouseDown={onMouseDown}
    >
      <div
        className={`pipe ${isFlowDown ? 'flow-down' : ''} ${
          isSelected ? 'active' : disabled ? 'disabled' : ''
        } ${isInlet ? 'inlet' : ''} ${isOutlet ? 'outlet' : ''} ${
          isSerpentine ? 'serpentine' : ''
        } ${invalid ? 'invalid' : ''}`}
      >
        <div className="pipe-popover-wrapper">
          <Popover
            content={<PipePopOver index={index} pipe={pipe} />}
            trigger="click"
            visible={showPopOver}
            placement="right"
            onVisibleChange={setShowPopOver}
            arrowPointAtCenter
            overlayStyle={{ width: '224px' }}
          >
            <EditOutlined />
          </Popover>
        </div>
        <div className="label">{label}</div>
        <div className="pipe-inlet" />
        <div className="pipe-outlet" />
      </div>
    </Movable>
  );
};

export default Pipe;
