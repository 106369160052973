import React from 'react';
import { ProjectList } from '../../components/Project/ProjectList';
import { ProjectsIntroModal } from '../../components/ProjectsIntroModal';
import { Spin } from 'antd';
import { useRecoilValue } from 'recoil';
import { isSignedIn } from '../../state/user';

export const Projects = () => {
  const userIsSignedIn = useRecoilValue(isSignedIn);

  return userIsSignedIn ? (
    <div className="main">
      <div className="content-wrapper">
        <div />
        <div className="content">
          <ProjectsIntroModal />
          <React.Suspense
            fallback={
              <div id="suspense-spinner">
                <Spin />
              </div>
            }
          >
            <ProjectList />
          </React.Suspense>
        </div>
        <div />
      </div>
    </div>
  ) : null;
};
