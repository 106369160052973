import React from 'react';
import './fin-bar-chart.less';
import { Tooltip } from 'antd';

const formatNumber = (value) => Number.parseFloat(value).toFixed(2);
// data = temperature, pressure, cost
export const FinBarChart = ({
  title,
  label,
  min = 0,
  value = 0,
  max = 100,
  suggested = 0,
  marker = 0,
  showMarker = true,
}) => {
  const scaleMax = showMarker ? (marker > max ? marker : max) : max;
  const scaleMin = showMarker ? (marker < min ? marker : min) : min;
  const scale = 100 / (scaleMax - scaleMin);
  const scaledValue = (value - scaleMin) * scale;
  // marker and bar are absolutely positioned
  const scaledMarker = (marker - scaleMin) * scale;
  const scaledSuggested = suggested >= min ? (suggested - scaleMin) * scale : 0;
  // bar is simply a grid with column size fr based on 0-100%
  const gridTemplateColumns = `${scaledValue}fr ${100 - scaledValue}fr`;
  return (
    <Tooltip
      title={`${formatNumber(scaleMin)} - ${formatNumber(
        value
      )} - ${formatNumber(scaleMax)}`}
    >
      <div className="bar-chart-wrapper">
        <div className="segment-title">{title}</div>
        <div className="segment-bar-container" style={{ gridTemplateColumns }}>
          <div className="segment-bar-value" />
          <div />
          <div
            className="segment-bar-marker"
            style={{ left: `${scaledSuggested}%` }}
            title={`Suggested: ${formatNumber(suggested)}`}
          />
          {showMarker && marker > 0 && (
            <div
              className="segment-bar-max-marker"
              style={{ left: `${scaledMarker}%` }}
              title={`Maximum: ${formatNumber(marker)}`}
            />
          )}
        </div>
        <div className="segment-label">{label}</div>
      </div>
    </Tooltip>
  );
};
