import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';

export const Overview = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Overview')} />
      <section>
        <LabeledValue
          label={t('Name')}
          value={project.name}
          size="auto"
        />
        <LabeledValue
          label={t('Status')}
          value={t(project.status)}
        />
      </section>
    </div>
  );
};
