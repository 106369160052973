import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Image } from 'cloudinary-react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  projectState,
  extrusion as defaultExtrusion,
} from '../../state/project';
import { listLibraryExtrusionsQuery } from '../../state/library';
import { cloudinaryImages } from '../../utils/config';
import { convertValueUnitsTo } from '../../utils/unit-conversion';
import { dimension } from '../../state/units';
import './extrusion-selector.less';

// cloudinary extrusion images endpoint - needs to go in config loaded at startup
// replace version 'v1598052908' with 'v1' should bypass CDN caching
// cloudinary upload preset set to invalidate CDN cache
export const url = `${cloudinaryImages}/v1/production/extrusions/`;

export const ExtrusionSelector = ({
  showModal = false,
  setShowModal = () => {},
  onCancel = () => {},
}) => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const extrusions = useRecoilValue(listLibraryExtrusionsQuery);

  // filter extrusions by project maxSize
  const { maxSize } = project.boundaryConditions;
  const filterByBoundarySize = ({ width, length, height }) =>
    convertValueUnitsTo({ value: width, to: 'mm' }) <
      convertValueUnitsTo({ value: maxSize.width, to: 'mm' }) &&
    convertValueUnitsTo({ value: length, to: 'mm' }) <
      convertValueUnitsTo({ value: maxSize.length, to: 'mm' }) &&
    convertValueUnitsTo({ value: height, to: 'mm' }) <
      convertValueUnitsTo({ value: maxSize.height, to: 'mm' });

  const projectExtrusions = extrusions.filter(({ dimensions }) =>
    filterByBoundarySize(dimensions)
  );

  // set default extrusion by lookup of project extrusion id = partNumber
  const projectExtrusionIndex = projectExtrusions.findIndex(
    ({ id }) => id === project.extrusion.id
  );

  const defaultSelectedExtrusion =
    projectExtrusionIndex === -1
      ? defaultExtrusion
      : projectExtrusions[projectExtrusionIndex];

  const [selectedExtrusion, setSelectedExtrusion] = useState(
    defaultSelectedExtrusion
  );

  const setExtrusion = () => {
    const { id, baseThick, dimensions } = selectedExtrusion;

    // if boundary conditions not set then default to extrusion w x h x l
    const { extrusion } = project;
    const width = convertValueUnitsTo({
      value: dimensions.width,
      to: project.baseSize.width.units,
    });

    const height = convertValueUnitsTo({
      value: { ...dimension, value: baseThick },
      to: project.baseSize.height.units,
    });

    setProject({
      ...project,
      baseSize: {
        ...project.baseSize,
        width: { ...project.baseSize.width, value: width },
        height: { ...project.baseSize.height, value: height },
      },
      extrusion: {
        ...extrusion,
        id,
        partId: id,
      },
    });
    setShowModal(false);
  };

  const resetExtrusion = () => {
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal
      title={t('Extrusions')}
      visible={showModal}
      onOk={setExtrusion}
      okButtonProps={{
        disabled:
          projectExtrusions.length === 0 ||
          selectedExtrusion.id === 'new-extrusion',
      }}
      onCancel={resetExtrusion}
      maskClosable={false}
      wrapClassName="extrusion-modal-content"
    >
      {projectExtrusions.length === 0 && (
        <div className="no-extrusion-text">
          No extrusions suitable for the specified working volume
        </div>
      )}
      {projectExtrusions.map((extrusion) => {
        const { id, partNumber, image } = extrusion;
        return (
          <div
            key={`image-${id}`}
            className={`tile ${selectedExtrusion.id === id ? 'selected' : ''}`}
            onClick={() => setSelectedExtrusion(extrusion)}
          >
            <Image publicId={`${url}${image}`} width="100%" />
            <div className="label">{partNumber}</div>
          </div>
        );
      })}
    </Modal>
  );
};
