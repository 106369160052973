import React from 'react';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import Storage from '@aws-amplify/storage';
import { useTranslation } from 'react-i18next';
import { Form, Button, Input, message } from 'antd';
import { getUserProjects } from '../../graphql/queries';
import { deleteUser, deleteProject } from '../../graphql/mutations';

const { Item } = Form;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 10 },
};
const tailLayout = {
  wrapperCol: { offset: 16 },
};

export const DeleteForm = ({ user, clearSession }) => {
  const { t } = useTranslation();

  const getProjects = async ({ id, nextToken }) => {
    try {
      const response = await API.graphql({
        query: getUserProjects,
        variables: {
          id,
          nextToken,
        },
      });
      return response.data.getUser.projects;
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDelete = async ({ confirm }) => {
    if (confirm === 'delete') {
      const deleteMessage = message.loading('Deleting account and projects', 0);
      // we have to delete the user resources first as they require user permissions
      try {
        let { items, nextToken } = await getProjects({
          id: user.id,
          nextToken: null,
        });

        let projects = [...items];

        while (nextToken !== null) {
          const res = await getProjects({
            id: user.id,
            nextToken,
          });
          projects = [...projects, ...res.items];
          nextToken = res.nextToken;
        }

        for (const { id } of projects) {
          const files = await Storage.vault.list(`${id}/`);
          for (const { key } of files) {
            await Storage.vault.remove(key);
          }

          await API.graphql({
            query: deleteProject,
            variables: {
              input: { id },
            },
          });
        }

        await API.graphql({
          query: deleteUser,
          variables: { input: { id: user.id } },
          authMode: 'AMAZON_COGNITO_USER_POOLS',
        });

        Auth.currentAuthenticatedUser().then((user) => {
          user.deleteUser((error, data) => {
            if (error) {
              console.error(error);
              throw new Error(error);
            }
            if (data === 'SUCCESS') {
              deleteMessage();
              clearSession();
            }
          });
        });
      } catch (error) {
        deleteMessage();
        clearSession();
        console.log('error', error);
      }
    }
  };

  return (
    <div style={{ marginLeft: '16px' }}>
      <Form
        name="delete"
        size="small"
        initialValues={{ confirm: '' }}
        onFinish={handleDelete}
        onFinishFailed={() => {}}
        {...layout}
      >
        <p>
          <strong>Are you ABSOLUTELY sure?</strong>
        </p>
        <p>
          Once you delete your account, there is no going back. Please be
          certain.
        </p>
        <p>
          This action <strong>CANNOT</strong> be undone. This will permanently
          delete your R-TOOLS MAXX account and all your projects.
        </p>
        <p>Please type 'delete' to confirm.</p>
        <Item
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Enter `delete` to confirm',
            },
          ]}
        >
          <Input />
        </Item>

        <Item {...tailLayout}>
          <Button type="primary" size="medium" htmlType="submit">
            {t('Delete Account')}
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default DeleteForm;
