import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';

export const FinSelection = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  const fin = project.mbf.fin;

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Fin Selection')} />
      <section>
        <LabeledValue label={t('Fin Type')} value={fin.type} />
        <LabeledValue label={t('Fin Height')} value={fin.height} />
      </section>
    </div>
  );
};
