import React, { useState } from 'react';
import { Button, Col, Row, Select, Form, Input, InputNumber } from 'antd';

const finishOptions = [
  { id: 'wash', label: 'Wash' },
  { id: 'anodize-clear', label: 'Clear Anodize' },
  { id: 'anodize-black', label: 'Black Anodize' },
  { id: 'chromate-clear', label: 'Clear Chromate' },
  { id: 'chromate-gold', label: 'Gold Chromate' },
  { id: 'other', label: 'Other' },
];

export const Quote = ({ handleQuoteButton }) => {
  const [showOther, setShowOther] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinish = (values) => handleQuoteButton(values);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onSelectOther = (value) => setShowOther(value === 'other');

  return (
    <Row gutter={[16, 16]}>
      <Col span={16}>
        <div className="section-wrapper">
          <p>Provide the following additional information and our sales team will provide a quote for this design.</p>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ required: true, message: 'Quantity is required' }]}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label="Number of Blind Holes"
              name="numblindholes"
              rules={[
                { required: true, message: 'Blind hole quantity is required' },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label="Number of Through Holes"
              name="numthroughholes"
              rules={[
                {
                  required: true,
                  message: 'Through hole quantity is required',
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label="Finish"
              name="finish"
              rules={[{ required: true, message: 'Finish is required' }]}
            >
              <Select onChange={onSelectOther}>
                {finishOptions.map(({ id, label }) => (
                  <Select.Option key={`finish-${id}`} value={id}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {showOther && (
              <Form.Item
                label="Finish Details"
                name="otherfinish"
                rules={[
                  {
                    required: true,
                    message: 'Please specify required finish',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
