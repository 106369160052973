import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';
import { convertUnits } from '../../../../utils/unit-conversion';

export const Overview = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  const { boundaryConditions, solution } = project;
  const dPmax = boundaryConditions.maxPressureDrop;
  const Tmax = boundaryConditions.maxTemperatureRise;
  // display solution results in project units
  const solutionTmax = convertUnits({
    value: solution.maximumTemperature,
    to: Tmax.units,
  });
  const solutiondPmax = convertUnits({
    value: solution.fluidPressureDrop,
    to: dPmax.units,
  });
  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Overview')} />
      <section>
        <LabeledValue label={t('Maximum Temperature')} value={solutionTmax} />
        <LabeledValue label={t('Project Max')} value={Tmax} />
        <LabeledValue
          label={t('Maximum Pressure Drop')}
          value={solutiondPmax}
        />
        <LabeledValue label={t('Project Max')} value={dPmax} />
      </section>
    </div>
  );
};
