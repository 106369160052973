import React, { useState } from 'react';
import { Popover } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { HeatSourcePopOver } from './HeatSourcePopOver';
import { Movable } from './Movable';
import './heat-source.less';

const HeatSource = ({
  index,
  isSelected,
  heatSource,
  update,
  onMouseDown,
  disabled,
  scaleFactor,
  invalid = false,
}) => {
  const [showPopOver, setShowPopOver] = useState(false);

  return (
    <Movable
      scaleFactor={scaleFactor}
      height={heatSource.size.height}
      width={heatSource.size.width}
      xPos={heatSource.position.x}
      yPos={heatSource.position.y}
      disabled={disabled || showPopOver}
      onUpdate={(position) => {
        update({
          ...heatSource,
          position,
        });
      }}
      onMouseDown={onMouseDown}
    >
      <div
        className={`heat-source ${
          isSelected ? 'active' : disabled ? 'disabled' : ''
        } ${invalid ? 'invalid' : ''}`}
      >
        {!disabled && (
          <>
            <div className="heat-source-popover-wrapper">
              <Popover
                content={
                  <HeatSourcePopOver index={index} heatSource={heatSource} />
                }
                title={`Heat Source: ${heatSource.label}`}
                trigger="click"
                visible={showPopOver}
                placement="right"
                onVisibleChange={setShowPopOver}
                arrowPointAtCenter
                overlayStyle={{ width: '224px' }}
              >
                <EditOutlined />
              </Popover>
            </div>
            <div className="heat-source-content">
              <div className="label">{heatSource.label}</div>
              <div className="power">
                {heatSource.power.value}
                {heatSource.power.units}
              </div>
            </div>
          </>
        )}
      </div>
    </Movable>
  );
};

export default HeatSource;
