import React, { useState, useEffect } from 'react';
import API from '@aws-amplify/api';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { message, PageHeader, Spin } from 'antd';
import { createProject, updateProject } from '../../graphql/mutations';
import { initialProjectState, projectState } from '../../state/project';
import { userState } from '../../state/user';
import { initialViewState, viewState } from '../../state/view';
import { ProjectForm } from './ProjectForm';
import { getProject } from '../../graphql/queries';
import { initialSettings } from './initial-settings';

export const ProjectEdit = ({
  id = 'new-project',
  reset = false,
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const setView = useSetRecoilState(viewState);
  const user = useRecoilValue(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [editProject, setEditProject] = useState(initialProjectState);
  const [refreshKey, setRefreshKey] = useState(0);

  const catchError = (error) => {
    message.error(t('An error occurred'));
    console.error(error);
  };

  const handleEditProject = ({ id }) => {
    if (id === 'new-project') {
      setEditProject(initialProjectState);
    } else {
      setIsLoading(true);
      API.graphql({
        query: getProject,
        variables: {
          id,
        },
      })
        .then((result) => {
          setEditProject(result.data.getProject);
        })
        .catch((error) => console.log('ProjectEdit', error))
        .finally(() => setIsLoading(false));
    }
  };

  // refreshKey ensure we reload the updated project with successive edits
  useEffect(() => {
    handleEditProject({ id });
  }, [id, refreshKey]);

  const onFinish = (values) => {
    setIsLoading(true);
    // create new project or update existing project
    const isNew = editProject.id === 'new-project';
    const input = {
      ...editProject,
      ...values,
      userId: user.id,
      auth: user.auth,
    };

    // loading the project will include the extrusion, mbf, and aquaSurf objects
    let { createdAt, updatedAt, owner, ...updateInput } = input;

    // is new project then set initial defaults
    // remove the placeholder new-project id so gql creates a unique ID
    // do not require authMode: 'AMAZON_COGNITO_USER_POOLS',
    const { id, ...newInput } = isNew
      ? initialSettings(updateInput)
      : updateInput;
    const gqlInput = isNew ? newInput : updateInput;

    API.graphql({
      query: isNew ? createProject : updateProject,
      variables: {
        input: gqlInput,
      },
    })
      .then((result) => {
        const savedProject = isNew
          ? result.data.createProject
          : result.data.updateProject;
        if (isNew) {
          message.success(t('Project created'));
          setProject(savedProject);
          setView({
            ...initialViewState,
            id: 'design',
            progress: 0,
            projectId: savedProject.id,
          });
        } else {
          if (project.id === savedProject.id) {
            setProject(savedProject);
          }
          message.success(t('Project updated'));
        }
        // close drawer
        onSuccess();
      })
      .catch(catchError)
      .finally(() => {
        setRefreshKey((previousKey) => previousKey + 1);
        setIsLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  return (
    <>
      <PageHeader title={t('Project')} />
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <ProjectForm
          project={editProject}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          isLoading={isLoading}
          reset={reset}
        />
      )}
    </>
  );
};
