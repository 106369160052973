import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import { projectState } from '../../../../state/project';
import { SectionHeading } from '../../../SectionHeading';

const { Text } = Typography;

export const HeatSources = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  const round = (value) => Math.round(value * 100) / 100;

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Heat Sources')} />
      <section>
        <Row>
          <Col span={8}>
            <Text strong>{t('Label')}</Text>
          </Col>
          <Col span={8}>
            <Text strong>{t('Average Temp')}</Text>
          </Col>
          <Col span={8}>
            <Text strong>{t('Maximum Temp')}</Text>
          </Col>
        </Row>
        {project.solution.perHeatSource.map(
          (hs, index) =>
            project.heatSources[index] && (
              <Row key={index}>
                <Col span={8}>
                  <Text>{project.heatSources[index].label}</Text>
                </Col>
                <Col span={8}>
                  <Text>
                    {round(hs.hskAverageT.value)}
                    {hs.hskAverageT.units}
                  </Text>
                </Col>
                <Col span={8}>
                  <Text>
                    {round(hs.hskMaximumT.value)}
                    {hs.hskMaximumT.units}
                  </Text>
                </Col>
              </Row>
            )
        )}
      </section>
    </div>
  );
};
