import { atom, selector } from 'recoil';
import API from '@aws-amplify/api';
import { listUsers } from '../graphql/queries';
import { languageCodes } from '../utils/i18n';

export const initialUserState = {
  email: '',
  name: '',
  roles: '',
  language: languageCodes[0],
  country: { value: '', label: '' },
  settings: JSON.stringify({ showSidePanel: false, showIntro: true }),
};

export const userState = atom({
  key: 'userState',
  default: initialUserState,
});

export const isSignedIn = selector({
  key: 'userAuthState',
  get: ({ get }) => {
    const user = get(userState);
    return user.email !== '';
  },
});

// will throw "no user" error if user is not signed in
export const listUsersQuery = selector({
  key: 'listUsers',
  get: async ({ get }) => {
    const response = await API.graphql({
      query: listUsers,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
    if (response.error) {
      throw response.error;
    }
    const user = response.data.listUsers.items.length
      ? response.data.listUsers.items[0]
      : initialUserState;
    // remove unwanted keys
    const { createdAt, updatedAt, owner, projects, ...rest } = user;
    return rest;
  },
});
