import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';

const { Text } = Typography;

export const BoundaryConditions = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  const projectTypeKey = project.type.toLowerCase();

  let showAltitude = false;
  let showMaxPressureDrop = false;
  let noPressureLimit = null;
  let noTempLimit = null;

  switch (project.type) {
    case 'EXTRUSION':
    case 'MBF':
      const { airFlow } = project[projectTypeKey];
      showMaxPressureDrop = airFlow.type === 'FORCEDCONVECTION';
      noPressureLimit =
        project.boundaryConditions.maxPressureDrop.value === 0
          ? 'No Limit'
          : null;
      noTempLimit =
        project.boundaryConditions.maxTemperatureRise.value === 0
          ? 'No Limit'
          : null;
      showAltitude = true;
      break;

    case 'AQUASURF':
      showMaxPressureDrop = true;
      break;

    default:
  }

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Boundary Conditions')} />
      <section>
        <Text strong>{t('Environmental Conditions')}</Text>
        <LabeledValue
          label={t('Ambient Temperature')}
          value={project.boundaryConditions.ambientTemperature}
        />
        {showAltitude && (
          <LabeledValue
            label={t('Altitude')}
            value={project.boundaryConditions.altitude}
          />
        )}
      </section>
      {['EXTRUSION', 'MBF'].includes(project.type) && (
        <section>
          <Text strong>{t('Physical Envelope')}</Text>
          <LabeledValue
            label={t('Max Width')}
            value={project.boundaryConditions.maxSize.width}
          />
          <LabeledValue
            label={t('Max Length')}
            value={project.boundaryConditions.maxSize.length}
          />
          <LabeledValue
            label={t('Max Height')}
            value={project.boundaryConditions.maxSize.height}
          />
        </section>
      )}
      <section>
        <Text strong>{t('Performance Requirements')}</Text>
        <LabeledValue
          label={t('Max Temperature')}
          value={noTempLimit || project.boundaryConditions.maxTemperatureRise}
        />
        {showMaxPressureDrop && (
          <LabeledValue
            label={t('Max Pressure Drop')}
            value={
              noPressureLimit || project.boundaryConditions.maxPressureDrop
            }
          />
        )}
      </section>
    </div>
  );
};
