import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Tooltip, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { projectState } from '../../state/project';
import Measurement from '../Measurement';

export const HeatSourcePopOver = ({ index, heatSource }) => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const [updatedHeatSource, setUpdatedHeatSource] = useState(heatSource);

  // must update with local state as the popover does not update
  // correctly on subsequent launches
  useEffect(() => {
    setUpdatedHeatSource(heatSource);
  }, [heatSource]);

  const updateHeatSourceProperty = (property) => {
    const hs = { ...updatedHeatSource, ...property };
    const updatedHeatSources = [...project.heatSources];
    updatedHeatSources.splice(index, 1, hs);
    setProject({ ...project, heatSources: updatedHeatSources });
    setUpdatedHeatSource(hs);
  };

  return (
    <div className="heat-source-popover">
      <label className="title">{t('Label')}</label>
      <Input
        value={updatedHeatSource.label}
        onChange={(e) => updateHeatSourceProperty({ label: e.target.value })}
      />
      <Measurement.Power
        label={t('Power')}
        value={updatedHeatSource.power}
        minimum={{type: 'POWER', value: 0, units: 'W'}}
        onChange={(power) => updateHeatSourceProperty({ power })}
      />
      <label className="title">{t('Size')}</label>
      <Measurement.Dimension
        label={t('W')}
        value={updatedHeatSource.size.width}
        minimum={{type: 'DIMENSION', value: 0, units: 'mm'}}
        onChange={(width) => {
          const size = { ...updatedHeatSource.size, width };
          updateHeatSourceProperty({ size });
        }}
      />
      <div>
        <Measurement.Dimension
          label={t('H')}
          value={updatedHeatSource.size.height}
          minimum={{type: 'DIMENSION', value: 0, units: 'mm'}}
          onChange={(height) => {
            const size = { ...updatedHeatSource.size, height };
            updateHeatSourceProperty({ size });
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <label className="title">{t('Position')}</label>
        <Tooltip
          placement="topRight"
          title={t(
            'Position is calculated using the bottom left corner of the heat source'
          )}
        >
          <QuestionCircleOutlined style={{ marginTop: '5px' }} />
        </Tooltip>
      </div>
      <Measurement.Dimension
        label={t('X')}
        value={updatedHeatSource.position.x}
        minimum={{type: 'DIMENSION', value: 0, units: 'mm'}}
        onChange={(x) => {
          const position = { ...updatedHeatSource.position, x };
          updateHeatSourceProperty({ position });
        }}
      />
      <div>
        <Measurement.Dimension
          label={t('Y')}
          value={updatedHeatSource.position.y}
          minimum={{type: 'DIMENSION', value: 0, units: 'mm'}}
          onChange={(y) => {
            const position = { ...updatedHeatSource.position, y };
            updateHeatSourceProperty({ position });
          }}
        />
      </div>
    </div>
  );
};
