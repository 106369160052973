import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button } from 'antd';
import {
  RotateRightOutlined,
  CopyOutlined,
  AimOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const HeatSourceControls = ({
  disabled,
  centreOnBaseplate,
  rotate,
  copy,
  remove,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('Rotate')}>
        <Button disabled={disabled} onClick={rotate}>
          <RotateRightOutlined />
        </Button>
      </Tooltip>
      <Tooltip title={t('Copy')}>
        <Button disabled={disabled} onClick={copy}>
          <CopyOutlined />
        </Button>
      </Tooltip>
      <Tooltip title={t('Center on base')}>
        <Button disabled={disabled} onClick={centreOnBaseplate}>
          <AimOutlined />
        </Button>
      </Tooltip>
      <Tooltip title={t('Remove')}>
        <Button disabled={disabled} onClick={remove}>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </>
  );
};

export default HeatSourceControls;
