import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import API from '@aws-amplify/api';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import _flattenDeep from 'lodash/flattenDeep';
import _sortBy from 'lodash/sortBy';
import { message, notification } from 'antd';
import { estimatorGetRequest } from '../../graphql/queries';
import { viewState } from '../../state/view';
import { projectState } from '../../state/project';
import { validEstimateState } from '../../state/app';
import { rtoolsApiEndpoint } from '../../utils/config';
import { FinBarChart } from './FinBarChart';
import { FinOptionButtons } from './FinOptionButtons';
import { FinDataGrid } from './FinDataGrid';
import { convertValueUnitsTo } from '../../utils/unit-conversion';
import './fin-selector.less';
import rehypeRaw from 'rehype-raw';
import { Hub } from '@aws-amplify/core';
import _uniqueId from 'lodash/uniqueId';

const initialButtonData = {
  suggested: { type: '-', height: 0 },
  lowpressure: { type: '-', height: 0 },
  custom: { type: '-', height: 0 },
};

const initialRanges = {
  T: { min: 0, max: 100 },
  dP: { min: 0, max: 100 },
  cost: { min: 0, max: 100 },
};

const getEstimatorData = async ({ url }) => {
  try {
    const response = await API.graphql({
      query: estimatorGetRequest,
      variables: { url },
    });
    return JSON.parse(response.data.estimatorGetRequest);
  } catch (error) {
    console.error('getEstimatorData', error);
    return { error };
  }
};

export const FinSelector = () => {
  const { t } = useTranslation();
  const view = useRecoilValue(viewState);
  const [project, setProject] = useRecoilState(projectState);
  const setValidEstimate = useSetRecoilState(validEstimateState);
  const [finDefinitions, setFinsDefinitions] = useState([]);
  const [estimates, setEstimates] = useState(null);
  const [ranges, setRanges] = useState(initialRanges);
  const [buttonData, setButtonData] = useState(initialButtonData);
  const [currentURLSearch, setCurrentURLSearch] = useState('');
  const [activeRequest, setActiveRequest] = useState(false);
  const id = useRef(_uniqueId('finselector-')).current;

  const setSelectedFin = ({ option, fin, height }) => {
    const mbf = {
      ...project.mbf,
      finOption: option,
      fin: {
        ...project.mbf.fin,
        type: fin,
        height: { ...project.mbf.fin.height, value: height },
      },
    };
    setProject({ ...project, mbf });
  };

  const dispatchError = ({ id, error = false }) => {
    const step = 'finSelection';
    Hub.dispatch('ui', {
      event: 'validation',
      data: { step, id, error },
      message: step,
    });
  };

  const getFinDefinitions = async () => {
    dispatchError({ id: `${id}-D`, error: true });
    setValidEstimate(false);
    const fins = await getEstimatorData({
      url: `${rtoolsApiEndpoint}/findefs`,
    });
    setFinsDefinitions(_sortBy(fins, ['fin_pitch', 'fin_thickness']));
    dispatchError({ id: `${id}-D`, error: false });
  };

  //const getURL = () =>
  const { baseSize, mbf, boundaryConditions, heatSources } = project;
  // calc heat source totals
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const ns = heatSources.length;
  const load = heatSources.map(({ power }) => power.value).reduce(reducer, 0);
  const sw = heatSources
    .map(({ size }) => convertValueUnitsTo({ value: size.width, to: 'mm' }))
    .reduce(reducer, 0);
  const sl = heatSources
    .map(({ size }) => convertValueUnitsTo({ value: size.height, to: 'mm' }))
    .reduce(reducer, 0);

  // chart max markers
  const markerTemperature = convertValueUnitsTo({
    value: project.boundaryConditions.maxTemperatureRise,
    to: 'C',
  });

  const markerPressure = convertValueUnitsTo({
    value: project.boundaryConditions.maxPressureDrop,
    to: 'Pa',
  });

  const { finOption, fin } = project.mbf;
  const selectedFinButton = {
    option: finOption,
    type: fin.type,
    height: fin.height.value,
  };

  // this is so that the estimates return all values and do
  // not apply the Tmax and dPmax constraints so we can display
  // Max markers in selector chart and users can select fins that
  // exceed their max
  const applyConstraints = true;

  // use URL constructor
  const url = new URL(rtoolsApiEndpoint);
  url.pathname = '/estimates/all';

  // no need to construct searchParams if zero load = no request
  if (load !== 0) {
    const { setup, forcedConvectionType, type, fan } = project.mbf.airFlow;
    const flowRate = convertValueUnitsTo({
      value: mbf.airFlow.flowRate,
      to: 'cfm',
    });
    const velocity = convertValueUnitsTo({
      value: mbf.airFlow.velocity,
      to: 'm/s',
    });
    const dPmax = convertValueUnitsTo({
      value: boundaryConditions.maxPressureDrop,
      to: 'Pa',
    });
    const Tmax = convertValueUnitsTo({
      value: boundaryConditions.maxTemperatureRise,
      to: 'C',
    });

    url.searchParams.append(
      'bw',
      convertValueUnitsTo({
        value: baseSize.width,
        to: 'mm',
      })
    );
    url.searchParams.append(
      'bh',
      convertValueUnitsTo({
        value: baseSize.height,
        to: 'mm',
      })
    );
    url.searchParams.append(
      'bl',
      convertValueUnitsTo({
        value: baseSize.length,
        to: 'mm',
      })
    );
    url.searchParams.append('sw', sw);
    url.searchParams.append('sl', sl);
    url.searchParams.append('load', load);
    url.searchParams.append('ns', ns);
    if (type === 'FORCEDCONVECTION' && forcedConvectionType === 'VELOCITY') {
      url.searchParams.append('velocity', velocity);
    }
    if (type === 'FORCEDCONVECTION' && forcedConvectionType === 'FLOWRATE') {
      url.searchParams.append('flowrate', flowRate);
    }
    if (type === 'FORCEDCONVECTION' && forcedConvectionType === 'FAN') {
      url.searchParams.append('fan', fan.id);
      url.searchParams.append('setup', setup);
    }
    url.searchParams.append(
      'Tamb',
      convertValueUnitsTo({
        value: boundaryConditions.ambientTemperature,
        to: 'C',
      })
    );
    url.searchParams.append(
      'altitude',
      convertValueUnitsTo({
        value: boundaryConditions.altitude,
        to: 'm',
      })
    );
    if (type === 'NATURALCONVECTION') {
      url.searchParams.append('hskOrient', mbf.airFlow.orientation.charAt(0));
    }
    url.searchParams.append(
      'hmax',
      convertValueUnitsTo({
        value: boundaryConditions.maxSize.height,
        to: 'mm',
      })
    );
    if (applyConstraints) {
      url.searchParams.append('Tmax', Tmax);
    }
    if (applyConstraints && type === 'FORCEDCONVECTION') {
      url.searchParams.append('dPmax', dPmax);
    }
  }

  const baseHeight = convertValueUnitsTo({
    value: baseSize.height,
    to: 'mm',
  });

  const maxHeight = convertValueUnitsTo({
    value: boundaryConditions.maxSize.height,
    to: 'mm',
  });

  const getFinEstimates = async () => {
    if (activeRequest) return;
    setActiveRequest(true);
    dispatchError({ id, error: true });
    setValidEstimate(false);
    // do not allow invalid request to estimator if there are no heat sources
    const estimateMessage = message.loading('Calculating fin estimates', 0);
    setEstimates(null);
    setRanges(initialRanges);
    setButtonData(initialButtonData);

    const estimatesAll = await getEstimatorData({ url });

    if (estimatesAll.error) {
      estimateMessage();
      message.error(
        t(
          'Unable to calculate estimates. Adjust Boundary, Source, or Flow conditions'
        )
      );
      console.error(estimatesAll.error.errors);
      setActiveRequest(false);
      dispatchError({ id, error: true });
    } else {
      // flatten estimates in array with fin data (type) for easier handling
      // todo - the estimates service should return fins as a flattened array, not object > key > array
      const estimateData = _flattenDeep(
        Object.keys(estimatesAll.fins).map((key) =>
          estimatesAll.fins[key].map((estimate) => ({
            fin: key,
            ...estimate,
          }))
        )
      );

      // match fins to project selection
      const { finOption, fin } = project.mbf;
      const option =
        finOption === 'LOWPRESSUREDROP'
          ? 'LOWPRESSURE'
          : finOption.toLowerCase();
      const projectFin = {
        option,
        type: fin.type,
        height: fin.height.value,
      };

      const buttonData = { ...initialButtonData };
      let ranges = { ...initialRanges };

      if (estimateData.length) {
        ranges = {
          T: { min: estimateData[0].T, max: estimateData[0].T },
          dP: { min: estimateData[0].dP, max: estimateData[0].dP },
          cost: { min: estimateData[0].cost, max: estimateData[0].cost },
        };
        estimateData.forEach((estimate, index) => {
          // get min/max for chart range
          ['T', 'dP', 'cost'].forEach((key) => {
            if (estimate[key] < ranges[key].min)
              ranges[key].min = estimate[key];
            if (estimate[key] > ranges[key].max)
              ranges[key].max = estimate[key];
          });
          // default value for custom is first estimate
          if (
            (projectFin.option !== 'custom' && index === 0) ||
            (projectFin.option === 'custom' &&
              projectFin.type === estimate.fin &&
              projectFin.height === estimate.fh)
          ) {
            buttonData.custom = {
              ...buttonData.custom,
              type: estimate.fin,
              height: estimate.fh,
              T: estimate.T,
              dP: estimate.dP,
              cost: estimate.cost,
            };
          }
          // get value for suggested, low pressure by matching fin height
          ['suggested', 'lowpressure'].forEach((key) => {
            if (
              estimate.fin === estimatesAll[key].fin &&
              estimate.fh === estimatesAll[key].fh
            ) {
              buttonData[key] = {
                ...buttonData[key],
                type: estimatesAll[key].fin,
                height: estimatesAll[key].fh,
                T: estimate.T,
                dP: estimate.dP,
                cost: estimate.cost,
              };
            }
          });
        });
      }
      // set to selected project fin option
      setButtonData(buttonData);
      setRanges(ranges);
      setEstimates(estimatesAll);
      if (project.mbf.fin.type === '' || project.mbf.fin.height.value === 0) {
        const { type, height } = buttonData.suggested;
        setSelectedFin({ option: 'SUGGESTED', fin: type, height });
      }

      notification.open({
        message: 'Fin Estimator',
        description: (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={t(
              `ESTIMATOR_MESSAGE_CODE_${estimatesAll.message.toUpperCase()}`
            )}
          />
        ),
        duration: 0,
      });
    }
    setActiveRequest(false);
    setValidEstimate(true);
    dispatchError({ id, error: false });
    estimateMessage();
  };

  const setCustomButton = (buttonData) => {
    setButtonData(buttonData);
    const { type, height } = buttonData.custom;
    setSelectedFin({ option: 'CUSTOM', fin: type, height });
  };

  // only need to get the fin definitions once
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getFinDefinitions();
  }, []);

  // update estimates if valid load, we are in Fin Selector view
  // and estimator url search params have changed
  useEffect(() => {
    if (
      finDefinitions.length !== 0 &&
      view.progress === 3 &&
      currentURLSearch !== url.search &&
      load !== 0
    ) {
      setCurrentURLSearch(url.search);
      getFinEstimates();
    }
  }, [view.progress, url.search, currentURLSearch, load, finDefinitions]);

  useEffect(() => {
    if (
      project.status !== 'SOLUTION' &&
      (project.mbf.fin.type === '' || currentURLSearch !== url.search)
    ) {
      dispatchError({ id, error: true });
    }
  }, [project.status, project.mbf.fin.type, url.search, currentURLSearch]);

  useEffect(() => {
    if (view.id !== 'design' || view.progress !== 3) {
      notification.destroy();
    }
    return notification.destroy;
  }, [view.id, view.progress]);

  const type = {
    SUGGESTED: 'suggested',
    LOWPRESSUREDROP: 'lowpressure',
    CUSTOM: 'custom',
  }[project.mbf.finOption];

  return (
    <>
      <FinOptionButtons
        data={buttonData}
        selectedFinButton={selectedFinButton}
        setSelectedFin={setSelectedFin}
      />
      <FinBarChart
        type={type}
        title="Temperature - &Delta;T"
        label="Hotter (worse)"
        min={ranges.T.min}
        max={ranges.T.max}
        value={buttonData[type].T}
        suggested={buttonData.suggested.T}
        marker={markerTemperature}
      />
      <FinBarChart
        type={type}
        title="Pressure Drop - &Delta;P"
        label="Higher (worse)"
        min={ranges.dP.min}
        max={ranges.dP.max}
        value={buttonData[type].dP}
        suggested={buttonData.suggested.dP}
        marker={markerPressure}
      />
      <FinBarChart
        type={type}
        title="Relative Cost - &Delta;$"
        label="More Expensive"
        max={ranges.cost.max}
        min={ranges.cost.min}
        value={buttonData[type].cost}
        suggested={buttonData.suggested.cost}
        showMarker={false}
      />
      <FinDataGrid
        type={selectedFinButton.option.toLowerCase()}
        fins={finDefinitions}
        estimates={estimates}
        buttonData={buttonData}
        setButtonData={setCustomButton}
        maxT={markerTemperature}
        maxP={markerPressure}
        maxHeight={maxHeight}
        baseHeight={baseHeight} // add baseplate thickness
      />
      <div className="fin-selector-option-text">
        <p>Fin Selection offer three potential solutions:</p>
        <p>
          1. <strong>Suggested</strong> gives lowest heatsink cost that meets
          thermal performance within 10mm of overall max height.
        </p>
        <p>
          2. <strong>Lowest Pressure</strong> design that meets thermal
          performance requirement within 10mm of overall max height.
        </p>
        <p>
          3. <strong>Custom</strong> allows you to choose your own fin design
          from the table of standard options. If none of the standard options
          meet your requirements, please contact an engineer to assist with a
          custom solution.
        </p>
      </div>
    </>
  );
};
