import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Image } from 'cloudinary-react';
import { Space, Row, Col } from 'antd';
import { projectState } from '../../state/project';
import { envelopes } from '../../state/units';
import { Measurement } from '../Measurement';
import { AquasurfVolume } from './AquasurfVolume';
import { MBFVolume } from './MBFVolume';
import { ExtrusionVolume } from './ExtrusionVolume';
import { cloudinaryImages } from '../../utils/config';
import './boundary-conditions.less';

const url = `${cloudinaryImages}/v1/images`;

export const BoundaryConditions = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { boundaryConditions } = project;
  const envelope = envelopes[project.type.toLowerCase()];
  const {
    ambientTemperature,
    altitude,
    maxTemperatureRise,
  } = envelope.boundaryConditions;

  const image = {
    AQUASURF: 'baseplate-dimensions-aquasurf.png',
    EXTRUSION: 'baseplate-dimensions-extrusion.png',
    MBF: 'baseplate-dimensions-mbf.png',
  }[project.type];

  const setBoundaryConditions = (updatedBoundaryConditions) =>
    setProject({
      ...project,
      boundaryConditions: {
        ...boundaryConditions,
        ...updatedBoundaryConditions,
      },
    });

  switch (project.type) {
    case 'EXTRUSION':
    case 'MBF':
      return (
        <div className="boundary-conditions">
          <Row gutter={64}>
            <Col span={7}>
              <Space direction="vertical" size="large">
                <div>
                  <h3>{`1. ${t('Environmental Conditions')}`}</h3>
                </div>
                <div>
                  <h4>{`1.1 ${t('Ambient Temperature')}`}</h4>
                  <p>
                    Define the ambient temperature surrounding your cooling
                    project. This temperature is used at the inlet where flows
                    enters the system. A higher inlet temperature will result in
                    a higher solution temperature. A default temperature value
                    of 40&deg;C is selected if you do not know the actual
                    working ambient temperature of your project.
                  </p>
                  <div className="measurement-input-width">
                    <Measurement.Temperature
                      step="boundaryConditions"
                      value={boundaryConditions.ambientTemperature}
                      minimum={ambientTemperature.min}
                      maximum={ambientTemperature.max}
                      onChange={(ambientTemperature) =>
                        setBoundaryConditions({
                          ambientTemperature: {
                            ...boundaryConditions.ambientTemperature,
                            ...ambientTemperature,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div>
                  <h4>{`1.2 ${t('Altitude')}`}</h4>
                  <p>
                    Define the altitude in which your project will be working
                    in. This value determines the pressure at the inlet where
                    flows enters the system. A higher inlet temperature will
                    result in a higher solution temperature.
                  </p>
                  <div className="measurement-input-width">
                    <Measurement.Altitude
                      step="boundaryConditions"
                      value={boundaryConditions.altitude}
                      minimum={altitude.min}
                      maximum={altitude.max}
                      onChange={(altitude) =>
                        setBoundaryConditions({
                          altitude: {
                            ...boundaryConditions.altitude,
                            ...altitude,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              </Space>
            </Col>
            <Col span={10}>
              <Space direction="vertical" size="small">
                <div>
                  <h3>{`2. ${t('Heatsink Volume')}`}</h3>
                </div>
                <p>
                  Define the maximum heatsink volume of your project. This
                  defines heatsink baseplate dimensions and the overall space
                  volume available for your system. This will be used to
                  recommend fin sizes.
                </p>
                <div className="dimension-image">
                  <Image publicId={`${url}/${image}`} width="480" />
                </div>
                {project.type === 'MBF' && <MBFVolume />}
                {project.type === 'EXTRUSION' && <ExtrusionVolume />}
              </Space>
            </Col>
            <Col span={7}>
              <div>
                <h3>{`3. ${t('Maximum Heatsink Temperature')}`}</h3>
              </div>
              <p>
                Define the maximum temperature allowable within your project.
                This allows estimator to recommend heatsink designs to meet this
                performance. This is a mandatory field and needs to be defined
                before proceeding to the next step.
              </p>
              <div>
                <div className="measurement-input-width">
                  <Measurement.Temperature
                    step="boundaryConditions"
                    value={boundaryConditions.maxTemperatureRise}
                    minimum={maxTemperatureRise.min}
                    maximum={maxTemperatureRise.max}
                    onChange={(maxTemperatureRise) =>
                      setBoundaryConditions({
                        maxTemperatureRise: {
                          ...boundaryConditions.maxTemperatureRise,
                          ...maxTemperatureRise,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );

    case 'AQUASURF':
      return (
        <div className="boundary-conditions">
          <Row gutter={64}>
            <Col span={7}>
              <div>
                <h3>{`1. ${t('Ambient Temperature')}`}</h3>
                <p>
                  Define the ambient temperature surrounding your cooling
                  project. This temperature is used at the inlet where flows
                  enters the system. A higher inlet temperature will result in a
                  higher solution temperature. A default temperature value of
                  40&deg;C is selected if you do not know the actual working
                  ambient temperature of your project.
                </p>
                <div className="measurement-input-width">
                  <Measurement.Temperature
                    step="boundaryConditions"
                    value={boundaryConditions.ambientTemperature}
                    minimum={ambientTemperature.min}
                    maximum={ambientTemperature.max}
                    onChange={(ambientTemperature) =>
                      setBoundaryConditions({
                        ambientTemperature: {
                          ...boundaryConditions.ambientTemperature,
                          ...ambientTemperature,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div>
                <h3>{`2. ${t('Volume')}`}</h3>
              </div>
              <p>
                The baseplate dimensions define the simulation volume of your
                project and does NOT include pipe headers. Headers extend above
                and below the length (Y) of the baseplate.
              </p>
              <div className="dimension-image">
                <Image publicId={`${url}/${image}`} width="480" />
              </div>
              <AquasurfVolume />
            </Col>
            <Col span={7}>
              <div>
                <h3>{`3. ${t('Maximum Temperature')}`}</h3>
              </div>
              <p>
                Define the maximum temperature allowable within your project.
                This allows estimator to recommend designs to meet this
                performance. This is a mandatory field and needs to be defined
                before proceeding to the next step.
              </p>
              <div>
                <div className="measurement-input-width">
                  <Measurement.Temperature
                    step="boundaryConditions"
                    value={boundaryConditions.maxTemperatureRise}
                    minimum={maxTemperatureRise.min}
                    maximum={maxTemperatureRise.max}
                    onChange={(maxTemperatureRise) =>
                      setBoundaryConditions({
                        maxTemperatureRise: {
                          ...boundaryConditions.maxTemperatureRise,
                          ...maxTemperatureRise,
                        },
                      })
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );

    default:
      return null;
  }
};
