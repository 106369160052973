import React from 'react';
import { useRecoilValue } from 'recoil';
import convert from 'convert-units';
import Draggable from 'react-draggable';
import { projectState } from '../../state/project';
import { viewState } from '../../state/view';
import { convertToMillimeters } from '../../utils/unit-conversion';

// Translates an origin position (bottom left corner) to a display position (top left corner)
// returns the display position in mm
const getDisplayPosition = (height, xPos, yPos, baseSize, scaleFactor) => {
  const baseDisplayLength = convertToMillimeters(baseSize.length);
  const displayHeight = convertToMillimeters(height);
  const convertedPosition = {
    x: convertToMillimeters(xPos),
    y: convertToMillimeters(yPos),
  };
  return {
    x: convertedPosition.x * scaleFactor,
    y: (baseDisplayLength - convertedPosition.y - displayHeight) * scaleFactor,
  };
};

// Converts a display position (top left corner) to an origin position (bottom left corner)
// returns the origin position in mm
const getOriginPosition = (displayPosition, height, baseSize, scaleFactor) => {
  const baseDisplayLength = convertToMillimeters(baseSize.length);
  const displayHeight = convertToMillimeters(height);
  const x = displayPosition.x / scaleFactor;
  const y =
    baseDisplayLength - (displayPosition.y / scaleFactor + displayHeight);
  return {
    x,
    y,
  };
};

// Converts the position from mm to the stored units (mm/inch) and adds on type
const getStoredPosition = (position, xPos, yPos) => {
  const convertedPosition = {
    x: convert(position.x).from('mm').to(xPos.units),
    y: convert(position.y).from('mm').to(yPos.units),
  };
  return {
    x: {
      type: 'DIMENSION',
      value: convertedPosition.x,
      units: xPos.units,
    },
    y: {
      type: 'DIMENSION',
      value: convertedPosition.y,
      units: yPos.units,
    },
  };
};

export const Movable = ({
  children,
  height,
  width,
  xPos,
  yPos,
  disabled,
  onUpdate,
  onMouseDown,
  scaleFactor,
  horizontalOnly,
}) => {
  const project = useRecoilValue(projectState);
  const view = useRecoilValue(viewState);
  const { baseSize } = project;
  const displayHeight = convertToMillimeters(height);
  const displayWidth = convertToMillimeters(width);
  const baseDisplayLength = convertToMillimeters(baseSize.length);
  const baseDisplayWidth = convertToMillimeters(baseSize.width);
  let uiPosition = getDisplayPosition(
    height,
    xPos,
    yPos,
    baseSize,
    scaleFactor
  );

  return (
    <Draggable
      disabled={disabled}
      bounds="parent"
      onDrag={(_, dragData) => {
        uiPosition.x = uiPosition.x + dragData.deltaX;
        if (!horizontalOnly) {
          uiPosition.y = uiPosition.y + dragData.deltaY;
        }
      }}
      onStop={() => {
        const position = getOriginPosition(
          uiPosition,
          height,
          baseSize,
          scaleFactor
        );

        // Snap to grid
        if (view.sourceLayout.snapToGrid) {
          const tileWidth =
            baseDisplayWidth / view.sourceLayout.gridChunkFactor;
          const tileHeight =
            baseDisplayLength / view.sourceLayout.gridChunkFactor;
          position.x = Math.round(position.x / tileWidth) * tileWidth;
          position.y = Math.round(position.y / tileHeight) * tileHeight;
        }

        // Boundary detection
        position.x = Math.max(position.x, 0);
        position.y = Math.max(position.y, 0);
        if (position.y + displayHeight > baseDisplayLength) {
          position.y = baseDisplayLength - displayHeight;
        }
        if (position.x + displayWidth > baseDisplayWidth) {
          position.x = baseDisplayWidth - displayWidth;
        }

        onUpdate(getStoredPosition(position, xPos, yPos));
      }}
      onMouseDown={() => !disabled && onMouseDown()}
      position={uiPosition}
    >
      <div
        style={{
          height: displayHeight * scaleFactor,
          width: displayWidth * scaleFactor,
          position: 'absolute',
        }}
      >
        {children}
      </div>
    </Draggable>
  );
};
