import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Switch } from 'antd';

const { Item } = Form;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 20 },
};

export const SettingsForm = ({ user, onFinish, onFinishFailed, isLoading }) => {
  const { t } = useTranslation();
  const loadSettings = user.settings ? JSON.parse(user.settings) : {};
  const defaultSettings = { showSidePanel: false, showIntro: false };
  const userSettings = { ...defaultSettings, ...loadSettings };
  const [settings, setSettings] = useState(userSettings);

  return (
    <Form
      name="settings"
      size="small"
      initialValues={user}
      onFinish={() => {
        onFinish({ settings: JSON.stringify(settings) });
      }}
      onFinishFailed={onFinishFailed}
      {...layout}
    >
      <Item label={t('Show Project Steps Info')}>
        <Switch
          checked={settings.showIntro}
          onChange={(value) => {
            setSettings((prevState) => ({
              ...prevState,
              showIntro: value,
            }));
          }}
          checkedChildren={'Yes'}
          unCheckedChildren={'No'}
        />
      </Item>

      <Item {...tailLayout}>
        <Button
          type="primary"
          size="medium"
          htmlType="submit"
          loading={isLoading}
        >
          {t('Save')}
        </Button>
      </Item>
    </Form>
  );
};

export default SettingsForm;
