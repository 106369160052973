import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import Storage from '@aws-amplify/storage';
import { Row, Col } from 'antd';
import { projectState } from '../../../state/project';
import { Overview } from './sections/Overview';
import { HeatSources } from './sections/HeatSources';
import { Files } from './sections/Files';
import { Images } from './sections/Images';

export const SolutionSummary = () => {
  const project = useRecoilValue(projectState);
  const [fileList, setFileList] = useState([]);
  const getFile = async (key) => await Storage.vault.get(key);
  const listFiles = () =>
    Storage.list(`${project.id}/`, { level: 'private' })
      .then(async (result) => {
        let files = [];
        for (let index in result) {
          const file = result[index];
          const url = await getFile(file.key);
          const name = file.key.split('/').pop();
          files.push({ ...file, url, name });
        }
        setFileList(files);
      })
      .catch((err) => console.log(err));

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    listFiles();
  }, [project.status]);

  const qom = fileList.find(({ key }) => key.endsWith('.qom'));
  const pdf = fileList.find(({ key }) => key.endsWith('.pdf'));
  const side = fileList.find(({ key }) => key.endsWith('v_iso_3.png'));
  const top = fileList.find(({ key }) => key.endsWith('v_bot_3.png'));
  const x3d = fileList.find(({ key }) => key.endsWith('.x3d'));
  const step = fileList.find(({ key }) => key.endsWith('.step'));
  const scalarbar = fileList.find(({ key }) => key.endsWith('scalarbar.png'));

  // return null when no solution so we do not have to handle errors in 3rd party (x3d)
  // rendering due to invalid data
  return project.status === 'SOLUTION' ? (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Overview />
        </Col>
        <Col span={12}>
          {qom && pdf && step && <Files qom={qom} pdf={pdf} step={step} />}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HeatSources />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {side && <Images side={side} top={top} x3d={x3d} scalarbar={scalarbar} />}
        </Col>
      </Row>
    </>
  ) : null;
};
