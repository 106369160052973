import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button, Switch, InputNumber, Tooltip, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { projectState, pipe } from '../../state/project';
import { viewState } from '../../state/view';
import { removeItemAtIndex } from '../../utils/recoil-utils';
import { convertToMillimeters } from '../../utils/unit-conversion';
import PipeControls from './PipeControls';
import './controls.less';
import { Measurement } from '../Measurement';
import {envelopes} from '../../state/units';

const Controls = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { aquasurf } = project;
  const envelope = envelopes[project.type.toLowerCase()];
  const { pipes } = project.aquasurf;
  const [view, setView] = useRecoilState(viewState);
  const { tubeOuterDiameter } = project.aquasurf;

  const setPipeConfiguration = (updatedPipeConfiguration) => {
    setProject({
      ...project,
      aquasurf: {
        ...project.aquasurf,
        ...updatedPipeConfiguration,
      },
    });
  };

  const setPipes = (pipes) =>
    setProject({ ...project, aquasurf: { ...project.aquasurf, pipes } });

  const selectedPipeIndex =
    view.selectedStep &&
    view.selectedStep.startsWith('pipe') &&
    view.selectedStep.split('-')[1]
      ? parseInt(view.selectedStep.split('-')[1])
      : null;

  const isMetric = project.units === 'METRIC';
  // tube position offset from 0,0 by tube center line
  const tubeCenterOffset = isMetric
    ? convertToMillimeters({ value: tubeOuterDiameter, units: 'in' }) / 2
    : tubeOuterDiameter / 2;

  const addPipe = () => {
    let newPipe = {
      ...pipe,
      x: {
        ...pipe.x,
        value: pipe.x.value + tubeCenterOffset,
        units: isMetric ? 'mm' : 'in',
      },
      y: {
        ...pipe.y,
        units: isMetric ? 'mm' : 'in',
      },
    };
    const updated = [...pipes, newPipe];
    setPipes(updated);
    setView({ ...view, selectedStep: `pipe-${updated.length - 1}` });
  };

  const remove = () => {
    if (selectedPipeIndex === null) {
      return;
    }
    const updated = removeItemAtIndex(pipes, selectedPipeIndex);
    setPipes(updated);
    setView({ ...view, selectedStep: '' });
  };

  const setMinimumFlowRate = () => {
    return envelope.flowRate[aquasurf.tubeMaterial][
      aquasurf.tubeOuterDiameter.toString()
      ].min;
  };

  const setMaximumFlowRate = () => {
    const maxFlowRate =
      envelope.flowRate[aquasurf.tubeMaterial][
        aquasurf.tubeOuterDiameter.toString()
        ].max;

    // parallel multiply rate by number of pipes
    return aquasurf.pipeConfiguration === 'PARALLEL' && aquasurf.pipes.length
      ? { ...maxFlowRate, value: maxFlowRate.value * aquasurf.pipes.length }
      : maxFlowRate;
  };

  return (
    <div className="pipe-control-wrapper-container">
      <div />
      <div className="control-wrapper">
        <div className="control-group">
          <div>
            <label>{t('Pipe')}</label>
          </div>
          <div>
            <Tooltip title={t('Add')}>
              <Button onClick={addPipe}>
                <PlusOutlined />
              </Button>
            </Tooltip>
            <PipeControls
              disabled={selectedPipeIndex === null}
              remove={remove}
            />
          </div>
        </div>
        <div className="control-group">
          <label>{t('Configuration')}</label>
          <div>
            <Radio.Group
              onChange={(event) =>
                setPipeConfiguration({
                  pipeConfiguration: event.target.value,
                })
              }
              value={project.aquasurf.pipeConfiguration}
            >
              <Radio value="PARALLEL">{t('Parallel')}</Radio>
              <Radio value="SERPENTINE">{t('Serpentine')}</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="control-group">
          <label>{t('Flow Rate')}</label>
          <div>
            <Measurement.FlowRateLiquid
              step="pipeLayout"
              value={aquasurf.flowRate}
              minimum={setMinimumFlowRate()}
              maximum={setMaximumFlowRate()}
              onChange={(flowRate) =>
                setPipeConfiguration({
                  flowRate: {
                    ...aquasurf.flowRate,
                    ...flowRate,
                  },
                })
              }
            />
          </div>
        </div>
        <div className="control-group">
          <div>
            <label>{t('Zoom')}</label>
          </div>
          <div>
            <InputNumber
              min={1}
              max={10}
              step={0.5}
              value={view.pipeLayout.scaleFactor}
              onChange={(scaleFactor) => {
                setView({
                  ...view,
                  pipeLayout: {
                    ...view.pipeLayout,
                    scaleFactor,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="control-group">
          <div>
            <label>{t('Grid Size')}</label>
          </div>
          <InputNumber
            min={2}
            max={100}
            value={view.pipeLayout.gridChunkFactor}
            onChange={(gridChunkFactor) => {
              setView({
                ...view,
                pipeLayout: {
                  ...view.pipeLayout,
                  gridChunkFactor,
                },
              });
            }}
            disabled={!view.pipeLayout.snapToGrid}
          />
        </div>
        <div className="control-group">
          <div>
            <label>{t('Snap to Grid')}</label>
          </div>
          <div>
            <Switch
              checked={view.pipeLayout.snapToGrid}
              onChange={() => {
                setView({
                  ...view,
                  pipeLayout: {
                    ...view.pipeLayout,
                    snapToGrid: !view.pipeLayout.snapToGrid,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default Controls;
