import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Popover } from 'antd';
import { projectState } from '../../state/project';
import { viewState } from '../../state/view';
import { replaceItemAtIndex } from '../../utils/recoil-utils';
import AxisLabel from './AxisLabel';
import HeatSource from './HeatSource';
import { FinDirection } from './FinDirection';
import { useTranslation } from 'react-i18next';
import { convertToMillimeters } from '../../utils/unit-conversion';
import { EditOutlined } from '@ant-design/icons';
import './baseplate.less';
import { BaseplatePopOver } from './BaseplatePopOver';
import { Hub } from '@aws-amplify/core';
import { hasValidHeatSources } from '../../utils/heatsource-validators';
import _uniqueId from 'lodash/uniqueId';

const Baseplate = ({ disabled }) => {
  const { t } = useTranslation();
  const [showPopOver, setShowPopOver] = useState(false);
  const [invalidHeatSources, setInvalidHeatSources] = useState([]);
  const [project, setProject] = useRecoilState(projectState);
  const [view, setView] = useRecoilState(viewState);
  const { heatSources, baseSize } = project;
  const setHeatSources = (heatSources) =>
    setProject({ ...project, heatSources });

  const updateHeatSource = (index, heatSource) => {
    const newHeatSources = replaceItemAtIndex(heatSources, index, {
      ...heatSource,
    });
    setHeatSources(newHeatSources);
    // validate for overlap
  };

  const id = useRef(_uniqueId('baseplate-')).current;
  const noFins = project.type === 'AQUASURF';

  const baseplateBorderSize = 2;
  const baseplateSelected = view.selectedStep === 'baseplate';
  const baseplateBorder = `${baseplateBorderSize}px solid ${
    baseplateSelected ? '#f46a25' : disabled ? '#d6d6d6' : '#c2c2c2'
  }`;

  let baseplateDisplayLength = convertToMillimeters(baseSize.length);
  let baseplateDisplayWidth = convertToMillimeters(baseSize.width);

  const scaleFactor = disabled
    ? Math.min(600 / baseplateDisplayLength, 350 / baseplateDisplayWidth)
    : view.sourceLayout.scaleFactor;

  baseplateDisplayLength *= scaleFactor;
  baseplateDisplayWidth *= scaleFactor;

  const dispatchError = ({ id, error = false }) => {
    const step = 'sourceLayout';
    Hub.dispatch('ui', {
      event: 'validation',
      data: { step, id, error },
      message: step,
    });
  };

  // no heatsources
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatchError({ id, error: heatSources.length === 0 });
  }, [heatSources.length]);

  // overlapping heatsources
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const validHeatSources = hasValidHeatSources(project);
    if (validHeatSources && validHeatSources.split(':').length === 2) {
      setInvalidHeatSources(
        validHeatSources
          ?.split(':')[1]
          .split(', ')
          .map((i) => Number(i)) || []
      );
    } else {
      setInvalidHeatSources([]);
    }
    dispatchError({ id, error: !!validHeatSources });
  }, [heatSources]);

  return (
    <div
      className="heat-source-layout"
      style={{
        display: 'grid',
        gridTemplateColumns: !disabled ? '1fr 90px auto 90px 1fr' : 'auto',
        gridTemplateRows: !disabled ? 'auto 70px' : 'auto',
      }}
    >
      <div />
      {!disabled && (
        <AxisLabel.Vertical
          selected={baseplateSelected}
          label={`${Math.round(baseSize.length.value * 100) / 100} ${
            baseSize.length.units
          }`}
        />
      )}
      <div>
        <div
          className={`baseplate-top-edge ${noFins ? 'no-fins' : ''}`}
          style={{
            width: `${baseplateDisplayWidth + baseplateBorderSize + 3}px`,
          }}
        />
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            className={`baseplate ${
              view.sourceLayout.snapToGrid && !disabled ? 'grid' : ''
            } ${!disabled ? 'active' : ''} ${
              baseplateSelected ? 'selected' : ''
            }`}
            style={{
              height: `${baseplateDisplayLength}px`,
              width: `${baseplateDisplayWidth}px`,
              border: baseplateBorder,
              backgroundSize: `${
                baseplateDisplayWidth / view.sourceLayout.gridChunkFactor
              }px ${
                baseplateDisplayLength / view.sourceLayout.gridChunkFactor
              }px`,
              boxSizing: 'content-box',
            }}
            onClick={(e) => {
              if (!disabled && e.target === e.currentTarget) {
                setView({
                  ...view,
                  selectedStep: baseplateSelected ? '' : 'baseplate',
                });
              }
            }}
          >
            {!disabled && (
              <div className="baseplate-popover-wrapper">
                <Popover
                  content={<BaseplatePopOver />}
                  title={
                    project.type === 'EXTRUSION' ? `Extrusion` : `Baseplate`
                  }
                  trigger="click"
                  visible={showPopOver || baseplateSelected}
                  placement="right"
                  onVisibleChange={setShowPopOver}
                  arrowPointAtCenter
                  overlayStyle={{ width: '224px' }}
                >
                  <EditOutlined
                    onClick={() =>
                      setView({
                        ...view,
                        selectedStep: baseplateSelected ? '' : 'baseplate',
                      })
                    }
                  />
                </Popover>
              </div>
            )}
            {heatSources.map((heatSource, index) => {
              const id = `heatsource-${index}`;
              return (
                <HeatSource
                  index={index}
                  key={id}
                  heatSource={heatSource}
                  disabled={disabled}
                  isSelected={view.selectedStep === id}
                  update={(hs) => {
                    updateHeatSource(index, hs);
                  }}
                  onMouseDown={() =>
                    setView({
                      ...view,
                      selectedStep: view.selectedStep === id ? 0 : id,
                    })
                  }
                  scaleFactor={scaleFactor}
                  invalid={invalidHeatSources.includes(index + 1)}
                />
              );
            })}
          </div>
          <div
            className={`baseplate-side-edge ${disabled ? 'disabled' : ''} ${
              noFins ? 'no-fins' : ''
            }`}
            style={{
              height: `${baseplateDisplayLength + baseplateBorderSize + 3}px`,
            }}
          />
        </div>
      </div>
      {!disabled && (
        <div style={{ marginLeft: '16px' }}>
          <FinDirection
            label={noFins ? t('Pipe Direction') : t('Fin Direction')}
            bidirectional={true}
          />
        </div>
      )}
      {!disabled && (
        <>
          <div />
          <div />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <div
              style={{
                color: baseplateSelected ? '#f46a25' : '#878787',
                margin: '8px 0 0 0',
                fontWeight: 600,
              }}
            >
              (0,0)
            </div>
          </div>
        </>
      )}
      {!disabled && (
        <AxisLabel.Horizontal
          selected={baseplateSelected}
          label={`${Math.round(baseSize.width.value * 100) / 100} ${
            baseSize.width.units
          }`}
        />
      )}
      <div />
    </div>
  );
};

export default Baseplate;
