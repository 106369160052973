import React from 'react';
import { useRecoilValue } from 'recoil';
import { Row, Col } from 'antd';
import { projectState } from '../../../state/project';
import { Baseplate } from './sections/Baseplate';
import { BoundaryConditions } from './sections/BoundaryConditions';
import { HeatSources } from './sections/HeatSources';
import { Pipes } from './sections/Pipes';
import { FlowConditions } from './sections/FlowConditions';
import { Extrusion } from './sections/Extrusion';
import { Overview } from './sections/Overview';
import { FinSelection } from './sections/FinSelection';

export const ProjectSummary = () => {
  const project = useRecoilValue(projectState);
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Overview />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <BoundaryConditions />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {project.type === 'EXTRUSION' && <Extrusion />}
          {project.type !== 'EXTRUSION' && <Baseplate />}
        </Col>
      </Row>
      {project.type === 'AQUASURF' && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Pipes />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HeatSources />
        </Col>
      </Row>
      {['EXTRUSION', 'MBF'].includes(project.type) && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <FlowConditions />
          </Col>
        </Row>
      )}
      {project.type === 'MBF' && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <FinSelection />
          </Col>
        </Row>
      )}
    </>
  );
};
