export const replaceItemAtIndex = (arr, index, newValue) => {
  if (!Number.isInteger(index)) {
    throw new Error('replaceItemAtIndex expected index to be a number');
  }
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = (arr, index) => {
  if (!Number.isInteger(index)) {
    throw new Error('removeItemAtIndex expected index to be a number');
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};
