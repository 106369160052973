import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Image } from 'cloudinary-react';
import { useTranslation } from 'react-i18next';
import { HeatSourceLayout } from '../HeatSourceLayout';
import { Select, Row, Col, Space } from 'antd';
import { projectState } from '../../state/project';
import { envelopes } from '../../state/units';
import {
  listLibraryExtrusionsQuery,
  listLibraryFansQuery,
} from '../../state/library';
import Measurement from '../Measurement';
import { SectionHeading } from '../SectionHeading';
import { cloudinaryImages } from '../../utils/config';
import { convertValueUnitsTo } from '../../utils/unit-conversion';
import './flow-conditions.less';

const { Option } = Select;

const url = `${cloudinaryImages}/v1/images/`;

const CenteredMeasurement = ({ label, children }) => (
  <div className="center-label">
    <div className="center-label-content">
      {label}
      {children}
    </div>
  </div>
);

export const FlowConditions = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const projectTypeKey = project.type.toLowerCase();
  const fans = useRecoilValue(listLibraryFansQuery);
  const extrusions = useRecoilValue(listLibraryExtrusionsQuery);
  // airFlow is child of project type project.extrusion, project.mbf
  const { airFlow } = project[projectTypeKey];
  const { boundaryConditions } = project;
  const envelope = envelopes[projectTypeKey];
  const { maxPressureDrop } = envelope.boundaryConditions;
  const { flowRateVelocity, velocity } = envelope.airFlow;
  const [filteredFans, setFilteredFans] = useState(fans);
  // use es6 Set to get unique values
  const sizes = [...new Set(fans.map((fan) => fan.size).sort((a, b) => a - b))];
  const impingePercentages = [10, 25, 50, 100];

  const getExtrusionHeight = (id) => {
    const extrusion = extrusions.find((extrusion) => extrusion.id === id);
    return extrusion
      ? extrusion.dimensions.height
      : project.boundaryConditions.maxSize.height;
  };

  // Flow Rate is calculated using the heatsink frontal/profile area (W x H)
  const calcFlowRate = (velocity) => {
    let hskHeight = convertValueUnitsTo({
      value: envelope.boundaryConditions.maxSize.height.default,
      to: 'm',
    });

    switch (project.type) {
      case 'EXTRUSION':
        hskHeight = convertValueUnitsTo({
          value: getExtrusionHeight(project.extrusion.id),
          to: 'm',
        });
        break;
      case 'MBF':
        hskHeight =
          convertValueUnitsTo({ value: project.mbf.fin.height, to: 'm' }) +
          convertValueUnitsTo({ value: project.baseSize.height, to: 'm' });
        break;

      default:
      // nothing
    }

    const value =
      convertValueUnitsTo({ value: velocity, to: 'm/s' }) *
      convertValueUnitsTo({ value: project.baseSize.width, to: 'm' }) *
      hskHeight;
    return { type: 'FLOWRATE', value, units: 'm3/s' };
  };

  const flowRateMin = calcFlowRate(flowRateVelocity.min);
  const flowRateMax = calcFlowRate(flowRateVelocity.max);

  const setAirFlow = (updatedAirFlow) =>
    setProject({
      ...project,
      [projectTypeKey]: {
        ...project[projectTypeKey],
        airFlow: {
          ...project[projectTypeKey].airFlow,
          ...updatedAirFlow,
        },
      },
    });

  const setBoundaryConditions = (updatedBoundaryConditions) =>
    setProject({
      ...project,
      boundaryConditions: {
        ...boundaryConditions,
        ...updatedBoundaryConditions,
      },
    });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const size = parseInt(airFlow.fan.size) || 80;
    const filteredFans = fans.filter((fan) => fan.size === size);
    setFilteredFans(filteredFans);
    const { createdAt, updatedAt, ...fan } = filteredFans[0];
    setAirFlow({ fan });
  }, [fans, airFlow.fan.size]);

  const ButtonCard = ({ title = '', image = '' }) => (
    <div className="button-card">
      <div className="title">{title}</div>
      <Image publicId={`${url}${image}`} height={50} />
    </div>
  );

  // css background arrow style
  const { type, setup, forcedConvectionType } = airFlow;
  let flowArrow = 'push';
  if (type === 'FORCEDCONVECTION') {
    if (setup === 'PUSHPULL') flowArrow = 'push-pull';
    if (setup === 'IMPINGE') flowArrow = 'impinge';
  }
  if (type === 'NATURALCONVECTION' && airFlow.orientation === 'HORIZONTAL') {
    flowArrow = 'impinge';
  }

  const handleFanSizeChange = (value) => {
    const filteredFans = fans.filter((fan) => fan.size === parseInt(value));
    setFilteredFans(filteredFans);
  };

  const filteredFanIndex = () => {
    const index = filteredFans.findIndex((fan) => fan.id === airFlow.fan.id);
    return index === -1 ? null : index;
  };

  return (
    <div className="flow-conditions">
      <Row>
        <Col span={16}>
          <SectionHeading label={t('Air Flow Type')} />
          <Row>
            <Col span={6} offset={1}>
              <button
                className={airFlow.type === 'NATURALCONVECTION' ? 'active' : ''}
                onClick={() => {
                  setAirFlow({ type: 'NATURALCONVECTION' });
                }}
              >
                <ButtonCard
                  title={t('Natural Convection')}
                  image="convection-horizontal.png"
                />
              </button>
            </Col>
            <Col span={6} offset={1}>
              <button
                className={airFlow.type === 'FORCEDCONVECTION' ? 'active' : ''}
                onClick={() => {
                  setAirFlow({ type: 'FORCEDCONVECTION' });
                }}
              >
                <ButtonCard
                  title={t('Forced Convection')}
                  image="fan-push.png"
                />
                <CenteredMeasurement label={t('Max Pressure Drop')}>
                  <Measurement.Pressure
                    step="flowConditions"
                    value={boundaryConditions.maxPressureDrop}
                    minimum={maxPressureDrop.min}
                    maximum={maxPressureDrop.max}
                    onChange={(maxPressureDrop) =>
                      setBoundaryConditions({
                        maxPressureDrop: {
                          ...boundaryConditions.maxPressureDrop,
                          ...maxPressureDrop,
                        },
                      })
                    }
                    disabled={airFlow.type !== 'FORCEDCONVECTION'}
                  />
                </CenteredMeasurement>
              </button>
            </Col>
          </Row>
          {airFlow.type === 'FORCEDCONVECTION' && (
            <>
              <SectionHeading label={t('Forced Flow Type')} />
              <Row>
                <Col span={6} offset={1}>
                  <button
                    className={
                      airFlow.forcedConvectionType === 'FAN' ? 'active' : ''
                    }
                    onClick={() => {
                      setAirFlow({ forcedConvectionType: 'FAN' });
                    }}
                  >
                    <ButtonCard title={t('Fan')} image="button-fan.png" />
                    <CenteredMeasurement>
                      <Space direction="vertical">
                        <Select
                          style={{ width: 150 }}
                          placeholder="Size (mm)"
                          onClick={(event) => event.stopPropagation()}
                          onChange={handleFanSizeChange}
                          value={filteredFans[0].size}
                        >
                          {sizes.map((size, index) => (
                            <Option key={`size-${index}`} value={size}>
                              {size}mm
                            </Option>
                          ))}
                        </Select>
                        <Select
                          style={{ width: 150 }}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(index) => {
                            const {
                              createdAt,
                              updatedAt,
                              ...fan
                            } = filteredFans[index];
                            setAirFlow({ fan });
                          }}
                          value={filteredFanIndex()}
                          placeholder="Select fan"
                        >
                          {filteredFans.map(({ id, label }, index) => (
                            <Option key={`fan-${index}`} value={index}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </Space>
                    </CenteredMeasurement>
                  </button>
                </Col>
                <Col span={6} offset={1}>
                  <button
                    className={
                      airFlow.forcedConvectionType === 'FLOWRATE'
                        ? 'active'
                        : ''
                    }
                    onClick={() =>
                      setAirFlow({ forcedConvectionType: 'FLOWRATE' })
                    }
                  >
                    <ButtonCard
                      title={t('Flow Rate')}
                      image="button-flow-rate.png"
                    />
                    <CenteredMeasurement>
                      <Measurement.FlowRate
                        step="flowConditions"
                        value={airFlow.flowRate}
                        minimum={flowRateMin}
                        maximum={flowRateMax}
                        onChange={(flowRate) => setAirFlow({ flowRate })}
                        disabled={airFlow.forcedConvectionType !== 'FLOWRATE'}
                      />
                    </CenteredMeasurement>
                  </button>
                </Col>
                <Col span={6} offset={1}>
                  <button
                    className={
                      airFlow.forcedConvectionType === 'VELOCITY'
                        ? 'active'
                        : ''
                    }
                    onClick={() =>
                      setAirFlow({ forcedConvectionType: 'VELOCITY' })
                    }
                  >
                    <ButtonCard
                      title={t('Velocity')}
                      image="button-flow-velocity.png"
                    />
                    <CenteredMeasurement>
                      <Measurement.FlowVelocity
                        step="flowConditions"
                        value={airFlow.velocity}
                        minimum={velocity.min}
                        maximum={velocity.max}
                        onChange={(velocity) => setAirFlow({ velocity })}
                        disabled={airFlow.forcedConvectionType !== 'VELOCITY'}
                      />
                    </CenteredMeasurement>
                  </button>
                </Col>
              </Row>
            </>
          )}
          {airFlow.type === 'NATURALCONVECTION' && (
            <>
              <SectionHeading label={t('Orientation')} />
              <Row>
                <Col span={6} offset={1}>
                  <button
                    className={
                      airFlow.orientation === 'HORIZONTAL' ? 'active' : ''
                    }
                    onClick={() => {
                      setAirFlow({ orientation: 'HORIZONTAL' });
                    }}
                  >
                    <ButtonCard
                      title={t('Horizontal')}
                      image="convection-horizontal.png"
                    />
                  </button>
                </Col>
                <Col span={6} offset={1}>
                  <button
                    className={
                      airFlow.orientation === 'VERTICAL' ? 'active' : ''
                    }
                    onClick={() => {
                      setAirFlow({ orientation: 'VERTICAL' });
                    }}
                  >
                    <ButtonCard
                      title={t('Vertical')}
                      image="convection-vertical.png"
                    />
                  </button>
                </Col>
              </Row>
            </>
          )}
          {airFlow.type === 'FORCEDCONVECTION' && (
            <>
              {forcedConvectionType === 'FAN' && (
                <SectionHeading label={t('Fan Placement')} />
              )}
              {forcedConvectionType !== 'FAN' && (
                <SectionHeading label={t('Flow Setup')} />
              )}
              <Row>
                <Col span={6} offset={1}>
                  <button
                    className={airFlow.setup === 'PUSH' ? 'active' : ''}
                    onClick={() => {
                      setAirFlow({ setup: 'PUSH' });
                    }}
                  >
                    <ButtonCard title={t('Push')} image="fan-push.png" />
                  </button>
                </Col>
                {airFlow.forcedConvectionType === 'FAN' && (
                  <Col span={6} offset={1}>
                    <button
                      className={airFlow.setup === 'PUSHPULL' ? 'active' : ''}
                      onClick={() => {
                        setAirFlow({ setup: 'PUSHPULL' });
                      }}
                    >
                      <ButtonCard
                        title={t('Push Pull')}
                        image="fan-push-pull.png"
                      />
                    </button>
                  </Col>
                )}
                <Col span={6} offset={1}>
                  <button
                    className={airFlow.setup === 'IMPINGE' ? 'active' : ''}
                    onClick={(event) => {
                      const impingePercentage = airFlow.impingePercentage || 25;
                      setAirFlow({ setup: 'IMPINGE', impingePercentage });
                    }}
                  >
                    <ButtonCard title={t('Impinge')} image="fan-impinge.png" />
                    <CenteredMeasurement label={t('Fan Heatsink Coverage')}>
                      <Select
                        style={{ width: 150 }}
                        onClick={(event) => event.stopPropagation()}
                        onChange={(impingePercentage) =>
                          setAirFlow({ impingePercentage })
                        }
                        value={airFlow.impingePercentage || 25}
                      >
                        {impingePercentages.map((percentage, index) => (
                          <Option key={`impinge-${index}`} value={percentage}>
                            {percentage}%
                          </Option>
                        ))}
                      </Select>
                    </CenteredMeasurement>
                  </button>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col span={8}>
          <>
            {/* <SectionHeading /> */}
            <div className="heat-source-layout-container">
              <HeatSourceLayout disabled={true} />
              <div className={`overlay-flow-image ${flowArrow}`} />
            </div>
          </>
        </Col>
      </Row>
    </div>
  );
};
