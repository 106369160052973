import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { viewState } from '../../state/view';
import './fin-option-buttons.less';

const buttonHelp = {
  suggested:
    'Suggested which gives lowest heatsink cost that meets thermal performance within 10mm of overall max height.',
  lowpressure:
    'Lowest pressure design that meets thermal performance requirement within 10mm of overall max height.',
  custom: 'Custom allows you to choose your own fin design from the table of standard options. If none of the standard options meet your requirements, please contact an engineer to assist with a custom solution.',
};

export const FinOptionButtons = ({
  data,
  selectedFinButton,
  setSelectedFin,
}) => {
  const { suggested, lowpressure, custom } = data;
  const { t } = useTranslation();
  const [view, setView] = useRecoilState(viewState);
  const buttonEls = {
    suggested: useRef(null),
    lowpressure: useRef(null),
    custom: useRef(null),
  };

  const selectedButtonType =
    selectedFinButton.option === 'LOWPRESSUREDROP'
      ? 'lowpressure'
      : selectedFinButton.option.toLowerCase();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    buttonEls[selectedButtonType].current.focus();
  }, [buttonEls]);

  const onButtonClick = ({ type }) => {
    setView({ ...view, finSelector: { ...view.finSelector, type } });
    const option =
      type === 'lowpressure' ? 'LOWPRESSUREDROP' : type.toUpperCase();
    setSelectedFin({ option, fin: data[type].type, height: data[type].height });
  };

  const selectedType = (type) =>
    type === selectedButtonType ? `button-${type} active` : `button-${type}`;

  const ButtonCard = ({
    button = '',
    title = '',
    description = '',
    type = '',
    length = '',
    disabled = false,
  }) =>
    disabled ? (
      <div className="button-card">
        <div className="title">{title}</div>
        <div className="description">No suitable fins available</div>
      </div>
    ) : (
      <div className="button-card">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="type">{type}</div>
        <div className="length">{length}</div>
        <div className="tooltip">
          <Tooltip title={buttonHelp[button]}>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </div>
    );

  const Button = ({ type, disabled, children }) => (
    <button
      ref={buttonEls[type]}
      className={`${selectedType(type)} ${disabled ? 'disabled' : ''} `}
      onClick={() => onButtonClick({ type })}
      disabled={disabled}
    >
      {children}
    </button>
  );

  return (
    <div className="fin-option-buttons-wrapper">
      <Button type="suggested" disabled={suggested.type === '-'}>
        <ButtonCard
          button="suggested"
          title={t('Suggested')}
          description="Best cost/performance"
          type={suggested.type}
          length={`${suggested.height}mm`}
          disabled={suggested.type === '-'}
        />
      </Button>
      <Button type="lowpressure" disabled={lowpressure.type === '-'}>
        <ButtonCard
          button="lowpressure"
          title={t('Low Pressure')}
          description="Best for..."
          type={lowpressure.type}
          length={`${lowpressure.height}mm`}
          disabled={lowpressure.type === '-'}
        />
      </Button>
      <Button type="custom" disabled={custom.type === '-'}>
        <ButtonCard
          button="custom"
          title={t('Custom')}
          description="Choose your own"
          type={custom.type}
          length={`${custom.height}mm`}
          disabled={custom.type === '-'}
        />
      </Button>
    </div>
  );
};
