import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu, Select, Space, Row, Col } from 'antd';
import { projectState } from '../../state/project';
import { envelopes } from '../../state/units';
import { convertValueUnitsTo } from '../../utils/unit-conversion';
import { Measurement } from '../Measurement';
import './pipe-selector.less';

export const PipeConfiguration = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { baseSize, aquasurf, boundaryConditions } = project;
  const envelope = envelopes[project.type.toLowerCase()];

  const percentageWaterMenu = ({ tubeMaterial, fluid }) => (
    <Menu
      onClick={({ key }) => {
        setProject({
          ...project,
          aquasurf: {
            ...aquasurf,
            tubeMaterial,
            fluid,
            percentageWater: parseInt(key),
          },
        });
      }}
    >
      <Menu.Item key="30">30%</Menu.Item>
      <Menu.Item key="40">40%</Menu.Item>
      <Menu.Item key="50">50%</Menu.Item>
      <Menu.Item key="60">60%</Menu.Item>
      <Menu.Item key="70">70%</Menu.Item>
    </Menu>
  );

  const onClickHandler = ({ tubeMaterial, fluid }) => {
    if (tubeMaterial && fluid) {
      setProject({
        ...project,
        aquasurf: {
          ...aquasurf,
          tubeMaterial,
          fluid,
        },
      });
    }
  };

  const ItemRow = ({ tubeMaterial, fluid, invalid = false }) => {
    const selected =
      tubeMaterial === aquasurf.tubeMaterial && fluid === aquasurf.fluid;
    const css = ['item'];
    if (invalid) {
      css.push('invalid');
    } else if (selected) {
      css.push('selected');
    }

    return ['EGLYCOL', 'PGLYCOL'].includes(fluid) ? (
      <Dropdown overlay={percentageWaterMenu({ tubeMaterial, fluid })}>
        <div className={css.join(' ')}>
          {selected ? `${aquasurf.percentageWater}%` : '\u2228'}
        </div>
      </Dropdown>
    ) : (
      <div
        className={css.join(' ')}
        onClick={() => onClickHandler({ tubeMaterial, fluid })}
      />
    );
  };

  const setPipeConfiguration = (updatedPipeConfiguration) =>
    setProject({
      ...project,
      aquasurf: {
        ...aquasurf,
        ...updatedPipeConfiguration,
      },
    });

  const changeTubeSize = ({ tubeOuterDiameter }) => {
    const tubeValue = {
      type: 'DIMENSION',
      value: tubeOuterDiameter / 2,
      units: 'in',
    };

    // if tube is larger then check pipe positions are valid
    if (tubeOuterDiameter > aquasurf.tubeOuterDiameter) {
      // map over any pipes and check positions are valid with new pipe
      const pipes = aquasurf.pipes.map((pipe) => {
        let updatedPipeX = { ...pipe.x };
        const maxWidth = convertValueUnitsTo({
          value: baseSize.width,
          to: updatedPipeX.units,
        });
        const offset = convertValueUnitsTo({
          value: tubeValue,
          to: updatedPipeX.units,
        });
        const minOffset = updatedPipeX.value - offset;
        const maxOffset = updatedPipeX.value + offset;
        if (minOffset < 0) {
          updatedPipeX.value = offset;
        } else if (maxOffset > maxWidth) {
          updatedPipeX.value = maxWidth - offset;
        }
        return { ...pipe, x: updatedPipeX };
      });
      // save any changes (include changed pipe)
      setProject({
        ...project,
        aquasurf: {
          ...aquasurf,
          pipes,
          tubeOuterDiameter,
        },
      });
    } else {
      setPipeConfiguration({ tubeOuterDiameter });
    }
  };

  const setMinimumInletTemperature = () => {
    return ['EGLYCOL', 'PGLYCOL'].includes(aquasurf.fluid)
      ? envelope.inletTemperature[
          `${aquasurf.fluid}-${aquasurf.percentageWater}`
        ].min
      : envelope.inletTemperature[aquasurf.fluid].min;
  };

  const setMaximumInletTemperature = () => {
    return ['EGLYCOL', 'PGLYCOL'].includes(aquasurf.fluid)
      ? envelope.inletTemperature[
          `${aquasurf.fluid}-${aquasurf.percentageWater}`
        ].max
      : envelope.inletTemperature[aquasurf.fluid].max;
  };

  return (
    <div className="boundary-conditions">
      <Row gutter={64}>
        <Col span={6}>
          <Space direction="vertical" size="small">
            <div>
              <h3>{t('Inlet Fluid Temperature')}</h3>
              <div className="measurement-input-width">
                <Measurement.Temperature
                  step="pipeConfiguration"
                  value={aquasurf.inletTemperature}
                  minimum={setMinimumInletTemperature()}
                  maximum={setMaximumInletTemperature()}
                  onChange={(inletTemperature) =>
                    setPipeConfiguration({
                      inletTemperature: {
                        ...aquasurf.inletTemperature,
                        ...inletTemperature,
                      },
                    })
                  }
                />
              </div>
            </div>
            {/*<div>
              <h3>{t('Flow Rate')}</h3>
              <div className="measurement-input-width">
                <Measurement.FlowRateLiquid
                  step="pipeConfiguration"
                  value={aquasurf.flowRate}
                  minimum={setMinimumFlowRate()}
                  maximum={setMaximumFlowRate()}
                  onChange={(flowRate) =>
                    setPipeConfiguration({
                      flowRate: {
                        ...aquasurf.flowRate,
                        ...flowRate,
                      },
                    })
                  }
                />
            </div>
            </div>*/}
            <div>
              <h3>{t('Max Allowable Pressure Drop')}</h3>
              <Measurement.Pressure
                step="pipeConfiguration"
                value={boundaryConditions.maxPressureDrop}
                onChange={(maxPressureDrop) =>
                  setProject({
                    ...project,
                    boundaryConditions: {
                      ...boundaryConditions,
                      maxPressureDrop,
                    },
                  })
                }
              />
            </div>
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical" size="small">
            <div>
              <h3>{t('Tube Outer Diameter')}</h3>
              <div className="measurement-input-width">
                <Select
                  defaultValue={aquasurf.tubeOuterDiameter}
                  style={{ width: 150 }}
                  onChange={(tubeOuterDiameter) =>
                    changeTubeSize({
                      tubeOuterDiameter,
                    })
                  }
                >
                  <Select.Option value={0.25}>1/4" (6.35mm)</Select.Option>
                  <Select.Option value={0.375}>3/8" (9.53mm)</Select.Option>
                  <Select.Option value={0.5}>1/2" (12.7mm)</Select.Option>
                  <Select.Option value={0.625}>5/8" (15.88mm)</Select.Option>
                </Select>
              </div>
            </div>
            <div>
              <h3>{t('Tube Embedded Position')}</h3>
              <div className="measurement-input-width">
                <Select
                  defaultValue={aquasurf.embeddedPosition}
                  style={{ width: 150 }}
                  onChange={(embeddedPosition) =>
                    setPipeConfiguration({ embeddedPosition })
                  }
                >
                  <Select.Option value="SOURCE_FLUSH">
                    {t('SOURCE_FLUSH')}
                  </Select.Option>
                  <Select.Option value="OPP_FLUSH">
                    {t('OPP_FLUSH')}
                  </Select.Option>
                </Select>
              </div>
            </div>
          </Space>
        </Col>
        <Col span={12}>
          <h3>{t('Tube Material and Fluid')}</h3>
          <div className="pipe-grid-container">
            <div className="row-0 column-0">Fluid</div>
            <div className="row-0 column-1" />
            <div className="row-0 column-2">Tube Material</div>
            {/*<div className="row-0 column-3">{t('AL')}</div>*/}
            <div className="row-0 column-4">{t('CU')}</div>
            <div className="row-0 column-5">{t('SS')}</div>
            <div className="row-1 column-1">{t('WATER')}</div>
            {/*<div className="item invalid" />*/}
            <ItemRow tubeMaterial="CU" fluid="WATER" />
            <ItemRow tubeMaterial="SS" fluid="WATER" />
            <div className="row-2 column-1">{t('DIWATER')}</div>
            {/*<ItemRow tubeMaterial="AL" fluid="DIWATER" />*/}
            <div className="item invalid" />
            <ItemRow tubeMaterial="SS" fluid="DIWATER" />
            <div className="row-3 column-1">{t('EGLYCOL')}</div>
            {/*<ItemRow tubeMaterial="AL" fluid="EGLYCOL" />*/}
            <ItemRow tubeMaterial="CU" fluid="EGLYCOL" />
            <ItemRow tubeMaterial="SS" fluid="EGLYCOL" />
            <div className="row-4 column-1">{t('PGLYCOL')}</div>
            {/*<ItemRow tubeMaterial="AL" fluid="PGLYCOL" />*/}
            <ItemRow tubeMaterial="CU" fluid="PGLYCOL" />
            <ItemRow tubeMaterial="SS" fluid="PGLYCOL" />
          </div>
          <p>Click to select or hover to select percentage glycol</p>
        </Col>
      </Row>
    </div>
  );
};
