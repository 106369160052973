import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const PipeControls = ({ disabled, remove }) => {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t('Remove')}>
        <Button disabled={disabled} onClick={remove}>
          <DeleteOutlined />
        </Button>
      </Tooltip>
    </>
  );
};

export default PipeControls;
