import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
  AmplifyConfirmSignUp,
  AmplifyForgotPassword,
} from '@aws-amplify/ui-react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { Button, message, Modal } from 'antd';
import { listUsers } from '../graphql/queries';
import { createUser } from '../graphql/mutations';
import { userState, initialUserState, isSignedIn } from '../state/user';
import { viewState, initialViewState } from '../state/view';
import { projectState, initialProjectState } from '../state/project';
import { ProfileForm } from './Profile/ProfileForm';

export const SignInModal = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [initialAuthState, setInitialAuthState] = useState('signin');
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUser] = useRecoilState(userState);
  const isUserSignedIn = useRecoilValue(isSignedIn);
  const setView = useSetRecoilState(viewState);
  const setProject = useSetRecoilState(projectState);
  const [isLoading, setIsLoading] = useState(false);

  // any errors then sign user out
  const signOut = () => {
    // ensure no user session
    Auth.signOut()
      .catch((err) => {})
      .finally(() => {
        setUser(initialUserState);
        setProject(initialProjectState);
        setView(initialViewState);
      });
  };

  const catchError = (error) => {
    setIsLoading(false);
    error.message
      ? message.error(error.message)
      : message.error(t('An error occurred'));
    console.error(error);
    signOut();
  };

  // update state and UI language
  const setAuthUser = (user) => {
    if (user.settings === undefined || user.settings === null) {
      setUser({ ...user, settings: initialUserState.settings });
    } else {
      const settings = JSON.parse(user.settings);
      if (settings.showIntro === undefined) {
        setUser({ ...user, settings: initialUserState.settings });
      } else {
        setUser({ ...initialUserState, ...user });
      }
    }
    i18n.changeLanguage(user.language.value);
    setShowModal(false);
  };

  const createUserProfile = (user) => {
    setIsLoading(true);
    const input = { ...user, ...currentUser };
    API.graphql({
        query: createUser,
        variables: { input },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
      .then((result) => {
        // remove database created keys from response
      const {
        createdAt,
        updatedAt,
        projects,
        ...authUser
        } = result.data.createUser;
      // ensure we start at start view
      setShowProfileModal(false);
      setView({ ...initialViewState, id: 'projects' });
      setIsLoading(false);
      setAuthUser(authUser);
      })
      .catch(catchError);
  };

  // test to resend code
  /*
  const resendAuthCode = (username) => {
    Auth.resendSignUp('uyplhpliirmmmkroxr@wqcefp.com').then(result => console.log(result)).catch(error => console.error(error));
  }
  */

  /*
    Auth.currentUserInfo
      data : {
                id: "us-west-2:377e1df1-698a-4f2e-83a7-b5619c985ac6"
                username: "318c545a-ac94-4179-958e-3248580edb47"
                attributes: {
                  email: "admin@orbital.co.nz"
                  email_verified: true
                  sub: "318c545a-ac94-4179-958e-3248580edb47"
                  }
                }

      username (sub) = owner id added/stored automatically with @auth database inputs
      id required for storage S3 bucket access
   */

  const getUsers = async ({ email, nextToken }) => {
    try {
      const response = await API.graphql({
        query: listUsers,
        variables: {
          filter: { email: { eq: email } },
          nextToken,
        },
      });
      return response.data.listUsers;
    } catch (error) {
      catchError(error);
    }
  };

  const getCurrentUser = () =>
    Auth.currentUserInfo()
      .then(async (data) => {
        // if signed in data can be null, empty object, valid object with id
        if (data && data.id) {
          // query existing user profile
          // does not require adding gql query variable authMode: 'AMAZON_COGNITO_USER_POOLS'
          // listUser should only return a single record due to auth used to create record
          try {
            let res = await getUsers({
              email: data.attributes.email,
              nextToken: null,
            });

            while (res.items.length === 0 && res.nextToken !== null) {
              res = await getUsers({
                email: data.attributes.email,
                nextToken: res.nextToken,
              });
            }

            const users = res.items;
            if (users.length === 0) {
              // create new user profile
              const currentUser = {
                email: data.attributes.email,
                auth: {
                  id: data.id,
                  username: data.attributes.sub,
                },
              };
              setCurrentUser(currentUser);
              setShowProfileModal(true);
            } else if (users.length === 1) {
              // load user profile
              const authUser = {
                ...user,
                ...users[0],
              };
              // todo - remove this is using last view restore (AutoSave)
              setView({ ...initialViewState, id: 'projects' });
              setAuthUser(authUser);
            }
          } catch (error) {
            catchError(error);
          }
        } else {
          return Promise.resolve();
        }
      })
      .catch(catchError);

  // Amplify maintains user session in local storage so check if still valid if user reloads browser
  // fail silently
  useEffect(() => {
    getCurrentUser();
  }, []);

  const displayResendCode = () => {
    setInitialAuthState('confirmSignUp');
    catchError({
      message: 'Email address has been registered but not verified',
    });
  };

  // Hub is Amplify local eventing system
  // https://docs.amplify.aws/lib/utilities/hub?platform=js
  useEffect(() => {
    Hub.listen('auth', (capsule) => {
      const { event, data } = capsule.payload;
      switch (event) {
        // do not use onAuthUIStateChange hook as this triggers multiple renders
        case 'signIn':
          getCurrentUser();
          break;

        case 'forgotPassword_failure':
          displayResendCode();
          break;

        case 'signIn_failure':
          // code, name, message
          console.error('signIn_failure', data);
          switch (data.code) {
            case 'UserNotConfirmedException':
              displayResendCode();
              break;

            case 'UserNotFoundException':
              break;

            default:
              console.log('hub default', event);
              // do nothing
              catchError({
                message: 'Problem with your account - contact administrator',
              });
          }
          break;

        // 'signOut', 'signUp', 'configured'
        default:
        // nothing
      }
    });
    // cleanup
    return () => Hub.remove('auth');
  }, []);

  return (
    <>
      {isUserSignedIn ? null : (
        <Button
          type="primary"
          onClick={(event) => {
            event.stopPropagation();
            setShowModal(true);
          }}
        >
          {t('Sign In')}
        </Button>
      )}
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        closable={false}
        bodyStyle={{
          padding: 0,
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <AmplifyAuthenticator
          usernameAlias="email"
          initialAuthState={initialAuthState}
        >
          <AmplifySignIn slot="sign-in" usernameAlias="email" />
          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              { type: 'email', label: 'Company Email Address' },
              { type: 'password' },
            ]}
            submitButtonText={t('Agree & Sign Up')}
            //handleAuthStateChange={data => console.log('sign up', data)}
            //validationErrors={errors => console.log('sign up', errors)}
          >
            <div slot="header-subtitle">
              By clicking Agree & Sign Up, you agree to the{' '}
              <a
                href="https://www.mersen.com/legal-notice"
                target="_blank"
                rel="noreferrer"
              >
                Mersen User Agreement
              </a>
              ,{' '}
              <a
                href="https://www.mersen.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , and{' '}
              <a
                href="https://www.mersen.com/cookie-charter"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </a>
              .
            </div>
          </AmplifySignUp>
          <AmplifyConfirmSignUp
            slot="confirm-sign-up"
            headerText="Enter the code sent to your email address"
            usernameAlias="email"
          />
          <AmplifyForgotPassword slot="forgot-password" usernameAlias="email" />
        </AmplifyAuthenticator>
      </Modal>
      <Modal
        title="Complete your profile"
        visible={showProfileModal}
        closable={false}
        footer={null}
      >
        <ProfileForm user={user} isLoading={isLoading} onFinish={createUserProfile} />
      </Modal>
    </>
  );
};
