import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import { projectState } from '../../../../state/project';
import { SectionHeading } from '../../../SectionHeading';

const { Text } = Typography;

export const HeatSources = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  const round = (value) => Math.round(value * 100) / 100;

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Heat Sources')} />
      <section>
        <Row>
          <Col span={4}>
            <Text strong>{t('Label')}</Text>
          </Col>
          <Col span={4}>
            <Text strong>{t('Power')}</Text>
          </Col>
          <Col span={8}>
            <Text strong>{t('Position (X,Y)')}</Text>
          </Col>
          <Col span={8}>
            <Text strong>{t('Size (W x H)')}</Text>
          </Col>
        </Row>
        {project.heatSources.map((hs, index) => (
          <Row key={index}>
            <Col span={4}>
              <Text>{hs.label}</Text>
            </Col>
            <Col span={4}>
              <Text>
                {round(hs.power.value)}
                {hs.power.units}
              </Text>
            </Col>
            <Col span={8}>
              <Text>
                {hs.position.x.value.toFixed(1)}
                {hs.position.x.units}, {hs.position.y.value.toFixed(1)}
                {hs.position.y.units}
              </Text>
            </Col>
            <Col span={8}>
              <Text>
                {hs.size.width.value.toFixed(1)}
                {hs.size.width.units} x {hs.size.height.value.toFixed(1)}
                {hs.size.height.units}
              </Text>
            </Col>
          </Row>
        ))}
      </section>
    </div>
  );
};
