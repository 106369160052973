import convert from 'convert-units';

// 1 inch water = 249. ... Pascal
const INH2OPA = 249.08890833333;

const validUnits = (units) => {
  const lookUp = {
    cfm: 'ft3/min',
    gpm: 'gal/min',
    inH2O: 'inH2O',
    // legacy r-tools api returned in_water
    in_water: 'inH2O',
  };
  return lookUp[units] || units;
};

// convertUnits takes value object with units and returns converted value object with to units
export const convertUnits = ({ value, to }) => ({
  ...value,
  value: convertValueUnits({ value: value.value, from: value.units, to }),
  units: to,
});

// convertValueUnitsTo takes value object with units and returns value only (not object) converted to units
export const convertValueUnitsTo = ({ value, to }) =>
  convertValueUnits({ value: value.value, from: value.units, to });

export const convertValueUnits = ({ value, from, to }) => {
  const validFrom = validUnits(from);
  const validTo = validUnits(to);
  try {
    // convert library does not include inches water
    if (validFrom === 'Pa' && validTo === 'inH2O') {
      return value * (1 / INH2OPA);
    }
    if (validFrom === 'inH2O' && validTo === 'Pa') {
      return value * INH2OPA;
    }
    if (validFrom === 'inH2O' && validTo === 'inH2O') {
      return value;
    }
    return convert(value).from(validFrom).to(validTo);
  } catch (error) {
    console.error(
      `Failed to convert from ${from} to ${to} for ${validFrom}
      )} to ${validTo}`
    );
    return value;
  }
};

export const convertToMillimeters = ({ value, units }) =>
  convert(value).from(units).to('mm');
