import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button, Switch, InputNumber, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { projectState, heatSource } from '../../state/project';
import { viewState } from '../../state/view';
import {
  convertValueUnits,
  convertToMillimeters,
} from '../../utils/unit-conversion';
import {
  removeItemAtIndex,
  replaceItemAtIndex,
} from '../../utils/recoil-utils';
import HeatSourceControls from './HeatSourceControls';
import './controls.less';
import { ExtrusionSelector } from '../ExtrusionSelector';

const Controls = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const [showModal, setShowModal] = useState(false);
  const { heatSources } = project;
  const setHeatSources = (heatSources) =>
    setProject({ ...project, heatSources });
  const [view, setView] = useRecoilState(viewState);
  const { baseSize } = project;

  const selectedHeatSourceIndex =
    view.selectedStep &&
    view.selectedStep.startsWith('heatsource') &&
    view.selectedStep.split('-')[1]
      ? parseInt(view.selectedStep.split('-')[1])
      : null;

  const addHeatSource = () => {
    let newHeatSource = {
      ...heatSource,
      label: `${heatSources.length + 1}`,
      size: {
        ...heatSource.size,
        height: {
          ...project.baseSize.length,
          value: project.baseSize.length.value / 2,
        },
        width: {
          ...project.baseSize.width,
          value: project.baseSize.width.value / 2,
        },
      },
      position: {
        x: {
          ...heatSource.position.x,
          units: project.units === 'METRIC' ? 'mm' : 'in',
        },
        y: {
          ...heatSource.position.y,
          units: project.units === 'METRIC' ? 'mm' : 'in',
        },
      },
    };

    const updated = [...heatSources, newHeatSource];
    setHeatSources(updated);
    setView({ ...view, selectedStep: `heatsource-${updated.length - 1}` });
  };

  const remove = () => {
    if (selectedHeatSourceIndex === null) {
      return;
    }
    const updated = removeItemAtIndex(heatSources, selectedHeatSourceIndex);
    setHeatSources(updated);
    setView({ ...view, selectedStep: '' });
  };

  const copy = () => {
    if (selectedHeatSourceIndex === null) {
      return;
    }
    const selected = heatSources[selectedHeatSourceIndex];
    const { x, y } = selected.position;
    const newHeatSource = {
      ...selected,
      label: `${heatSources.length + 1}`,
      position: {
        x: { ...x, value: 0 },
        y: { ...y, value: 0 },
      },
    };
    const updated = [...heatSources, newHeatSource];
    setHeatSources(updated);
    setView({ ...view, selectedStep: `heatsource-${updated.length - 1}` });
  };

  const rotate = () => {
    if (selectedHeatSourceIndex === null) {
      return;
    }
    const selected = heatSources[selectedHeatSourceIndex];
    const { width, height } = selected.size;
    const updatedHeatSource = {
      ...selected,
      size: {
        ...selected.size,
        height: width,
        width: height,
      },
    };
    setHeatSources(
      replaceItemAtIndex(
        heatSources,
        selectedHeatSourceIndex,
        updatedHeatSource
      )
    );
  };

  const centreOnBaseplate = () => {
    if (selectedHeatSourceIndex === null) {
      return;
    }
    const selected = heatSources[selectedHeatSourceIndex];
    const heatSourceDisplayHeight = convertToMillimeters(selected.size.height);
    const heatSourceDisplayWidth = convertToMillimeters(selected.size.width);
    const baseSizeDisplayLength = convertToMillimeters(baseSize.length);
    const baseSizeDisplayWidth = convertToMillimeters(baseSize.width);
    const mmPosition = {
      x: baseSizeDisplayWidth / 2 - heatSourceDisplayWidth / 2,
      y: baseSizeDisplayLength / 2 - heatSourceDisplayHeight / 2,
    };
    const convertedPosition = {
      x: convertValueUnits({
        value: mmPosition.x,
        from: 'mm',
        to: selected.position.x.units,
      }),
      y: convertValueUnits({
        value: mmPosition.y,
        from: 'mm',
        to: selected.position.y.units,
      }),
    };
    const updatedHeatSource = {
      ...selected,
      position: {
        x: { ...selected.position.x, value: convertedPosition.x },
        y: { ...selected.position.y, value: convertedPosition.y },
      },
    };

    setHeatSources(
      replaceItemAtIndex(
        heatSources,
        selectedHeatSourceIndex,
        updatedHeatSource
      )
    );
  };

  const isExtrusionProject = project.type === 'EXTRUSION';

  return (
    <div
      className={`control-wrapper-container ${
        isExtrusionProject ? 'extrusion' : ''
      }`}
    >
      <div />
      <div className="control-wrapper">
        {isExtrusionProject && (
          <div className="control-group">
            <div>
              {project.extrusion.id !== 'new-extrusion' && (
                <label className="extrusion-id">
                  {t('Extrusion')}: <span>{project.extrusion.id}</span>
                </label>
              )}
            </div>
            <div>
              <Button type="secondary" onClick={() => setShowModal(!showModal)}>
                {t('Select Extrusion')}
              </Button>
              <ExtrusionSelector
                showModal={showModal}
                setShowModal={setShowModal}
              />
            </div>
          </div>
        )}
        <div className="control-group">
          <div>
            <label>{t('Heat Source')}</label>
          </div>
          <div>
            <Tooltip title={t('Add')}>
              <Button onClick={addHeatSource}>
                <PlusOutlined />
              </Button>
            </Tooltip>
            <HeatSourceControls
              disabled={selectedHeatSourceIndex === null}
              centreOnBaseplate={centreOnBaseplate}
              rotate={rotate}
              copy={copy}
              remove={remove}
            />
          </div>
        </div>
        <div className="control-group">
          <div>
            <label>{t('Zoom')}</label>
          </div>
          <div>
            <InputNumber
              min={1}
              max={10}
              step={0.5}
              value={view.sourceLayout.scaleFactor}
              onChange={(scaleFactor) => {
                setView({
                  ...view,
                  sourceLayout: {
                    ...view.sourceLayout,
                    scaleFactor,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="control-group">
          <div>
            <label>{t('Grid Size')}</label>
          </div>
          <InputNumber
            min={2}
            max={100}
            value={view.sourceLayout.gridChunkFactor}
            onChange={(gridChunkFactor) => {
              setView({
                ...view,
                sourceLayout: {
                  ...view.sourceLayout,
                  gridChunkFactor,
                },
              });
            }}
            disabled={!view.sourceLayout.snapToGrid}
          />
        </div>
        <div className="control-group">
          <div>
            <label>{t('Snap to Grid')}</label>
          </div>
          <div>
            <Switch
              checked={view.sourceLayout.snapToGrid}
              onChange={() => {
                setView({
                  ...view,
                  sourceLayout: {
                    ...view.sourceLayout,
                    snapToGrid: !view.sourceLayout.snapToGrid,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
      <div />
    </div>
  );
};

export default Controls;
