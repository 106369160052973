import { atom, selector, selectorFamily } from 'recoil';
import API from '@aws-amplify/api';
import { listProjects, getProject } from '../graphql/queries';
import sortBy from 'lodash/sortBy';
import { getUnixTime, parseISO } from 'date-fns';
import { initialViewState } from './view';
import {
  dimension,
  temperature,
  flowRate,
  velocity,
  sizeValue,
  altitude,
  pressure,
} from './units';

const defaultFan = {
  id: 'new-fan',
  label: '',
  acdc: '',
  size: 0,
  fanCurve: JSON.stringify(''),
  region: [],
};

const airFlow = {
  type: 'NATURALCONVECTION',
  orientation: 'HORIZONTAL',
  forcedConvectionType: 'FLOWRATE',
  velocity,
  flowRate,
  fan: defaultFan,
  setup: 'PUSH',
};

const boundaryConditions = {
  ambientTemperature: temperature,
  altitude: altitude,
  includeRadiation: false,
  maxTemperatureRise: temperature,
  maxPressureDrop: pressure,
  maxSize: {
    width: dimension,
    length: dimension,
    height: dimension,
  },
};

export const heatSource = {
  label: '',
  power: {
    type: 'POWER',
    value: 10,
    units: 'W',
  },
  position: {
    x: dimension,
    y: dimension,
  },
  size: {
    ...sizeValue,
    width: { ...dimension, value: 100 },
    height: { ...dimension, value: 100 },
  },
  rjc: {
    type: 'RESISTANCE',
    value: 0,
    units: 'C/W',
  },
  interface: 'NONE',
  material: '',
};

export const pipe = {
  x: dimension,
  y: dimension,
};

// heat solutions models

export const extrusion = {
  id: 'new-extrusion',
  partId: '0',
  surfaceFinish: {
    id: 'new-surface-finish',
    name: '',
    emissivity: 0,
  },
  airFlow,
  region: [],
};

export const mbf = {
  id: 'new-mbf',
  baseMaterial: '',
  embeddedHeatPipes: [],
  finOption: 'SUGGESTED',
  fin: {
    type: '',
    height: dimension,
    region: [],
  },
  airFlow,
};

export const aquasurf = {
  id: 'new-aquasurf',
  baseMaterial: '',
  tubeMaterial: 'CU',
  fluid: 'WATER',
  percentageWater: 50,
  inletTemperature: { ...temperature, value: 30 },
  headerSize: 0.75,
  tubeOuterDiameter: 0.375,
  pipeConfiguration: 'SERPENTINE',
  embeddedPosition: 'SOURCE_FLUSH',
  numberPasses: 0,
  pipes: [],
  flowRate: { ...flowRate, value: 1, units: 'gpm' },
};

// project model

export const initialProjectState = {
  id: 'new-project',
  name: '',
  description: '',
  notes: '',
  star: false,
  type: 'MBF',
  units: 'METRIC',
  baseSize: sizeValue,
  boundaryConditions,
  heatSources: [],
  userId: '',
  auth: {
    id: '',
    username: '',
  },
  extrusion: null,
  mbf: null,
  aquasurf: null,
  aquamax: null,
  status: 'INCOMPLETE',
  simulationStatus: {
    message: '',
    progress: 0,
    timeStamps: [],
  },
  solution: null,
  viewState: JSON.stringify({ ...initialViewState, id: 'design' }),
};

// Recoil transaction observer uses persistence_UNSTABLE
export const projectState = atom({
  key: 'projectState',
  default: initialProjectState,
  persistence_UNSTABLE: {
    type: 'projectState',
  },
});

const sortByUpdatedAt = (items) =>
  sortBy(items, (item) => -getUnixTime(parseISO(item.updatedAt)));

//authMode: 'AMAZON_COGNITO_USER_POOLS',
export const listProjectsQuery = selector({
  key: 'listProjects',
  get: async () => {
    const response = await API.graphql({ query: listProjects });
    if (response.error) {
      throw response.error;
    }
    return sortByUpdatedAt(response.data.listProjects.items);
  },
});

export const getProjectQuery = selectorFamily({
  key: 'getProject',
  get: (id) => async () => {
    if (id === 'new-project') {
      return new Promise((resolve) => resolve(initialProjectState));
    }
    //authMode: 'AMAZON_COGNITO_USER_POOLS',
    const response = await API.graphql({
      query: getProject,
      variables: {
        id,
      },
    });
    if (response.error) {
      throw response.error;
    }
    return response.data.getProject;
  },
});
