import { convertToMillimeters } from './unit-conversion';

const tubeSpace = (tubeMaterial) => {
  switch (tubeMaterial) {
    case 'CU':
      return {
        '0.25': {
          SERPENTINE: 12.7,
          PARALLEL: 9.5,
        },
        '0.375': {
          SERPENTINE: 18.0,
          PARALLEL: 12.7,
        },
        '0.5': {
          SERPENTINE: 25.4,
          PARALLEL: 15.9,
        },
        '0.625': {
          SERPENTINE: 31.8,
          PARALLEL: 19.1,
        },
      };
    case 'SS':
      return {
        '0.25': {
          SERPENTINE: 19.1,
          PARALLEL: 9.5,
        },
        '0.375': {
          SERPENTINE: 25.4,
          PARALLEL: 12.7,
        },
        '0.5': {
          SERPENTINE: 31.8,
          PARALLEL: 15.9,
        },
        '0.625': {
          SERPENTINE: 38.1,
          PARALLEL: 19.1,
        },
      };
    default:
      return 20.0;
  }
};

const hasOverlap = (r1, r2, min) => Math.abs(r1 - r2) < min;

export const hasValidPipes = ({ aquasurf }) => {
  const { tubeMaterial, tubeOuterDiameter, pipeConfiguration } = aquasurf;

  // minimum tube space (CL - CL) depends on tube an configuration (value in mm)
  const minimumCenterLineSpace =
    tubeSpace(tubeMaterial)[tubeOuterDiameter.toString()][pipeConfiguration];

  // order pipes by x position to report correctly
  const pipes = aquasurf.pipes
    .map((pipe) => pipe)
    .sort((a, b) => convertToMillimeters(a.x) - convertToMillimeters(b.x));

  // Validate that we have heat sources
  if (pipes.length === 0) {
    return 'Must have at least one pipe';
  }

  let invalidPipes = [];

  // Validate position for each pipe
  /* not required as this is enforced when setting the tube size

  const baseWidth = convertToMillimeters(baseSize.width);

  const tubeValue = {
    type: 'DIMENSION',
    value: tubeOuterDiameter / 2,
    units: 'in',
  };

  pipes.forEach(({ x }, index) => {
    const xPos = convertToMillimeters(x);
    const offset = convertValueUnitsTo({ value: tubeValue, to: x.units });
    // positions use tube center line
    if (xPos < offset || xPos + offset > baseWidth) {
      invalidPipes.push(index + 1);
    }
  });

  if (invalidPipes.length > 0) {
    return `The following pipes have invalid position: ${invalidPipes.join(
      ', '
    )}`;
  }

  */

  // Validate no pipes closer than minimum center line
  for (let i = 0; i < pipes.length; i++) {
    for (let j = 0; j < pipes.length; j++) {
      if (
        i !== j &&
        hasOverlap(
          convertToMillimeters(pipes[i].x),
          convertToMillimeters(pipes[j].x),
          minimumCenterLineSpace
        )
      ) {
        invalidPipes.push(j + 1);
      }
    }
  }

  // list each unique invalid pipe
  return invalidPipes.length > 0
    ? `The following pipes have invalid spacing (minimum space = ${minimumCenterLineSpace}mm):  ${[
        ...new Set(invalidPipes.sort()),
      ].join(', ')}`
    : null;
};
