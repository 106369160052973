import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import { CloudinaryContext } from 'cloudinary-react';
import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage'
import aws_exports from './aws-exports';
import { App } from './components/App';
import { AutoSave } from './components/AutoSave';
import './utils/i18n';
import './css/index.less';

Amplify.configure(aws_exports);
Storage.configure({ level: 'private' });

ReactDOM.render(
  <CloudinaryContext cloudName="rtools">
    <RecoilRoot>
      <App />
      <AutoSave />
    </RecoilRoot>
  </CloudinaryContext>,
  document.getElementById('root')
);
