import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Steps } from 'antd';
import { viewState } from '../../state/view';
import { Hub } from '@aws-amplify/core';
import { stepErrorState } from '../../state/app';
const { Step } = Steps;

export const Bar = ({ workflow }) => {
  const [view, setView] = useRecoilState(viewState);
  const [stepErrors, setStepErrors] = useState({});
  const setStepError = useSetRecoilState(stepErrorState);

  const setSelectedStep = (progress) =>
    setView({
      ...view,
      progress,
      selectedStep: workflow[progress].step,
    });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Hub.listen('ui', (capsule) => {
      const { event, data } = capsule.payload; // message not used
      switch (event) {
        case 'validation':
          setStepErrors((prevState) => {
            const { step, id, error } = data;
            if (step === '' || step === 'solution') return [];
            const stepErrors = { ...prevState };
            const errorIds = stepErrors[step] || [];
            if (error) {
              stepErrors[step] = [...new Set([...errorIds, id])];
            } else if (errorIds.length) {
              const index = errorIds.indexOf(id);
              if (index > -1) {
                errorIds.splice(index, 1);
                errorIds.length === 0
                  ? delete stepErrors[step]
                  : (stepErrors[step] = errorIds);
              }
            }
            return stepErrors;
          });
          break;

        default:
        // nothing
      }
    });
    // cleanup
    return () => Hub.remove('ui');
  }, []);

  // check if any steps has error - to disable simulation button
  useEffect(() => {
    setStepError(
      [
        ...new Set(workflow.map(({ step }) => !!stepErrors[step]?.length)),
      ].includes(true)
    );
  },[workflow, stepErrors]);

  return (
    <Steps
      type="navigation"
      size="small"
      className="step-items"
      current={view.progress}
      onChange={setSelectedStep}
    >
      {workflow.map(({ step, title }) =>
        stepErrors[step]?.length ? (
          <Step key={step} title={title} status="error" />
        ) : (
          <Step key={step} title={title} />
        )
      )}
    </Steps>
  );
};
