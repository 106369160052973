import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Measurement } from '../Measurement';
import { envelopes } from '../../state/units';
import { projectState } from '../../state/project';
import { Row, Col } from 'antd';

/*
MBF
- Baseplate Width: Set boundary conditions + base size
- Baseplate Length: Set boundary conditions + base size
- Baseplate Thickness: Set base size
- Maximum Height: Set boundary conditions
*/

export const MBFVolume = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { boundaryConditions, baseSize } = project;
  const envelope = envelopes[project.type.toLowerCase()];
  const { maxSize } = envelope.boundaryConditions;

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Baseplate Width (X)')}
                value={baseSize.width}
                minimum={maxSize.width.min}
                maximum={maxSize.width.max}
                onChange={(width) => {
                  setProject({
                    ...project,
                    boundaryConditions: {
                      ...boundaryConditions,
                      maxSize: {
                        ...boundaryConditions.maxSize,
                        width,
                      },
                    },
                    baseSize: {
                      ...baseSize,
                      width,
                    },
                  });
                }}
              />
            </div>
          </Row>
        </Col>
        <Col span={12}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Maximum Height')}
                value={boundaryConditions.maxSize.height}
                minimum={maxSize.height.min}
                maximum={maxSize.height.max}
                onChange={(height) =>
                  setProject({
                    ...project,
                    boundaryConditions: {
                      ...boundaryConditions,
                      maxSize: {
                        ...boundaryConditions.maxSize,
                        height,
                      },
                    },
                  })
                }
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Baseplate Length (Y)')}
                value={baseSize.length}
                minimum={maxSize.length.min}
                maximum={maxSize.length.max}
                onChange={(length) =>
                  setProject({
                    ...project,
                    boundaryConditions: {
                      ...boundaryConditions,
                      maxSize: {
                        ...boundaryConditions.maxSize,
                        length,
                      },
                    },
                    baseSize: {
                      ...baseSize,
                      length,
                    },
                  })
                }
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Row justify="center" align="middle">
            <div className="measurement-input-width">
              <Measurement.Dimension
                step="boundaryConditions"
                label={t('Baseplate Thickness')}
                value={baseSize.height}
                minimum={maxSize.thickness.min}
                maximum={maxSize.thickness.max}
                onChange={(height) =>
                  setProject({
                    ...project,
                    baseSize: {
                      ...baseSize,
                      height,
                    },
                  })
                }
              />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
