import { convertToMillimeters } from './unit-conversion';

const getCoordinates = ({ position, size }) => {
  const x = convertToMillimeters(position.x);
  const y = convertToMillimeters(position.y);
  return {
    x0: x,
    y0: y,
    x1: x + convertToMillimeters(size.width),
    y1: y + convertToMillimeters(size.height),
  };
};

const hasOverlap = (r1, r2) =>
  !(r1.x0 >= r2.x1 || r2.x0 >= r1.x1 || r1.y0 >= r2.y1 || r2.y0 >= r1.y1);

export const hasValidHeatSources = ({ baseSize, heatSources }) => {
  // Validate that we have heat sources
  if (heatSources.length === 0) {
    return 'Must have at least one Heat Source';
  }

  const baseWidth = convertToMillimeters(baseSize.width);
  const baseLength = convertToMillimeters(baseSize.length);

  let invalidHeatSources = [];

  // Validate power output for each heat source
  heatSources.forEach(({ label, power }) => {
    if (power.value <= 0) {
      invalidHeatSources.push(label);
    }
  });

  if (invalidHeatSources.length > 0) {
    return `The following Heat Sources are invalid - Bad Power Output: ${invalidHeatSources.join(
      ', '
    )}`;
  }

  // Validate position for each heat source
  heatSources.forEach(({ label, position, size }) => {
    const xPos = convertToMillimeters(position.x);
    const yPos = convertToMillimeters(position.y);
    const width = convertToMillimeters(size.width);
    const height = convertToMillimeters(size.height);

    if (
      xPos < 0 ||
      yPos < 0 ||
      yPos + height > baseLength ||
      xPos + width > baseWidth
    ) {
      invalidHeatSources.push(label);
    }
  });

  if (invalidHeatSources.length > 0) {
    return `The following Heat Sources are invalid - Bad Position or Size: ${invalidHeatSources.join(
      ', '
    )}`;
  }

  // Validate no overlapping heat sources
  for (let i = 0; i < heatSources.length; i++) {
    for (let j = 0; j < heatSources.length; j++) {
      if (
        i !== j &&
        hasOverlap(
          getCoordinates(heatSources[i]),
          getCoordinates(heatSources[j])
        )
      ) {
        invalidHeatSources.push(heatSources[j].label);
      }
    }
  }

  // list each unique overlapping heat source
  return invalidHeatSources.length > 0
    ? `The following Heat Sources are invalid - Position Overlap: ${[
        ...new Set(invalidHeatSources.sort()),
      ].join(', ')}`
    : null;
};
