import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const languageCodes = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'ja', label: 'Japanese' },
  { value: 'hi', label: 'Hindi' },
];

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translation: {
        English: 'English',
        Email: 'Email',
        Password: 'Password',
        Language: 'Language',
        Save: 'Save',
        Settings: 'Settings',
        Country: 'Country',
        Projects: 'Projects',
        Project: 'Project',
        Baseplate: 'Baseplate',
        Details: 'Details',
        Created: 'Created',
        Updated: 'Updated',
        Status: 'Status',
        Actions: 'Actions',
        'New Project': 'New Project',
        'view-id-title-start': 'Start',
        'view-id-title-project': 'Projects',
        'Reset-Password': 'Reset Password option is available when you Sign In',
        'Sign In': 'Sign In',
        'Sign Out': 'Sign Out',
        'Create Project': 'Create Project',
        'Thermal modeling of air cooled and liquid cooled heat sinks.':
          'Thermal modeling of air cooled and liquid cooled heat sinks.',
        'No Projects': 'No Projects',
        Name: 'Name',
        Type: 'Type',
        Description: 'Description',
        Units: 'Units',
        General: 'General',
        Security: 'Security',
        Profile: 'Profile',
        Company: 'Company',
        City: 'City',
        State: 'State',
        Address: 'Address',
        PENDING: 'Saving...',
        SAVED: 'Saved',
        SAVING: 'Saving...',
        ERROR: 'Error',
        MBF: 'MBF',
        EXTRUSION: 'Extrusion',
        AQUASURF: 'Aquasurf',
        INCOMPLETE: 'Incomplete',
        SOLUTION: 'Solution',
        SOLVING: 'Solving',
        QUEUED: 'Queued',
        FAILED: 'Failed',
        FORCEDCONVECTION: 'Forced',
        NATURALCONVECTION: 'Natural',
        FAN: 'Fan',
        FLOWRATE: 'Flow Rate',
        VELOCITY: 'Velocity',
        PUSH: 'Push',
        PULL: 'Pull',
        PUSHPULL: 'Push Pull',
        IMPINGE: 'Impinge',
        HORIZONTAL: 'Horizontal',
        VERTICAL: 'Vertical',
        WATER: 'Tap Water',
        DIWATER: 'Deionized Water',
        EGLYCOL: 'Ethylene Glycol',
        PGLYCOL: 'Propylene Glycol',
        SERPENTINE: 'Serpentine',
        PARALLEL: 'Parallel',
        CU: 'Copper',
        AL: 'Aluminum',
        SS: 'Stainless Steel',
        CENTERED: 'Centered',
        SOURCE_FLUSH: 'Source Side',
        OPP_FLUSH: 'Opposite Side',
        ESTIMATOR_MESSAGE_CODE_1:
          '<p>Solution meets the maximum temperature requirement and the pressure drop requirement.</p>',
        ESTIMATOR_MESSAGE_CODE_2:
          '<p>Solution meets all project requirements.</p><p>As the results show the maximum temperature with a margin greater than 5C there is room for optimization.</p><p>Possible ways to optimize design is to do one or a combination of the following:</p><ul><li>Decrease heatsink length to decrease overall weight and cost</li><li>Decrease fin height to decrease overall weight and cost</li><li>Increase fin gap (select larger fin pitch) to decrease overall weight and cost</li></ul>',
        ESTIMATOR_MESSAGE_CODE_3A:
          '<p>The solution meets the thermal requirement and does not meet pressure drop requirement.</p><p>Possible ways to decrease pressure drop is to do one or all of the following:</p><ul><li>Decrease flow rate to drop air speed between the fins</li><li>Increase fin gap (select larger fin pitch) to slow the air speed between the fins</li><li>Decrease baseplate length to decrease length of the cooling channel</li></ul>',
        ESTIMATOR_MESSAGE_CODE_3B:
          '<p>The solution meets the thermal requirement and does not meet pressure drop requirement.</p><p>Possible ways to decrease pressure drop is to do one or a combination of the following:</p><ul><li>Decrease flow rate to drop air speed between the fins</li><li>Increase fin gap (select larger fin pitch) to slow the air speed between the fins</li><li>Increase fin height to slow air speed between the fins</li><li>Decrease baseplate length to decrease length of the cooling channel</li><li>Decrease baseplate length</li><li>Increase baseplate width  to increase the numbers of fins and decrease the fluid between the fins</li></ul>',
        ESTIMATOR_MESSAGE_CODE_3C:
          '<p>The solution meets the thermal requirement and does not meet pressure drop requirement.</p><p>Possible ways to decrease pressure drop is to do one or a combination of the following:</p><ul><li>Decrease flow rate to drop air speed between the fins</li><li>Decrease baseplate length to decrease length of the cooling channel</li><li>Decrease baseplate length</li><li>Increase baseplate width to increase the numbers of fins and decrease the fluid between the fins</li></ul>',
        ESTIMATOR_MESSAGE_CODE_4A:
          '<p>Solution does not meet max temperature requirement and does meet the pressure drop requirement.</p><p>Possible ways to improve the maximum temperature is to do one or a combination of the following:</p><ul><li>Decrease fin gap (select smaller fin pitch) to increase surface area</li><li>Increase flow rate to increase heat transfer</li><li>Increase baseplate length to allow more speading around each heat source</li><li>Increase baseplate width to allow more fins to increase surface area</li></ul>',
        ESTIMATOR_MESSAGE_CODE_4B:
          '<p>Solution does not meet max temperature requirement and does meet the pressure drop requirement.</p><p>Possible ways to improve the maximum temperature is to do one or a combination of the following:</p><ul><li>Decrease fin gap (select smaller fin pitch) to have more surface area</li><li>Increase fin height to have more surface area</li><li>Increase flow rate to have a higher air speed between the fins</li><li>Increase baseplate length to spread heat around more each heat source</li><li>Increase baseplate width to increase surface area</li></ul>',
        ESTIMATOR_MESSAGE_CODE_4C:
          '<p>Solution does not meet max temperature requirement and does meet the pressure drop requirement.</p><p>Possible ways to improve the maximum temperature is to do one or a combination of the following:</p><ul><li>Increase flow rate to have a higher air speed between the fins</li><li>Increase baseplate length to spread heat around more each heat source</li><li>Increase baseplate width to increase surface area</li></ul>',
        ESTIMATOR_MESSAGE_CODE_5A:
          '<p>Solution does not meet max temperature requirement.</p><p>Possible ways to improve the thermal performance is to do one or a combination of the following:</p><ul><li>Increase fin pitch (gap between the fins) to allow more airflow through the heatsink</li><li>Increase baseplate length  to spread heat around each heat source</li><li>Increase baseplate width to increase the number of fins</li><li>Forced convection</li></ul>',
        ESTIMATOR_MESSAGE_CODE_5B:
          '<p>Solution does not meet max temperature requirement.</p><p>Possible ways to improve the thermal performance is to do one or a combination of the following:</p><ul><li>Increase fin pitch (gap between the fins) to allow more airflow through the heatsink</li><li>Increase fin height for more surface area</li><li>Increase baseplate length  to spread heat around each heat source</li><li>Increase baseplate width to increase the number of fins</li><li>Forced convection</li></ul>',
        ESTIMATOR_MESSAGE_CODE_5C:
          '<p>Solution does not meet max temperature requirement.</p><p>Possible ways to improve the thermal performance is to do one or a combination of the following:</p><ul><li>Increase baseplate length  to spread heat around each heat source</li><li>Increase baseplate width to increase the number of fins</li><li>Forced convection</li></ul>',
        ESTIMATOR_MESSAGE_CODE_6A:
          '<p>Solution does not meet max temperature requirement and pressure drop requirement.</p><p>Possible ways to improve the thermal performance while meeting the pressure requirement is to do one or a combination of the following:</p><ul><li>Increase baseplate width to have more fins and surface area</li><li>Contact engineer for more technology options</li></ul><p>If it is possible to increase the allowable pressure drop</p><ul><li>Decrease the fin spacing (small gap between fins), to allow for more surface area</li></ul>',
        ESTIMATOR_MESSAGE_CODE_6B:
          '<p>Solution does not meet max temperature requirement and pressure drop requirement.</p><p>Possible ways to improve the thermal performance while meeting the pressure requirement is to do one or a combination of the following:</p><ul><li>Increase fin height to have more surface area</li><li>Increase baseplate width to have more fins and surface area</li><li>Contact engineer for more technology options</li></ul><p>If it is possible to increase the allowable pressure drop</p><ul><li>Decrease the fin spacing (small gap between fins), to allow for more surface area</li></ul>',
        ESTIMATOR_MESSAGE_CODE_6C:
          '<p>Solution does not meet max temperature requirement and pressure drop requirement.</p><p>Possible ways to improve the thermal performance while meeting the pressure requirement is to do one or a combination of the following:</p><ul><li>Increase baseplate width to have more fins and surface area</li><li>Contact engineer for more technology options</li></ul><p>If it is possible to increase the allowable pressure drop</p><ul><li>Decrease the fin spacing (small gap between fins), to allow for more surface area</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_1:
          '<p>Solution meets all required Max Temperature, Max Pressure Drop, and Tube OD project requirements</p>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_2:
          '<p>Solution meets all project requirements.</p><p>As you’re below the maximum temperature by >5C there is room for optimization.</p><p>Possible ways to optimize design is to do one or all of the following:</p><ul><li>Decrease flow rate</li><li>Increase tube size</li><li>Decrease number of tube paths</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_3A:
          '<p>Solution does not meet pressure drop requirement.</p><p>Possible ways to decrease pressure drop is to do one or all of the following:</p><ul><li>Decrease flow rate</li><li>Decrease number of tube paths</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_3B:
          '<p>Solution does not meet pressure drop requirement.</p><p>Possible ways to decrease pressure drop is to do one or all of the following:</p><ul><li>Decrease flow rate</li><li>Increase tube size</li><li>Decrease number of tube paths</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_3C:
          '<p>Solution does not meet pressure drop requirement.</p><p>Possible ways to decrease pressure drop is to do one or all of the following:</p><ul><li>Decrease flow rate</li><li>Decrease number of tube paths</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_4A:
          '<p>Solution does not meet max temperature requirement.</p><p>Possible ways to decrease max temperature is to do one or all of the following:</p><ul><li>Increase flow rate</li><li>Decrease tube size</li><li>Increase number of tube paths</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_4B:
          '<p>Solution does not meet max temperature requirement.</p><p>Possible ways to decrease max temperature is to do one or all of the following:</p><ul><li>Increase flow rate</li><li>Decrease tube size</li><li>Increase number of tube paths</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_4C:
          '<p>Solution does not meet max temperature requirement.</p><p>Possible ways to decrease max temperature is to do one or all of the following:</p><ul><li>Increase flow rate</li><li>Decrease tube size</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_5A:
          '<p>Solution does not meet max temperature requirement and pressure drop requirement.</p><p>Possible ways to decrease max temperature and pressure drop is to do one or all of the following:</p><ul><li>Increase baseplate thickness to accommodate larger tubes</li><li>Increase number of tube paths</li><li>Increase flow rate</li><li>Contact engineer for more technology options</li></ul>',
        ESTIMATOR_MESSAGE_CODE_AQUASURF_6A:
          '<p>Solution showing temperatures near coolant boiling point.</p><p>Reduce temperature below 95C by:</p><ul><li>Reduce heat source power</li><li>Increase flow rate</li><li>Increase number of tube paths</li><li>Contact engineer for more technology options</li></ul>',
      },
    },

    fr: {
      translation: {
        French: 'Français',
        Email: 'Email',
        Password: 'Mot de passe',
        Language: 'Langue',
        Save: 'Sauver',
        Settings: 'Réglages',
        Country: 'Pays',
        Projects: 'Projets',
        Project: 'Projet',
        Baseplate: 'Plaque de base',
        Details: 'Détails',
        Created: 'Établi',
        Updated: 'Actualisé',
        Status: 'Statut',
        Actions: 'Actions',
        'New Project': 'Nouveau projet',
        'view-id-title-start': 'Début',
        'view-id-title-project': 'Projets',
        'Sign In': 'Se connecter',
        'Sign Out': 'Déconnexion',
        'Reset-Password':
          "L'option Réinitialiser le mot de passe est disponible lorsque vous vous connectez",
        'Create Project': 'Créer un projet',
        'Thermal modeling of air cooled and liquid cooled heat sinks.':
          'Modélisation thermique des dissipateurs thermiques refroidis par air et par liquide.',
        'No Projects': 'Aucun projet',
        Name: 'Nom',
        Type: 'Type',
        Description: 'La description',
        Units: 'Unités',
        General: 'Général',
        Security: 'Sécurité',
        Profile: 'Profil',
        Company: 'Compagnie',
        City: 'Ville',
        State: 'Etat',
        Address: 'Adresse',
      },
    },
  },
  lng: 'en',
  //debug: true,
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});
