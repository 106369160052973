import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Steps } from 'antd';
const { Step } = Steps;

export const ProgressSteps = ({ progress }) => {
  const { t } = useTranslation();

  return (
    <Row style={{ marginBottom: '16px' }}>
      <Col span={18} offset={3}>
        <Steps
          current={progress.currentStep}
          status={progress.error ? 'error' : ''}
          size="small"
        >
          <Step title={t('Project Summary')} description={progress.error} />
          <Step title={t('Run Simulation')} />
          <Step title={t('Solution')} />
        </Steps>
      </Col>
    </Row>
  );
};
