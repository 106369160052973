import React from 'react';
import Coldplate from './Coldplate';
import Controls from './Controls';

export const PipeLayout = ({ disabled }) => {
  return (
    <div className="pipe-layout-wrapper">
      <div />
      <div>
        {!disabled && <Controls />}
        <Coldplate disabled={disabled} />
      </div>
      <div />
    </div>
  );
};

export default PipeLayout;
