import { hasValidHeatSources } from './heatsource-validators';
import { hasValidPipes } from './pipe-validators';
import { workflows } from '../views/design/workflows';

export const validators = ({ project, step }) => {
  switch (project.type) {
    case 'EXTRUSION':
      switch (step) {
        case 'sourceLayout':
        case 'solution':
          return hasValidHeatSources(project);
        case 'flowConditions':
        default:
          return;
      }

    case 'MBF':
      switch (step) {
        case 'sourceLayout':
        case 'solution':
          return hasValidHeatSources(project);
        case 'flowConditions':
        case 'finSelection':
        default:
          return;
      }

    case 'AQUASURF':
      switch (step) {
        case 'sourceLayout':
          return hasValidHeatSources(project);
        case 'pipeLayout':
          return hasValidPipes(project);
        case 'solution':
          return hasValidHeatSources(project) ?? hasValidPipes(project);
        default:
          return;
      }

    default:
      return;
  }
};

// validation run by solution step
export const validateEntireProject = (project) => {
  let errorMessage = '';
  // [undefined, undefined, ...] for passed validations = no errors
  workflows[project.type]
    .map(({ step }) => validators({ project, step }))
    .map((error) => (errorMessage = error ?? ''));
  return errorMessage;
};
