/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String!) {
    onCreateUser(owner: $owner) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String!) {
    onUpdateUser(owner: $owner) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String!) {
    onDeleteUser(owner: $owner) {
      id
      email
      name
      company
      phone
      address
      city
      state
      country {
        value
        label
      }
      language {
        value
        label
      }
      roles
      region
      ipregion
      auth {
        id
        username
      }
      settings
      projects {
        items {
          id
          name
          description
          notes
          star
          type
          units
          boundaryConditions {
            ambientTemperature {
              type
              value
              units
            }
            altitude {
              type
              value
              units
            }
            includeRadiation
            maxTemperatureRise {
              type
              value
              units
            }
            maxPressureDrop {
              type
              value
              units
            }
            maxSize {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
          }
          baseSize {
            width {
              type
              value
              units
            }
            length {
              type
              value
              units
            }
            height {
              type
              value
              units
            }
          }
          heatSources {
            label
            power {
              type
              value
              units
            }
            position {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            size {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            rjc {
              type
              value
              units
            }
            interface
            material
          }
          userId
          extrusion {
            id
            partId
            surfaceFinish {
              id
              name
              emissivity
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
            region
          }
          mbf {
            id
            baseMaterial
            embeddedHeatPipes {
              id
              description
            }
            finOption
            fin {
              type
              height {
                type
                value
                units
              }
              region
            }
            airFlow {
              type
              orientation
              forcedConvectionType
              velocity {
                type
                value
                units
              }
              flowRate {
                type
                value
                units
              }
              fan {
                id
                label
                acdc
                size
                fanCurve
                region
              }
              setup
              impingePercentage
            }
          }
          aquasurf {
            id
            baseMaterial
            tubeMaterial
            fluid
            percentageWater
            inletTemperature {
              type
              value
              units
            }
            headerSize
            tubeOuterDiameter
            pipeConfiguration
            embeddedPosition
            numberPasses
            pipes {
              x {
                type
                value
                units
              }
              y {
                type
                value
                units
              }
            }
            flowRate {
              type
              value
              units
            }
          }
          aquamax {
            id
            baseMaterial
          }
          status
          simulationStatus {
            progress
            message
            timeStamps
          }
          solution {
            id
            createdAt
            hskWeight {
              type
              value
              units
            }
            hskDimensions {
              width {
                type
                value
                units
              }
              length {
                type
                value
                units
              }
              height {
                type
                value
                units
              }
            }
            maximumTemperature {
              type
              value
              units
            }
            fluidPressureDrop {
              type
              value
              units
            }
            fluidExitTemperature {
              type
              value
              units
            }
            perHeatSource {
              coveragePercentage
              hskAverageT {
                type
                value
                units
              }
              hskMaximumT {
                type
                value
                units
              }
              caseT {
                type
                value
                units
              }
              junctionT {
                type
                value
                units
              }
            }
          }
          viewState
          createdAt
          auth {
            id
            username
          }
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($owner: String!) {
    onCreateProject(owner: $owner) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($owner: String!) {
    onUpdateProject(owner: $owner) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($owner: String!) {
    onDeleteProject(owner: $owner) {
      id
      name
      description
      notes
      star
      type
      units
      boundaryConditions {
        ambientTemperature {
          type
          value
          units
        }
        altitude {
          type
          value
          units
        }
        includeRadiation
        maxTemperatureRise {
          type
          value
          units
        }
        maxPressureDrop {
          type
          value
          units
        }
        maxSize {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
      }
      baseSize {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      heatSources {
        label
        power {
          type
          value
          units
        }
        position {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        size {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        rjc {
          type
          value
          units
        }
        interface
        material
      }
      userId
      extrusion {
        id
        partId
        surfaceFinish {
          id
          name
          emissivity
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
        region
      }
      mbf {
        id
        baseMaterial
        embeddedHeatPipes {
          id
          description
        }
        finOption
        fin {
          type
          height {
            type
            value
            units
          }
          region
        }
        airFlow {
          type
          orientation
          forcedConvectionType
          velocity {
            type
            value
            units
          }
          flowRate {
            type
            value
            units
          }
          fan {
            id
            label
            acdc
            size
            fanCurve
            region
          }
          setup
          impingePercentage
        }
      }
      aquasurf {
        id
        baseMaterial
        tubeMaterial
        fluid
        percentageWater
        inletTemperature {
          type
          value
          units
        }
        headerSize
        tubeOuterDiameter
        pipeConfiguration
        embeddedPosition
        numberPasses
        pipes {
          x {
            type
            value
            units
          }
          y {
            type
            value
            units
          }
        }
        flowRate {
          type
          value
          units
        }
      }
      aquamax {
        id
        baseMaterial
      }
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
      solution {
        id
        createdAt
        hskWeight {
          type
          value
          units
        }
        hskDimensions {
          width {
            type
            value
            units
          }
          length {
            type
            value
            units
          }
          height {
            type
            value
            units
          }
        }
        maximumTemperature {
          type
          value
          units
        }
        fluidPressureDrop {
          type
          value
          units
        }
        fluidExitTemperature {
          type
          value
          units
        }
        perHeatSource {
          coveragePercentage
          hskAverageT {
            type
            value
            units
          }
          hskMaximumT {
            type
            value
            units
          }
          caseT {
            type
            value
            units
          }
          junctionT {
            type
            value
            units
          }
        }
      }
      viewState
      createdAt
      auth {
        id
        username
      }
      updatedAt
      owner
    }
  }
`;
export const onCreateLibraryExtrusion = /* GraphQL */ `
  subscription OnCreateLibraryExtrusion {
    onCreateLibraryExtrusion {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLibraryExtrusion = /* GraphQL */ `
  subscription OnUpdateLibraryExtrusion {
    onUpdateLibraryExtrusion {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLibraryExtrusion = /* GraphQL */ `
  subscription OnDeleteLibraryExtrusion {
    onDeleteLibraryExtrusion {
      id
      partNumber
      dimensions {
        width {
          type
          value
          units
        }
        length {
          type
          value
          units
        }
        height {
          type
          value
          units
        }
      }
      image
      shapeType
      baseWidth
      baseThick
      finThick
      finHeight
      leftOffset
      rightOffset
      leftThick
      rightThick
      numFins
      numFinsLeft
      numFinsRight
      numOuterFins
      numInnerFins
      gap
      outerGap
      innerGap
      areaGain
      region
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLibrarySurfaceFinish = /* GraphQL */ `
  subscription OnCreateLibrarySurfaceFinish {
    onCreateLibrarySurfaceFinish {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLibrarySurfaceFinish = /* GraphQL */ `
  subscription OnUpdateLibrarySurfaceFinish {
    onUpdateLibrarySurfaceFinish {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLibrarySurfaceFinish = /* GraphQL */ `
  subscription OnDeleteLibrarySurfaceFinish {
    onDeleteLibrarySurfaceFinish {
      id
      name
      emissivity
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLibraryFin = /* GraphQL */ `
  subscription OnCreateLibraryFin {
    onCreateLibraryFin {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLibraryFin = /* GraphQL */ `
  subscription OnUpdateLibraryFin {
    onUpdateLibraryFin {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLibraryFin = /* GraphQL */ `
  subscription OnDeleteLibraryFin {
    onDeleteLibraryFin {
      id
      label
      description
      type
      style
      finPitch
      finThickness
      standardHeights
      cost
      costPerHeight
      region
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLibraryFan = /* GraphQL */ `
  subscription OnCreateLibraryFan {
    onCreateLibraryFan {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLibraryFan = /* GraphQL */ `
  subscription OnUpdateLibraryFan {
    onUpdateLibraryFan {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLibraryFan = /* GraphQL */ `
  subscription OnDeleteLibraryFan {
    onDeleteLibraryFan {
      id
      label
      acdc
      size
      fanCurve
      region
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConfiguration = /* GraphQL */ `
  subscription OnCreateConfiguration {
    onCreateConfiguration {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConfiguration = /* GraphQL */ `
  subscription OnUpdateConfiguration {
    onUpdateConfiguration {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConfiguration = /* GraphQL */ `
  subscription OnDeleteConfiguration {
    onDeleteConfiguration {
      id
      type
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTerritory = /* GraphQL */ `
  subscription OnCreateTerritory {
    onCreateTerritory {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTerritory = /* GraphQL */ `
  subscription OnUpdateTerritory {
    onUpdateTerritory {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTerritory = /* GraphQL */ `
  subscription OnDeleteTerritory {
    onDeleteTerritory {
      id
      name
      countries
      states
      zipcodes
      agents
      createdAt
      updatedAt
    }
  }
`;
