import React from 'react';
import { Row, Col } from 'antd';
import ReactPlayer from 'react-player';
import { Image } from 'cloudinary-react';
import { cloudinaryImages } from '../../utils/config';
import './start.less';

const url = `${cloudinaryImages}/c_scale,h_180,w_280/v1/images/`;
const logoUrl = `${cloudinaryImages}/w_420/v1/images/`;

const backgroundImages = [
  'application-rail.png',
  'application-aquasurf.png',
  'application-motors.png',
  'application-thermal-diagram.png',
  'application-solar-wind.png',
];

export const Start = () => {
  return (
    <div className="main">
      <div className="content-wrapper">
        <div />
        <div className="start-view content">
          <Row>
            <Col span={24}>
              <div className="banner">
                {backgroundImages.map((image) => (
                  <div
                    key={image}
                    className="banner-image"
                    style={{ backgroundImage: `url(${url}${image})` }}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div className="text-block">
                <p>
                  R-TOOLS MAXX is a no-charge, on-line simulation software that
                  allows users to create and model their custom cooling
                  solutions. We align Mersen's capabilities with your project
                  requirements to provide a custom solution tailored to your
                  application.
                </p>
                <p>
                  R-TOOLS MAXX allows the flexibility of engineering teams to
                  quickly generate models, simulate results, and impose
                  iterative changes to obtain an optimized design. R-TOOLS MAXX
                  can greatly reduce the design time for your custom solution
                  with its iterative approach, but also with the built in
                  alignment with Mersen's manufacturing capabilities meaning
                  less time on the front, and back end, of your project.
                </p>
              </div>
            </Col>
            <Col span={12} offset={1}>
              <div className="logo">
                <Image
                  publicId={`${logoUrl}r-tools-maxx-aqua-logo.png`}
                  width="248px"
                />
              </div>
              <div className="promo-text">
                <div>Free</div>
                <div>|</div>
                <div>Easy to Use</div>
                <div>|</div>
                <div>24/7 Online Access</div>
              </div>
              <div className="promo-video">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=--cVv7gmAWg"
                  width="100%"
                />
              </div>
              <div className="instruction-text">
                <p>
                  Click <span>Sign In</span> to create an account.
                </p>
                <p>
                  After you have signed in click <span>New Project</span>.
                </p>
                <p>
                  Click <span>Help</span> for more information.
                </p>
              </div>
            </Col>
            <Col span={5} offset={1}>
              <div className="text-block">
                <p>
                  R-TOOLS MAXX lets users input parameters of their application
                  such as the temperature boundaries, size of the heat source
                  (IGBT, SCR, etc.), layout of the power electronics, and
                  several other design criteria.
                </p>
                <p>
                  The R-TOOLS MAXX simulation engine outputs an air and liquid
                  cooled heat sink design along with 3D visualization tools to
                  provide users an overview of the heat sink thermal behavior
                  and heat spread over the surface of the heat sink. This
                  results in a more accurate technical solution compared to
                  traditional data sheets and graphs.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12} offset={6}></Col>
          </Row>
        </div>
        <div />
      </div>
    </div>
  );
};
