import { atom } from 'recoil';

export const initialViewState = {
  id: 'start',
  progress: 0,
  selectedStep: 'boundaryConditions',
  finSelector: {
    type: 'suggested',
  },
  sourceLayout: {
    scaleFactor: 1, //4
    snapToGrid: false,
    gridChunkFactor: 15,
  },
  pipeLayout: {
    scaleFactor: 1, //4
    snapToGrid: false,
    gridChunkFactor: 15,
  },
};

export const viewState = atom({
  key: 'viewState',
  default: initialViewState,
  persistence_UNSTABLE: {
    type: 'viewState',
  },
});

