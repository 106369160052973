import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Hub } from '@aws-amplify/core';
import { useTranslation } from 'react-i18next';
import { Button, Space, Modal } from 'antd';
import { viewState } from '../../state/view';
import { isSignedIn, userState } from '../../state/user';
import { initialProjectState, projectState } from '../../state/project';
import { ProjectEdit } from './ProjectEdit';
import { ProjectImport } from './ProjectImport';
import { hasPermission } from '../../utils/permissions';
import './project-bar.less';

export const ProjectBar = () => {
  const { t } = useTranslation();
  const isUserSignedIn = useRecoilValue(isSignedIn);
  const { roles } = useRecoilValue(userState);
  const [view, setView] = useRecoilState(viewState);
  const [project, setProject] = useRecoilState(projectState);
  const [currentProject, setCurrentProject] = useState(initialProjectState);
  const [showModal, setShowModal] = useState(false);
  const [showImportForm, setShowImportForm] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState('');

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Hub.listen('autosave', ({ payload }) => {
      setAutoSaveStatus(payload.status);
    });
    return () => Hub.remove('autosave');
  }, []);

  return (
    <div className="project-bar-wrapper">
      {view.id === 'design' && (
        <>
          <div className="title">{project.name}</div>
          <div className="status">{t(autoSaveStatus)}</div>
        </>
      )}
      {view.id !== 'design' && (
        <>
          <div className="title" />
          <div className="status" />
        </>
      )}
      <div className="actions">
        <Space>
          <Button
            type="secondary"
            onClick={() => {
              setProject(initialProjectState);
              setView({ ...view, id: 'projects' });
            }}
            disabled={!isUserSignedIn}
          >
            {t('Projects')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setCurrentProject(initialProjectState);
              setShowImportForm(false);
              setShowModal(true);
            }}
            disabled={!isUserSignedIn}
          >
            {t('New Project')}
          </Button>
          {hasPermission({
            roles,
            action: 'IMPORT_PROJECT',
          }) && (
            <Button
              type="secondary"
              onClick={() => {
                setShowImportForm(true);
                setShowModal(true);
              }}
            >
              {t('Import Project')}
            </Button>
          )}
        </Space>
      </div>
      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={null}
        zIndex={1001}
      >
        {showImportForm ? (
          <ProjectImport
            reset={showModal}
            onSuccess={() => setShowModal(false)}
          />
        ) : (
          <ProjectEdit
            id={currentProject.id}
            reset={showModal}
            onSuccess={() => setShowModal(false)}
          />
        )}
      </Modal>
    </div>
  );
};
