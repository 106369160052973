import React from 'react';
import _isPlainObject from 'lodash/isPlainObject';

const formatValue = (value) =>
  _isPlainObject(value)
    ? `${Math.round(value.value * 100) / 100} ${value.units}`
    : value;

export const LabeledValue = ({ label, value, size = '' }) => (
  <div
    className="labeled-value-wrapper"
    style={{
      gridTemplateColumns: size === '' ? '200px 100px' : `200px ${size}`,
    }}
  >
    <div>{label}</div>
    <div className="value-wrapper">{formatValue(value)}</div>
  </div>
);
