import React from 'react';
import { useRecoilValue } from 'recoil';
import { Spin } from 'antd';
import { viewState } from '../state/view';
import { AppHeader } from './AppHeader';
// view component mapping lookup { start, design }
import views from '../views';

export const App = () => {
  const view = useRecoilValue(viewState);
  // ensure we always have a valid view = 'start'
  const View = views[view.id] || views['start'];
  return (
    <>
      <AppHeader />
      <React.Suspense
        fallback={
          <div id="suspense-spinner">
            <Spin />
          </div>
        }
      >
        <View />
      </React.Suspense>
    </>
  );
};
