import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import API from '@aws-amplify/api';
import Storage from '@aws-amplify/storage';
import { format, parseISO, getUnixTime, compareAsc } from 'date-fns';
import { enUS, fr, ja, de, hi } from 'date-fns/locale';
import sortBy from 'lodash/sortBy';
import {
  Table,
  Space,
  Button,
  Popconfirm,
  message,
  Empty,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import {
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { getProject, getUserProjects } from '../../graphql/queries';
import { createProject, deleteProject } from '../../graphql/mutations';
import {
  onCreateProject,
  onUpdateProject,
  onDeleteProject,
} from '../../graphql/subscriptions';
import { editProjectState } from '../../state/app';
import { initialViewState, viewState } from '../../state/view';
import { initialProjectState, projectState } from '../../state/project';
import { userState, isSignedIn } from '../../state/user';
import { ProjectEdit } from './ProjectEdit';
import { Hub } from '@aws-amplify/core';

// map date-fns locales to i18n locales - maybe rename i18n to match
const locales = { en: enUS, fr, ja, de, hi };

export const ProjectList = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(userState);
  const isUserSignedIn = useRecoilValue(isSignedIn);
  const setView = useSetRecoilState(viewState);
  const setEditProject = useSetRecoilState(editProjectState);
  const [project, setProject] = useRecoilState(projectState);
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(initialProjectState);
  const [showModal, setShowModal] = useState(false);

  // sort desc by returning negative timestamp numbers
  const sortByUpdatedAt = (items) =>
    sortBy(items, (item) => -getUnixTime(parseISO(item.updatedAt)));

  const catchError = (error) => {
    message.error(t('An error occurred'));
    console.error(error);
  };

  const getProjects = async ({ id, nextToken }) => {
    try {
      const response = await API.graphql({
        query: getUserProjects,
        variables: {
          id,
          nextToken,
        },
      });
      return response.data.getUser.projects;
    } catch (error) {
      catchError(error);
    }
  };

  const getAllProjects = async () => {
    const loadingMessage = message.loading('Loading projects', 0);

    let { items, nextToken } = await getProjects({
      id: user.id,
      nextToken: null,
    });

    let projects = [...items];

    while (nextToken !== null) {
      const res = await getProjects({
        id: user.id,
        nextToken,
      });
      projects = [...projects, ...res.items];
      nextToken = res.nextToken;
    }

    setProjects(sortByUpdatedAt(projects));
    loadingMessage();
  };

  // use hub to update project list as copy API not wired into gql subscriptions
  useEffect(() => {
    Hub.listen('copy-project', (capsule) => {
      const { event, data } = capsule.payload; // message not used
      getAllProjects();
    });
    // cleanup
    return () => Hub.remove('copy-project');
  }, []);

  // optionally use listProjectsQuery from recoil project state
  useEffect(() => {
    if (isUserSignedIn) {
      getAllProjects();
    }
  }, [isUserSignedIn]);

  // subscriptions
  useEffect(() => {
    if (user.owner) {
      const onCreateProjectListener = API.graphql({
        query: onCreateProject,
        variables: {
          owner: user.owner,
        },
      }).subscribe({
        next: (result) => {
          const createdProject = result.value.data.onCreateProject;
          setProjects((prevProjects) => [createdProject, ...prevProjects]);
        },
      });

      const onUpdateProjectListener = API.graphql({
        query: onUpdateProject,
        variables: {
          owner: user.owner,
        },
      }).subscribe({
        next: (result) => {
          const updatedProject = result.value.data.onUpdateProject;
          setProjects((prevProjects) =>
            sortByUpdatedAt(
              prevProjects.map((project) => {
                return project.id === updatedProject.id
                  ? updatedProject
                  : project;
              })
            )
          );
        },
      });

      const onDeleteProjectListener = API.graphql({
        query: onDeleteProject,
        variables: {
          owner: user.owner,
        },
      }).subscribe({
        next: (result) => {
          const deletedProject = result.value.data.onDeleteProject;
          setProjects((prevProjects) =>
            prevProjects.filter((project) => project.id !== deletedProject.id)
          );
        },
      });

      return () => {
        onCreateProjectListener.unsubscribe();
        onUpdateProjectListener.unsubscribe();
        onDeleteProjectListener.unsubscribe();
      };
    }
  }, [user.owner]);

  const handleCopyProject = async ({ id }) => {
    const loadingMessage = message.loading('Copying project', 0);
    const projectCopy = await API.graphql({
      query: getProject,
      variables: {
        id,
      },
    })
      .then((result) => {
        const {
          id,
          createdAt,
          updatedAt,
          owner,
          ...rest
        } = result.data.getProject;
        return rest;
      })
      .catch((error) => {
        message.error(t('An error occurred'));
        console.log(error);
      });
    // save copy as new project
    const { name, viewState } = projectCopy;
    const copyName = `${t('Copy of')} ${name}`;
    API.graphql({
      query: createProject,
      variables: {
        input: {
          ...projectCopy,
          name: copyName,
          status: 'INCOMPLETE',
          simulationStatus: {
            message: '',
            progress: 0,
            timeStamps: [],
          },
          solution: null,
          viewState: JSON.stringify({
            ...JSON.parse(viewState),
            progress: 0,
            selectedStep: 'boundaryConditions',
          }),
        },
      },
    })
      .catch(catchError)
      .finally(loadingMessage);
  };

  const handleDeleteProject = async ({ id }) => {
    const deleteMessage = message.loading('Deleting project', 0);
    try {
      const files = await Storage.vault.list(`${id}/`);
      for (const { key } of files) {
        await Storage.vault.remove(key);
      }
      await API.graphql({
        query: deleteProject,
        variables: {
          input: { id },
        },
      });
      deleteMessage();
      if (id === project.id) {
        setProject(initialProjectState);
        // go back to start - this should contain information about starting a new project
        // can only delete from project list view
        //setView(initialViewState);
      }
    } catch (error) {
      deleteMessage();
      catchError(error);
    }
  };

  const dateFormatter = (date) =>
    format(date, 'PP', { locale: locales[user.language.value] });

  const calcTotalPower = (heatSources) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return heatSources.map(({ power }) => power.value).reduce(reducer, 0);
  };

  const FlowConditions = ({ project }) => {
    switch (project.type) {
      case 'EXTRUSION':
      case 'MBF':
        const { airFlow } = project[project.type.toLowerCase()];
        const { setup, type, orientation, forcedConvectionType } = airFlow;
        return type === 'FORCEDCONVECTION' ? (
          <Space direction="horizontal">
            <div>{t(type)}</div>
            <div>{t(forcedConvectionType)}</div>
            <div>{t(setup)}</div>
          </Space>
        ) : (
          <Space direction="horizontal">
            <div>{t(type)}</div>
            <div>{t(orientation)}</div>
          </Space>
        );

      default:
        const { fluid, tubeMaterial } = project[project.type.toLowerCase()];
        return (
          <Space direction="horizontal">
            <div>{t(tubeMaterial)}</div>
            <div>{t(fluid)}</div>
          </Space>
        );
    }
  };

  const Heatsink = ({ project }) => {
    const { type, extrusion, mbf } = project;
    switch (type) {
      case 'EXTRUSION':
        return extrusion.partId;

      case 'MBF':
        return mbf.fin.type;

      default:
        return '-';
    }
  };

  const ActionIcons = ({ project }) => (
    <Space size="middle">
      <Button
        type="link"
        icon={<EditOutlined />}
        onClick={() => {
          setCurrentProject(project);
          setShowModal(true);
        }}
        title={t('Edit')}
      />
      <Popconfirm
        title={t('Are you sure you want to copy this project?')}
        onConfirm={() => handleCopyProject(project)}
        onCancel={() => {}}
        okText="Copy"
        cancelText="Cancel"
        placement="topRight"
      >
        <Button type="link" icon={<CopyOutlined />} title={t('Copy')} />
      </Popconfirm>
      <Popconfirm
        title={t('Are you sure you want to delete this project?')}
        onConfirm={() => handleDeleteProject(project)}
        onCancel={() => {}}
        okText="Delete"
        cancelText="Cancel"
        placement="topRight"
      >
        <Button type="link" icon={<DeleteOutlined />} title={t('Delete')} />
      </Popconfirm>
    </Space>
  );

  const handleSelectProject = ({ id }) => {
    API.graphql({
      query: getProject,
      variables: {
        id,
      },
    })
      .then((result) => {
        const project = result.data.getProject;
        setProject(project);
        setEditProject(project.status !== 'SOLUTION');
        try {
          const viewStateObj = JSON.parse(project.viewState);
          setView({
            ...initialViewState,
            id: 'design',
            progress: 0,
            projectId: project.id,
          });
          // reload view state over initial state to handle view state updates
          if (viewStateObj) {
            setView({ ...initialViewState, ...viewStateObj });
          }
        } catch (error) {
          console.error('viewState JSON', error);
          setView({
            ...initialViewState,
            id: 'design',
            progress: 0,
            projectId: project.id,
          });
        }
      })
      .catch(catchError);
  };

  const tagColor = (status) => {
    switch (status) {
      case 'INCOMPLETE':
        return 'default'; //'#bbbbbb';
      case 'SOLUTION':
        return 'success'; //'#3cb371';
      case 'SOLVING':
        return 'processing';
      case 'FAILED':
        return 'error'; //'#ff4d4f';
      default:
        return 'warning'; //'#fa8c16';
    }
  };

  const tableColumns = [
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Tag color={tagColor(record.status)}>{t(record.status)}</Tag>
      ),
      sorter: (a, b) =>
        a.status === b.status ? 0 : a.status > b.status ? 1 : -1,
    },
    {
      title: t('Project'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => handleSelectProject(record)}
          style={{
            padding: 0,
          }}
        >
          <div
            style={{
              width: 280,
              textAlign: 'left',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {text}
          </div>
        </Button>
      ),
      sorter: (a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1),
    },
    {
      title: '',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => (
        <Tooltip title={record.description}>
          <Button
            type="link"
            icon={<InfoCircleOutlined />}
            title={t('Description')}
            disabled={record.description === ''}
          />
        </Tooltip>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => t(record.type),
      sorter: (a, b) => (a.type === b.type ? 0 : a.type > b.type ? 1 : -1),
    },
    {
      title: t('Sources'),
      dataIndex: 'sources',
      key: 'source',
      render: (text, record) => record.heatSources.length,
      sorter: (a, b) => a.heatSources.length - b.heatSources.length,
    },
    {
      title: t('Load'),
      dataIndex: 'load',
      key: 'load',
      render: (text, record) => `${calcTotalPower(record.heatSources)}W`,
      sorter: (a, b) =>
        calcTotalPower(a.heatSources) - calcTotalPower(b.heatSources),
    },
    {
      title: t('Flow Conditions'),
      dataIndex: 'flowconditions',
      key: 'flowconditions',
      render: (text, record) => <FlowConditions project={record} />,
    },
    {
      title: t('Heatsink'),
      dataIndex: 'heatsink',
      key: 'heatsink',
      render: (text, record) => <Heatsink project={record} />,
    },
    {
      title: t('Created'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => dateFormatter(parseISO(record.createdAt)),
      sorter: (a, b) =>
        compareAsc(parseISO(a.createdAt), parseISO(b.createdAt)),
    },
    {
      title: t('Updated'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => dateFormatter(parseISO(record.updatedAt)),
      sorter: (a, b) =>
        compareAsc(parseISO(a.updatedAt), parseISO(b.updatedAt)),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => <ActionIcons project={record} />,
    },
  ];

  return (
    <>
      <Table
        dataSource={projects}
        rowKey="id"
        columns={tableColumns}
        pagination={false}
        size="small"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('No Projects')}
            />
          ),
        }}
      />
      <Modal
        visible={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={null}
        zIndex={1001}
      >
        <ProjectEdit
          id={currentProject.id}
          reset={showModal}
          onSuccess={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};
