import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import './fin-data-grid.less';

const GridRow = ({
  prefix,
  data,
  buttonData,
  selectData = () => {},
  rowIndex = 0,
  maxT = 0,
  maxP = 0,
  maxHeight = 0,
  baseHeight = 0,
}) =>
  data.map((value, index) => {
    const isValueArray = Array.isArray(value);

    const getCSSClasses = (value) => {
      if (prefix === 'height') {
        const { type, estimate } = value;
        const css = [];

        if (estimate) {
          if (
            buttonData.suggested.type === type &&
            buttonData.suggested.height === estimate.fh
          ) {
            css.push('suggested');
          }
          if (
            buttonData.lowpressure.type === type &&
            buttonData.lowpressure.height === estimate.fh
          ) {
            css.push('lowpressure');
          }
          if (
            buttonData.custom.type === type &&
            buttonData.custom.height === estimate.fh
          ) {
            css.push('custom');
          }
          if (
            (maxT === 0 && maxP === 0
              ? false
              : estimate.T > maxT || estimate.dP > maxP) ||
            maxHeight < estimate.fh + baseHeight
          ) {
            css.push('invalid');
          }
        }
        // convert array to spaced string
        return css.join(' ');
      }
    };

    return (
      <div
        key={`cell-${index}`}
        className={`item-${prefix} row-${rowIndex} column-${index} ${
          isValueArray ? 'estimate' : ''
        }`}
      >
        {isValueArray
          ? value.map((fin, finIndex) => (
              <div
                key={`cell-fin-${finIndex}`}
                className={`item ${getCSSClasses(fin)}`}
                onClick={() => selectData({ column: index, finIndex })}
              >
                {fin.label}
              </div>
            ))
          : value.label}
        {prefix === 'thickness' && index === 1 && (
          <Tooltip title="A thicker fin usually translates into better heat conduction, but increases weight/cost and pressure drop.">
            {' '}
            <QuestionCircleOutlined />
          </Tooltip>
        )}
        {prefix === 'pitch' && index === 1 && (
          <Tooltip title="This is the distance between fin centers. Larger values mean wider gaps between fins and lower pressure drop.">
            {' '}
            <QuestionCircleOutlined />
          </Tooltip>
        )}
      </div>
    );
  });

export const FinDataGrid = ({
  setButtonData,
  buttonData,
  estimates,
  fins,
  type,
  maxT = 0,
  maxP = 0,
  maxHeight = 0,
  baseHeight = 0,
}) => {
  // row labels this could be created dynamically based on height range
  // of available/selected library fins
  const rows = {
    type: [{ label: '' }, { label: 'Fin Type' }],
    thickness: [{ label: '' }, { label: 'Fin Thickness mm' }],
    pitch: [{ label: '' }, { label: 'Fin Pitch mm' }],
    heights: [
      [{ label: 'Fin Height' }, { label: '< 20' }],
      [{ label: '20-30' }],
      [{ label: '30-40' }],
      [{ label: '40-50' }],
      [{ label: '50-60' }],
      [{ label: '60-70' }],
      [{ label: '70-80' }],
      [{ label: '80-90' }],
      [{ label: '90-100' }],
      [{ label: '100-110' }],
      [{ label: '> 110' }],
    ],
  };

  // convert fin definitions to array and generate grid row data
  const arr = Object.keys(fins).map((key) => fins[key]);

  // generate rows
  arr.forEach((fin) => {
    rows.type.push({ label: fin.label });
    rows.thickness.push({ label: fin.fin_thickness });
    rows.pitch.push({ label: fin.fin_pitch });
    // process heights for each fin
    rows.heights.forEach((row, index) => {
      // numeric value of row to set range: 20, 30, 40, ... 120
      const key = index * 10 + 20;
      if (!estimates) {
        row.push({ label: '-' });
      } else {
        let setHeight = false;
        let range = [0, 20];
        switch (key) {
          case 20:
            range = [0, 20];
            break;
          case 120:
            range = [110, 1000]; // 1000 = upper bound is just a large number
            break;
          default:
            range = [key - 10, key];
        }
        // put fin in corrected row based on height
        fin.standard_heights.forEach((h) => {
          if (!setHeight && h >= range[0] && h < range[1]) {
            setHeight = true;
            // check if this is estimated
            if (estimates.fins[fin.label]) {
              const fins = [];
              estimates.fins[fin.label].forEach((efin) => {
                if (efin.fh >= range[0] && efin.fh < range[1]) {
                  fins.push({
                    label: efin.fh,
                    estimate: efin,
                    type: fin.label,
                  });
                }
              });
              row.push(fins);
            }
            // no estimate then just add label
            if (fins.length === 0) {
              row.push({ label: h, type: fin.label });
            }
          }
        });
        // fill row position if no fin value within height range
        if (!setHeight) {
          row.push({ label: '-' });
        }
      }
    });
  });

  return (
    <div className="fin-grid-container">
      <GridRow type={type} prefix="type" data={rows.type} />
      <GridRow type={type} prefix="thickness" data={rows.thickness} />
      <GridRow type={type} prefix="pitch" data={rows.pitch} />
      {rows.heights.map((row, index) => (
        <GridRow
          prefix="height"
          key={index}
          data={row}
          rowIndex={index}
          buttonData={buttonData}
          selectData={({ column, finIndex }) => {
            const fin = row[column][finIndex];
            if (fin.estimate) {
              const custom = {
                ...fin.estimate,
                type: fin.type,
                height: fin.estimate.fh,
              };
              setButtonData({ ...buttonData, custom });
            }
          }}
          maxT={maxT}
          maxP={maxP}
          maxHeight={maxHeight}
          baseHeight={baseHeight}
        />
      ))}
    </div>
  );
};
