import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import _startCase from 'lodash/startCase';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';

const { Text } = Typography;

export const FlowConditions = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);
  const { airFlow } = project[project.type.toLowerCase()];

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Flow Conditions')} />
      <section>
        <Text strong>{t('Air Flow')}</Text>
        <LabeledValue
          label={t('Air Flow Type')}
          value={t(_startCase(airFlow.type))}
        />
      </section>
      {airFlow.type === 'NATURALCONVECTION' && (
        <section>
          <LabeledValue
            label={t('Orientation')}
            value={t(_startCase(airFlow.orientation))}
          />
        </section>
      )}
      {airFlow.type === 'FORCEDCONVECTION' && (
        <section>
          <LabeledValue
            label={t('Forced Flow Type')}
            value={t(_startCase(airFlow.forcedConvectionType))}
          />
          {airFlow.forcedConvectionType === 'FAN' && (
            <>
              <LabeledValue label={t('Fan')} value={airFlow.fan.label} />
              <LabeledValue
                label={t('Placement')}
                value={t(_startCase(airFlow.setup))}
              />
            </>
          )}
          {airFlow.setup === 'IMPINGE' && (
            <LabeledValue
              label={t('Fan Heatsink Coverage')}
              value={`${airFlow.impingePercentage}%`}
            />
          )}
          {airFlow.forcedConvectionType === 'FLOWRATE' && (
            <LabeledValue label={t('Flow Rate')} value={airFlow.flowRate} />
          )}
          {airFlow.forcedConvectionType === 'VELOCITY' && (
            <LabeledValue
              label={t('Flow Velocity')}
              value={airFlow.velocity}
            />
          )}
        </section>
      )}
    </div>
  );
};
