import React from 'react';
import { BoundaryConditions } from '../../components/BoundaryConditions';
import { FlowConditions } from '../../components/FlowConditions';
import { HeatSourceLayout } from '../../components/HeatSourceLayout';
import { PipeLayout } from '../../components/PipeLayout';
import { PipeConfiguration } from '../../components/PipeConfiguration';
import { FinSelector } from '../../components/FinSelector';
import { Solution } from '../../components/Solution';

// define steps for each workflow (Extrusion, MBF, Aquasurf, Aquamax)
export const workflows = {
  EXTRUSION: [
    {
      title: 'Boundary Conditions',
      step: 'boundaryConditions',
      view: <BoundaryConditions />,
    },
    {
      title: 'Source Layout',
      step: 'sourceLayout',
      view: <HeatSourceLayout />,
    },
    {
      title: 'Flow Conditions',
      step: 'flowConditions',
      view: <FlowConditions />,
    },
    {
      title: 'Solution',
      step: 'solution',
      view: <Solution />,
    },
  ],
  MBF: [
    {
      title: 'Boundary Conditions',
      step: 'boundaryConditions',
      view: <BoundaryConditions />,
    },
    {
      title: 'Source Layout',
      step: 'sourceLayout',
      view: <HeatSourceLayout />,
    },
    {
      title: 'Flow Conditions',
      step: 'flowConditions',
      view: <FlowConditions />,
    },
    {
      title: 'Fin Selection',
      step: 'finSelection',
      view: <FinSelector />,
    },
    {
      title: 'Solution',
      step: 'solution',
      view: <Solution />,
    },
  ],
  AQUASURF: [
    {
      title: 'Boundary Conditions',
      step: 'boundaryConditions',
      view: <BoundaryConditions />,
    },
    {
      title: 'Pipe Configuration',
      step: 'pipeConfiguration',
      view: <PipeConfiguration />,
    },
    {
      title: 'Source Layout',
      step: 'sourceLayout',
      view: <HeatSourceLayout />,
    },
    {
      title: 'Pipe Layout',
      step: 'pipeLayout',
      view: <PipeLayout />,
    },
    {
      title: 'Solution',
      step: 'solution',
      view: <Solution />,
    },
  ],
};
