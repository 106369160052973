import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeading } from '../../../SectionHeading';

export const Files = ({ qom, pdf, step }) => {
  const { t } = useTranslation();
  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Result Files')} />
      <section>
        <div className="simulation-result-files">
          <div>R-TOOLS MAXX Report (PDF)</div>
          <div>
            <a href={pdf.url}>{pdf.name}</a>
          </div>
          <div>Qfin Project (QOM)</div>
          <div>
            <a href={qom.url}>{qom.name}</a>
          </div>
          <div>CAD (STEP)</div>
          <div>
            <a href={step.url}>{step.name}</a>
          </div>
        </div>
      </section>
    </div>
  );
};
