const actions = {
  IMPORT_PROJECT: 'IMPORT_PROJECT',
  AQUASURF: 'AQUASURF',
  USER_ADMIN: 'USER_ADMIN',
};

const roles = {
  ADMIN: 'ADMIN',
  SALES: 'SALES',
};

const permissions = new Map();
permissions.set(actions.IMPORT_PROJECT, [roles.ADMIN]);
permissions.set(actions.AQUASURF, [roles.ADMIN]);
permissions.set(actions.USER_ADMIN, [roles.ADMIN]);

export const hasPermission = ({ action = 'none', roles = 'none' }) => {
  // user roles is a comma separated string 'ADMIN,USER' - ensure not spaces and convert to array
  if (!roles) return false;
  const arrRoles = roles.replace(/\s/g, '').split(',');
  if (arrRoles.length === 0 || !permissions.has(action)) return false;
  return arrRoles
    .map((role) => permissions.get(action).includes(role))
    .includes(true);
};
