import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { projectState } from '../../../../state/project';
import { LabeledValue } from '../../LabeledValue';
import { SectionHeading } from '../../../SectionHeading';

const { Text } = Typography;

export const Extrusion = () => {
  const { t } = useTranslation();
  const project = useRecoilValue(projectState);

  return (
    <div className="section-wrapper">
      <SectionHeading label={t('Extrusion')} />
      <section>
        <Text strong>{t('Extrusion')}</Text>
        <LabeledValue
          label={t('Part Number')}
          value={project.extrusion.partId}
        />
      </section>
      <section>
        <Text strong>{t('Dimensions')}</Text>
        <LabeledValue label={t('Width')} value={project.baseSize.width} />
        <LabeledValue label={t('Length')} value={project.baseSize.length} />
        <LabeledValue label={t('Height')} value={project.baseSize.height} />
      </section>
    </div>
  );
};
