import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
//import { Tooltip } from 'antd';
import { Image } from 'cloudinary-react';
//import { QuestionCircleOutlined } from '@ant-design/icons';
import { projectState } from '../../state/project';
import { url } from '../ExtrusionSelector';
import Measurement from '../Measurement';

export const BaseplatePopOver = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const { baseSize, boundaryConditions } = project;
  const setBaseSize = (baseSize) => setProject({ ...project, baseSize });

  return (
    <div className="baseplate-popover">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {project.type === 'EXTRUSION' && project.extrusion.partId !== '0' && (
          <div>
            <label className="title">
              {t('Extrusion')}: {project.extrusion.partId}
            </label>
            <div style={{ textAlign: 'center' }}>
              <Image
                publicId={`${url}${project.extrusion.id}.png`}
                width="180"
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <label className="title">{t('Width')}</label>
        {/* <Tooltip
          placement="topRight"
          title={t(`A maximum width of ${boundaryConditions.maxSize.width.value} ${boundaryConditions.maxSize.width.units} is allowed`)}
        >
          <QuestionCircleOutlined style={{ marginTop: '5px' }} />
        </Tooltip> */}
      </div>
      <Measurement.Dimension
        value={baseSize.width}
        minimum={{type: 'DIMENSION', value: 10, units: 'mm'}}
        maximum={boundaryConditions.maxSize.width}
        onChange={(width) => {
          setBaseSize({ ...baseSize, width });
        }}
        disabled={project.type === 'EXTRUSION' || project.type === 'MBF'}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <label className="title">{t('Length')}</label>
        {/* <Tooltip
          placement="topRight"
          title={t(`A maximum length of ${boundaryConditions.maxSize.length.value} ${boundaryConditions.maxSize.length.units} is allowed`)}
        >
          <QuestionCircleOutlined style={{ marginTop: '5px' }} />
        </Tooltip> */}
      </div>
      <Measurement.Dimension
        value={baseSize.length}
        minimum={{type: 'DIMENSION', value: 10, units: 'mm'}}
        maximum={boundaryConditions.maxSize.length}
        onChange={(length) => {
          setBaseSize({ ...baseSize, length });
        }}
        disabled={project.type === 'MBF'}
      />
      <div>
        <label className="title">{t('Thickness')}</label>
        <Measurement.Dimension
          value={baseSize.height}
          minimum={{type: 'DIMENSION', value: 10, units: 'mm'}}
          maximum={boundaryConditions.maxSize.height}
          onChange={(height) => {
            setBaseSize({ ...baseSize, height });
          }}
          disabled={project.type === 'EXTRUSION'}
        />
      </div>
    </div>
  );
};

