import React, { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import { Hub } from '@aws-amplify/core';
import { Modal, Progress, Carousel, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { getProject } from '../../graphql/queries';
import ReactMarkdown from 'react-markdown';
import { useRecoilValue } from 'recoil';
import { viewState } from '../../state/view';
import rehypeRaw from 'rehype-raw';

const carouselContent = [
  {
    link:
      'https://ep-us.mersen.com/products/family/high-speed-fuses?utm_source=LinkedIn&utm_medium=Social&utm_campaign=MersenMondays',
    image: 'AD-Mersen-Round-Body-Fuses.jpg',
  },
  {
    link:
      'https://ep-us.mersen.com/products/engineering/bus-bar?utm_source=MersenMondays&utm_medium=Social&utm_content=LBB&utm_campaign=MersenMondays',
    image: 'AD-Mersen-Laminated-Bus-Bar.jpg',
  },
  {
    link:
      'https://ep-us.mersen.com/products/engineering/isomaxx-enhanced-cooling?utm_source=LinkedIn&utm_medium=Social&utm_campaign=MersenMondays',
    image: 'AD-Mersen-IsoMAXX.jpg',
  },
  {
    link:
      'https://ep-us.mersen.com/products/engineering/capacitors?utm_source=LinkedIn&utm_medium=Social&utm_campaign=MersenMondays',
    image: 'AD-Mersen-Capacitors.jpg',
  },
];

export const getStatusProgress = `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      status
      simulationStatus {
        progress
        message
        timeStamps
      }
    }
  }
`;

const progressStatus = (status) => {
  switch (status) {
    case 'SOLUTION':
      return 'success';

    case 'COMPLETE':
    case 'QUEUED':
    case 'SOLVING':
      return 'active';

    case 'INCOMPLETE':
    case 'FAILED':
      return 'exception';

    default:
      return 'normal';
  }
};

const projectPollStatus = ({ project }) => {
  const { status, simulationStatus = null } = project;
  const { progress = 0, message = '' } = simulationStatus || {};
  return {
    status,
    progress,
    message,
    poll:
      ['QUEUED', 'SOLVING', 'COMPLETE'].includes(status) &&
      progress !== 100 &&
      progress >= 0,
  };
};

let timer;

export const RunSimulation = ({
  project,
  setProject,
  startSimulation,
  cancelSimulation,
}) => {
  const { t } = useTranslation();
  const view = useRecoilValue(viewState);
  const [status, setStatus] = useState(project.status);
  const [autoSaveStatus, setAutoSaveStatus] = useState('');
  const [simulationProgress, setSimulationProgress] = useState(0);
  const [showModal, setShowModal] = useState(startSimulation);
  const [carouselLink, setCarouselLink] = useState(carouselContent[0].link);

  const getProjectStatus = ({ project }) => {
    API.graphql({
      query: getStatusProgress,
      variables: {
        id: project.id,
      },
    })
      .then((response) => {
        if (response?.data) {
          const projectStatusProgress = response.data.getProject;
          const { status, message, progress, poll } = projectPollStatus({
            project: projectStatusProgress,
          });
          setStatus(status);
          setSimulationProgress(progress);
          if (!poll) {
            clearInterval(timer);
            API.graphql({ query: getProject, variables: { id: project.id } })
              .then((response) => {
                setProject(response.data.getProject);

                const messageCode = ['EXTRUSION', 'MBF'].includes(project.type)
                  ? `ESTIMATOR_MESSAGE_CODE_${message.toUpperCase()}`
                  : `ESTIMATOR_MESSAGE_CODE_AQUASURF_${message.toUpperCase()}`;

                notification.open({
                  message: 'Solution',
                  description: (
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={t(messageCode)}
                    />
                  ),
                  duration: 0,
                });
              })
              .catch((error) => {
                clearInterval(timer);
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        clearInterval(timer);
        console.log(error);
      });
  };

  useEffect(() => {
    setCarouselLink(carouselContent[0].link);
    setShowModal(startSimulation);
  }, [startSimulation]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (autoSaveStatus === 'SAVED') {
      const { status, progress, poll } = projectPollStatus({ project });
      setStatus(status);
      setSimulationProgress(progress);
      if (poll) {
        timer = setInterval(() => getProjectStatus({ project }), 5000);
      } else {
        clearInterval(timer);
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [autoSaveStatus, project.status, startSimulation]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Hub.listen('autosave', ({ payload }) => {
      setAutoSaveStatus(payload.status);
    });
    return () => Hub.remove('autosave');
  }, []);

  useEffect(() => {
    if (view.id !== 'design' || view.progress !== 3) {
      notification.destroy();
    }
    return notification.destroy;
  }, [view.id, view.progress]);

  const Title = () => (
    <div style={{ width: '100%' }}>
      {t('Simulation Progress')}
      <a
        href={carouselLink}
        target="_blank"
        rel="noreferrer"
        style={{ float: 'right', fontSize: '0.8rem' }}
      >
        Visit Site (opens new tab)
      </a>
    </div>
  );

  return (
    <Modal
      title={<Title />}
      visible={showModal}
      onCancel={() => {
        cancelSimulation();
        setShowModal(false);
      }}
      cancelText={t('Cancel Simulation')}
      cancelButtonProps={{ disabled: progressStatus(status) !== 'active' }}
      onOk={() => setShowModal(false)}
      okButtonProps={{ disabled: progressStatus(status) === 'active' }}
      closable={false}
      maskClosable={false}
      width={712}
      bodyStyle={{ padding: '16px' }}
    >
      <Carousel
        autoplay
        autoplaySpeed={6000}
        dotPosition="top"
        afterChange={(index) => setCarouselLink(carouselContent[index].link)}
      >
        {carouselContent.map(({ link, image }, index) => (
          <div key={`slide-${index}`}>
            <img
              src={`./promo/${image}`}
              width="680"
              height="360"
              alt="Mersen Product"
            />
          </div>
        ))}
      </Carousel>
      <div style={{ margin: '0 16px' }}>
        <Progress
          percent={simulationProgress}
          status={progressStatus(status)}
          strokeColor="#ff7f32"
        />
      </div>
    </Modal>
  );
};
