import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Row, Col, Alert } from 'antd';
import { viewState } from '../../state/view';
import { projectState } from '../../state/project';
import { stepErrorState } from '../../state/app';
import { validators } from '../../utils/validators';
import { workflows } from './workflows';

export const Navigation = () => {
  const { t } = useTranslation();
  const [view, setView] = useRecoilState(viewState);
  const stepErrors = useRecoilValue(stepErrorState);
  const project = useRecoilValue(projectState);
  const workflow = workflows[project.type];
  const { progress } = view;

  const getErrorMessage = () => {
    const step = workflow[progress].step;
    if (step === 'solution') {
      return stepErrors
        ? 'Correct all step errors to enable Run Simulation'
        : '';
    } else {
      return validators({ project, step: workflow[progress].step });
    }
  };

  const setSelectedStep = (progress) =>
    setView({
      ...view,
      progress,
      selectedStep: workflow[progress].step,
    });

  const isFirstStep = progress === 0;
  const isLastStep = progress === workflow.length - 1;

  const errorMessage = getErrorMessage();

  return (
    <div className="content-wrapper">
      <div />
      <div>
        <Row
          style={{ marginTop: '16px', height: '40px' }}
          align="middle"
          gutter={16}
        >
          <Col span={4} style={{ textAlign: 'left' }}>
            <Button
              disabled={isFirstStep}
              onClick={() => setSelectedStep(progress - 1)}
              style={{ marginLeft: '16px' }}
            >
              {t('Previous')}
            </Button>
          </Col>
          <Col span={16}>
            {errorMessage && (
              <Alert showIcon message={errorMessage} type="error" />
            )}
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button
              type={isLastStep ? 'default' : 'primary'}
              disabled={isLastStep}
              onClick={() => setSelectedStep(progress + 1)}
              style={{ marginRight: '16px' }}
            >
              {t('Next')}
            </Button>
          </Col>
        </Row>
      </div>
      <div />
    </div>
  );
};
