import React, { useState } from 'react';
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button, PageHeader, Modal, Row, Col, Menu, message } from 'antd';
import {
  UserOutlined,
  UserDeleteOutlined,
  LockOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { createUser, updateUser } from '../../graphql/mutations';
import { initialUserState, userState, isSignedIn } from '../../state/user';
import { initialViewState, viewState } from '../../state/view';
import { initialProjectState, projectState } from '../../state/project';
import { ProfileForm } from './ProfileForm';
import { SecurityForm } from './SecurityForm';
import { SettingsForm } from './SettingsForm';
import { DeleteForm } from './DeleteForm';

export const Profile = () => {
  const { t, i18n } = useTranslation();
  const userIsSignedIn = useRecoilValue(isSignedIn);
  const [user, setUser] = useRecoilState(userState);
  const [showModal, setShowModal] = useState(false);
  const [menuKey, setMenuKey] = useState('profile');
  const [isLoading, setIsLoading] = useState(false);
  const setViewState = useSetRecoilState(viewState);
  const setProjectState = useSetRecoilState(projectState);

  const catchError = (error) => {
    message.error(t('An error occurred'));
    console.error(error);
  };

  const onFinish = (values) => {
    setIsLoading(true);
    // removed readonly records keys
    const { createdAt, updatedAt, owner, projects, ...rest } = user;
    const input = { ...rest, ...values };
    // create new user or update user profile
    const isNew = user.id === undefined;
    API.graphql({
      query: isNew ? createUser : updateUser,
      variables: { input },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
      .then((result) => {
        const data = isNew ? result.data.createUser : result.data.updateUser;
        // remove database created keys from response
        const { createdAt, updatedAt, owner, projects, ...rest } = data;
        setUser(rest);
        i18n.changeLanguage(rest.language.value);
        setIsLoading(false);
        message.success(isNew ? t('Profile created') : t('Profile updated'));
      })
      .catch(catchError);
  };

  const onFinishFailed = (error) => {
    message.error(t('An error occurred'));
    console.log(error);
  };

  const clearSession = () => {
    setUser(initialUserState);
    setProjectState(initialProjectState);
    setViewState(initialViewState);
    setShowModal(false);
  };

  const handleSignOut = () => {
    // ensure no user session
    Auth.signOut()
      .catch((err) => {})
      .finally(clearSession);
  };

  // when fixed use recoil selector useRecoilValue(isAuthorized)
  return userIsSignedIn ? (
    <div className="profile-wrapper">
      <Button
        type="secondary"
        onClick={() => {
          setMenuKey('profile');
          setShowModal(true);
        }}
      >
        {t('Profile')}
      </Button>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={640}
      >
        <PageHeader title={user.email} />
        <Row>
          <Col span={6}>
            <Menu
              mode="vertical"
              style={{ padding: 0, borderRight: 0 }}
              onClick={({ key }) => {
                switch (key) {
                  case 'signout':
                    handleSignOut();
                    break;

                  default:
                    setMenuKey(key);
                }
              }}
              selectedKeys={[menuKey]}
            >
              <Menu.Item key="profile" icon={<UserOutlined />}>
                {t('Profile')}
              </Menu.Item>
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                {t('Settings')}
              </Menu.Item>
              <Menu.Item key="security" icon={<LockOutlined />}>
                {t('Security')}
              </Menu.Item>
              <Menu.Item key="delete" icon={<UserDeleteOutlined />}>
                {t('Delete')}
              </Menu.Item>
              <Menu.Item
                key="signout"
                icon={<LogoutOutlined />}
                style={{ marginTop: '48px' }}
              >
                {t('Sign Out')}
              </Menu.Item>
            </Menu>
          </Col>
          <Col span={18}>
            {menuKey === 'profile' && (
              <ProfileForm
                user={user}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                isLoading={isLoading}
              />
            )}
            {menuKey === 'settings' && (
              <SettingsForm
                user={user}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                isLoading={isLoading}
              />
            )}
            {menuKey === 'security' && (
              <SecurityForm
                user={user}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                isLoading={isLoading}
              />
            )}
            {menuKey === 'delete' && (
              <DeleteForm user={user} clearSession={clearSession} />
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  ) : null;
};
