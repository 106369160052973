import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { ProjectTypeSelect } from './ProjectTypeSelect';
import { UnitsSelect } from './UnitsSelect';

const { Item } = Form;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 18 },
};

export const ProjectForm = ({
  project,
  onFinish,
  onFinishFailed,
  isLoading,
  reset,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isNew = project.id === 'new-project';
  let type = project.type;
  let units = project.units;

  // reset form ensuring selected project is loaded
  useEffect(() => {
    form.resetFields();
  }, [reset, project, form]);

  return (
    <Form
      form={form}
      size="small"
      name="project"
      initialValues={project}
      onFinish={(data) => onFinish({ ...data, type, units })}
      onFinishFailed={onFinishFailed}
      {...layout}
    >
      <Item
        name="name"
        label={t('Name')}
        rules={[{ required: true, message: 'Please input a project name' }]}
      >
        <Input />
      </Item>

      <Item name="description" label={t('Description')}>
        <Input.TextArea />
      </Item>

      <Item label={t('Type')}>
        <ProjectTypeSelect
          name="type"
          value={project.type}
          onChange={(selected) => (type = selected)}
          reset={reset}
          disabled={!isNew}
        />
      </Item>

      <Item label={t('Units')}>
        <UnitsSelect
          name="units"
          value={project.units}
          onChange={(selected) => (units = selected)}
          reset={reset}
          disabled={!isNew}
        />
      </Item>

      <Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          size="medium"
        >
          {t('Save')}
        </Button>
      </Item>
    </Form>
  );
};
