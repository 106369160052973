/*
See: https://github.com/jsteenkamp/react-r-tools/issues/123#issuecomment-692199610

Boundary conditions:
Ambient T: -40C to +60C – default = 40C
Altitude: 0 – 20,000m , default = 0m
Max dimensions (physical envelope):
  Width: 25 – 520mm, default 200mm
  Length: 25 – 1000mm, default 200mm
  Ext Height: 5 - 144 (default 132mm)
  MBF Height: 25 – 144 (default 132mm)
  MBF base thickness: 12.7 - 25 (default 12.7mm)
Max temperature: -39C to +120C (Also by logic: >= 1C above ambient in all conditions) Default = 90C

Forced convection:
Flow rate: default = 3 m/s * area, max= 20 m/s * area, where area = hsk W x hsk H
Velocity: 0 – 20m/s, default 3 m/s
 */

export const measurementUnits = {
  dimension: ['mm', 'in'],
  flowRate: ['m3/min', 'm3/s', 'cfm', 'gpm'], // cfm = ft3/min (convert-units library)
  flowRateLiquid: ['l/min', 'gpm'], // l/min = liters per minute
  flowRateGas: ['m3/min', 'cfm', 'gpm'],
  flowVelocity: ['m/s', 'ft/s'],
  temperature: ['C', 'F'],
  altitude: ['m', 'ft'],
  pressure: ['Pa', 'inH2O', 'psi', 'bar'],
  thermalResistance: ['C/W'],
  thermalImpedance: ['C in2/W'],
  thermalConductivity: ['W/M-K'],
  power: ['W'],
  mass: ['kg'],
};

// these constants must match the enums defined in the GraphQL schema
// javascript does not have enums, we do not need Symbols so just use constants

export const projectType = {
  EXTRUSION: 'EXTRUSION',
  MBF: 'MBF',
  AQUASURF: 'AQUASURF',
  AQUAMAX: 'AQUAMAX',
};

export const projectStatus = {
  INCOMPLETE: 'INCOMPLETE', // Not all project data supplied
  COMPLETE: 'COMPLETE', // Ready to solve
  SOLVING: 'SOLVING', // Busy solving via simulation service
  SOLUTION: 'SOLUTION', // Solved - solution available
  FAILED: 'FAILED', // Error - could not solve
};

export const unitType = {
  TEMPERATURE: 'TEMPERATURE',
  ALTITUDE: 'ALTITUDE',
  DIMENSION: 'DIMENSION',
  PRESSURE: 'PRESSURE',
  RESISTANCE: 'RESISTANCE',
  VELOCITY: 'VELOCITY',
  FLOWRATE: 'FLOWRATE',
  POWER: 'POWER',
  MASS: 'MASS',
};

export const unitSystem = {
  METRIC: 'METRIC',
  AMERICAN: 'AMERICAN',
};

export const airFlowType = {
  NATURALCONVECTION: 'NATURALCONVECTION',
  FORCEDCONVECTION: 'FORCEDCONVECTION',
};

export const naturalConvectionOrientation = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
};

export const forcedConvectionType = {
  FLOWRATE: 'FLOWRATE',
  VELOCITY: 'VELOCITY',
  FAN: 'FAN',
};

export const flowSetup = {
  PUSH: 'PUSH',
  PUSHPULL: 'PUSHPULL',
  IMPINGE: 'IMPINGE',
};

export const interfaceType = {
  NONE: 'NONE',
  MATERIAL: 'MATERIAL',
  THERMALRESISTANCE: 'THERMALRESISTANCE',
  THERMALIMPEDANCE: 'THERMALIMPEDANCE',
  CONDUCTIVITY_THICKNESS: 'CONDUCTIVITY_THICKNESS',
};

export const finOption = {
  SUGGESTED: 'SUGGESTED',
  LOWPRESSUREDROP: 'LOWPRESSUREDROP',
  CUSTOM: 'CUSTOM',
};

export const fluid = {
  WATER: 'WATER',
  OTHER: 'OTHER',
};

export const pipeConfiguration = {
  PARALLEL: 'PARALLEL',
  SERPENTINE: 'SERPENTINE',
};

export const embeddedPosition = {
  CENTERED: 'CENTERED',
  FLUSH: 'FLUSH',
};

// flat, serrated, corrugated, hollow
export const finStyle = {
  F: 'F',
  S: 'S',
  C: 'C',
  H: 'H',
};

export const dimension = {
  type: 'DIMENSION',
  value: 0,
  units: 'mm',
};

export const temperature = {
  type: 'TEMPERATURE',
  value: 0,
  units: 'C',
};

export const altitude = {
  type: 'ALTITUDE',
  value: 0,
  units: 'm',
};

export const flowRate = {
  type: 'FLOWRATE',
  value: 0,
  units: 'm3/s',
};

export const flowRateLiquid = {
  type: 'FLOWRATE',
  value: 0,
  units: 'gpm',
};

export const pressure = {
  type: 'PRESSURE',
  value: 0,
  units: 'Pa',
};

export const power = {
  type: 'POWER',
  value: 0,
  units: 'W',
};

export const mass = {
  type: 'MASS',
  value: 0,
  units: 'kg',
};

export const velocity = {
  type: 'VELOCITY',
  value: 0,
  units: 'm/s',
};

export const sizeValue = {
  width: dimension,
  length: dimension,
  height: dimension,
};

// all defaults and valid ranges are based on METRIC units
// https://github.com/jsteenkamp/react-r-tools/issues/123
const validRanges = {
  boundaryConditions: {
    ambientTemperature: {
      min: { ...temperature, value: -40 },
      max: { ...temperature, value: 60 },
      default: { ...temperature, value: 40 },
    },
    altitude: {
      min: { ...altitude, value: 0 },
      max: { ...altitude, value: 20000 },
      default: { ...altitude, value: 0 },
    },
    maxTemperatureRise: {
      min: { ...temperature, value: -39 },
      max: { ...temperature, value: 120 },
      default: { ...temperature, value: 90 },
    },
    maxPressureDrop: {
      min: { ...pressure, value: 0 },
      max: { ...pressure, value: 1000 },
      default: { ...pressure, value: 250 },
    },
    maxSize: {
      width: {
        min: { ...dimension, value: 25 },
        max: { ...dimension, value: 520 },
        default: { ...dimension, value: 200 },
      },
      length: {
        min: { ...dimension, value: 25 },
        max: { ...dimension, value: 1000 },
        default: { ...dimension, value: 200 },
      },
      height: {
        min: { ...dimension, value: 5 },
        max: { ...dimension, value: 144 },
        default: { ...dimension, value: 132 },
      },
      thickness: {
        min: { ...dimension, value: 12.7 },
        max: { ...dimension, value: 25 },
        default: { ...dimension, value: 12.7 },
      },
    },
  },
  airFlow: {
    // values are m/s, flow rate must be m3/s and
    // calculated using heatsink area (W x H)
    flowRateVelocity: {
      min: { ...velocity, value: 0.006 },
      max: { ...velocity, value: 20 },
      default: { ...velocity, value: 3 },
    },
    // m/s - 0.001 ~ equal to 0
    velocity: {
      min: { ...velocity, value: 0.001 },
      max: { ...velocity, value: 20 },
      default: { ...velocity, value: 3 },
    },
  },
};

export const envelopes = {
  extrusion: validRanges,
  mbf: {
    ...validRanges,
    boundaryConditions: {
      ...validRanges.boundaryConditions,
      maxSize: {
        ...validRanges.boundaryConditions.maxSize,
        height: {
          ...validRanges.boundaryConditions.maxSize.height,
          min: {
            ...validRanges.boundaryConditions.maxSize.height.min,
            value: 25,
          },
        },
      },
    },
  },
  aquasurf: {
    ...validRanges,
    boundaryConditions: {
      ...validRanges.boundaryConditions,
      maxSize: {
        ...validRanges.boundaryConditions.maxSize,
        thickness: {
          ...validRanges.boundaryConditions.maxSize.thickness,
          max: {
            ...validRanges.boundaryConditions.maxSize.thickness.max,
            value: 50.8,
          },
        },
      },
    },
    inletTemperature: {
      WATER: {
        min: { ...temperature, value: 10 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      DIWATER: {
      min: { ...temperature, value: 10 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'EGLYCOL-30': {
        min: { ...temperature, value: -9.52 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'EGLYCOL-40': {
        min: { ...temperature, value: -18.27 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'EGLYCOL-50': {
        min: { ...temperature, value: -28.97 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'EGLYCOL-60': {
        min: { ...temperature, value: -41.63 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'EGLYCOL-70': {
        min: { ...temperature, value: -56.24 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'PGLYCOL-30': {
        min: { ...temperature, value: -7.9 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'PGLYCOL-40': {
        min: { ...temperature, value: -16.6 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'PGLYCOL-50': {
        min: { ...temperature, value: -27.45 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'PGLYCOL-60': {
        min: { ...temperature, value: -40.51 },
        max: { ...temperature, value: 70 },
        default: { ...temperature, value: 30 },
      },
      'PGLYCOL-70': {
        min: { ...temperature, value: -55.75 },
        max: { ...temperature, value: 70 },
      default: { ...temperature, value: 30 },
    },
    },
    flowRate: {
      CU: {
      '0.25': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 1.4 },
          default: { ...flowRateLiquid, value: 1 },
        },
        '0.375': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 3.12 },
        default: { ...flowRateLiquid, value: 2 },
      },
        '0.5': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 6.55 },
          default: { ...flowRateLiquid, value: 3 },
        },
        '0.625': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 11.23 },
          default: { ...flowRateLiquid, value: 6 },
        },
      },
      SS: {
        '0.25': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 2.77 },
          default: { ...flowRateLiquid, value: 1 },
        },
      '0.375': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 6.26 },
        default: { ...flowRateLiquid, value: 3 },
      },
      '0.5': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 13.1 },
        default: { ...flowRateLiquid, value: 6 },
      },
      '0.625': {
          min: { ...flowRateLiquid, value: 0.132 },
          max: { ...flowRateLiquid, value: 22.48 },
          default: { ...flowRateLiquid, value: 11 },
        },
      },
    },
  },
};
