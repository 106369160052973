import { atom, selector } from 'recoil';
import API from '@aws-amplify/api';
import {
  listLibraryExtrusions,
  getLibraryExtrusion,
  listLibraryFans,
} from '../graphql/queries';
import { sizeValue } from './units';

export const libraryState = atom({
  key: 'libraryState',
  default: {
    id: '09424f52-7203-4004-b567-ba4b6df5aa2f',
    extrusions: [],
    fans: [],
    fins: [],
    surfaceFinishes: [],
  },
});

// ----- extrusions -----

export const defaultExtrusion = {
  id: 'new-extrusion',
  partNumber: '0',
  dimensions: sizeValue,
  image: '',
  shapeType: 0,
  baseWidth: 0,
  baseThick: 0,
  finThick: 0,
  finHeight: 0,
  leftOffset: 0,
  rightOffset: 0,
  leftThick: 0,
  rightThick: 0,
  numFins: 0,
  numFinsLeft: 0,
  numFinsRight: 0,
  numOuterFins: 0,
  numInnerFins: 0,
  gap: 0,
  outerGap: 0,
  innerGap: 0,
  areaGain: 0,
};

export const listLibraryExtrusionsQuery = selector({
  key: 'listLibraryExtrusions',
  get: async ({ get }) => {
    const response = await API.graphql({
      query: listLibraryExtrusions,
      variables: {
        filter: null,
        limit: null,
        nextToken: null,
      },
    });
    if (response.error) {
      throw response.error;
    }
    return response.data.listLibraryExtrusions.items;
  },
});

export const getLibraryExtrusionQuery = selector({
  key: 'getLibraryExtrusion',
  get: (id) => async ({ get }) => {
    const response = await API.graphql({
      query: getLibraryExtrusion,
      variables: {
        id,
      },
    });
    if (response.error) {
      throw response.error;
    }
    return response.data.getLibraryExtrusion;
  },
});

// ----- fans -----

export const listLibraryFansQuery = selector({
  key: 'listLibraryFans',
  get: async ({ get }) => {
    const response = await API.graphql({
      query: listLibraryFans,
      variables: {
        filter: null,
        limit: null,
        nextToken: null,
      },
    });
    if (response.error) {
      throw response.error;
    }
    return response.data.listLibraryFans.items;
  },
});
