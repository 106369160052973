import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import API from '@aws-amplify/api';
import { Hub } from '@aws-amplify/core';
import { projectState } from '../../state/project';
import { rtoolsApiEndpoint } from '../../utils/config';
import { estimatorGetRequest } from '../../graphql/queries';
import { validateEntireProject } from '../../utils/validators';
import { Button, Row, Col, Tabs, Space, notification } from 'antd';
import { ProgressSteps } from './ProgressSteps';
import { ProjectSummary } from './ProjectSummary';
import { RunSimulation } from './RunSimulation';
import { SolutionSummary } from './SolutionSummary';
import { Contact } from './Contact';
import { Quote } from './Quote';
import './solution.less';
import { viewState } from '../../state/view';
import { stepErrorState, validEstimateState } from '../../state/app';

const { TabPane } = Tabs;

const statusStep = (status) => {
  switch (status) {
    case 'SOLUTION':
      return 3;

    case 'COMPLETE':
      return 2;

    case 'QUEUED':
    case 'SOLVING':
      return 1;

    case 'INCOMPLETE':
    case 'FAILED':
      return 0;

    default:
      return 0;
  }
};

export const Solution = () => {
  const { t } = useTranslation();
  const [project, setProject] = useRecoilState(projectState);
  const view = useRecoilValue(viewState);
  const hasValidEstimate = useRecoilValue(validEstimateState);
  const hasStepError = useRecoilValue(stepErrorState);
  const errorMessage = validateEntireProject(project);
  const [runSimulation, setRunSimulation] = useState(false);
  const [initSimulation, setInitSimulation] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [progress, setProgress] = useState({
    currentStep: statusStep(project.status),
    error: '',
  });
  const initialSimulationStatus = { message: ``, progress: 0, timeStamps: [] };
  const [autoSaveStatus, setAutoSaveStatus] = useState('SAVED');
  const hasError = !!errorMessage;
  const inValidMBF =
    project.type === 'MBF' &&
    project.status !== 'SOLUTION' &&
    !hasValidEstimate;

  // if project stuck in SOLVING allow restarting simulation
  const disableRunButton =
    autoSaveStatus !== 'SAVED' ||
    project.status === 'SOLVING' ||
    hasError ||
    inValidMBF ||
    hasStepError;

  const runButtonText =
    autoSaveStatus !== 'SAVED'
      ? 'Saving project...'
      : project.status === 'SOLUTION'
      ? 'Rerun Simulation'
      : 'Run Simulation';

  const startSimulation = () => {
    setInitSimulation(false);
    setRunSimulation(false);
    setActiveTabKey('1');
    setProject({
      ...project,
      status: 'SOLVING',
      simulationStatus: initialSimulationStatus,
    });
    API.graphql({
      query: estimatorGetRequest,
      variables: {
        url: `${rtoolsApiEndpoint}/simulation/${project.id}`,
      },
    })
      .then(({ data }) => {
        const result = JSON.parse(data.estimatorGetRequest);
        // response returns estimatorGetRequest.msgId = '44c4b7c7-3c56-4862-9ea7-27fb401f3a9e'
        if (result.msgId) {
          setRunSimulation(true);
        } else {
          setProject({
            ...project,
            status: 'FAILED',
            simulationStatus: initialSimulationStatus,
          });
          setRunSimulation(false);
          console.error('startSimulation', result);
        }
      })
      .catch((error) => {
        setProject({
          ...project,
          status: 'FAILED',
          simulationStatus: initialSimulationStatus,
        });
        setRunSimulation(false);
        console.error('startSimulation', error);
      });
  };

  // todo - how to cancel remote simulation request
  const cancelSimulation = () => {
    setRunSimulation(false);
    setProject({
      ...project,
      status: 'INCOMPLETE',
      simulationStatus: initialSimulationStatus,
    });
  };

  const handleRunButton = () => {
    setProgress({
      ...progress,
      currentStep: 1,
    });
    if (project.status === 'INCOMPLETE') {
      startSimulation();
    } else {
      // use project.star to toggle change for autosave compare
      // project.star intended for user favorites
      setProject({
        ...project,
        star: !project.star,
      });
      setInitSimulation(true);
    }
  };

  const handleContactButton = () => {
    API.graphql({
      query: estimatorGetRequest,
      variables: {
        url: `${rtoolsApiEndpoint}/help/${project.id}`,
      },
    })
      .then((result) => {
        // returns result: {result: 'Help request sent'}
        notification.open({
          message: 'Contact Request Sent',
          description:
            'Your request have been sent to our engineering team. An engineer will contact you within 24 hours.',
          duration: 0,
        });
      })
      .catch((error) => console.log(error));
  };

  const handleQuoteButton = ({
    quantity,
    numblindholes,
    numthroughholes,
    finish,
    otherfinish = '',
  }) => {
    const params = `quantity=${quantity}&numblindholes=${numblindholes}&numthroughholes=${numthroughholes}&finish=${finish}&otherfinish=${encodeURI(
      otherfinish
    )}`;
    API.graphql({
      query: estimatorGetRequest,
      variables: {
        url: `${rtoolsApiEndpoint}/quote/${project.id}?${params}`,
      },
    })
      .then((result) => {
        // returns result: {result: 'Help request sent'}
        notification.open({
          message: 'Quote Request Sent',
          description:
            'Your request have been sent to our engineering team. An engineer will contact you within 24 hours.',
          duration: 0,
        });
      })
      .catch((error) => console.log(error));
  };

  const tabButtons = {
    right: (
      <Space direction="horizontal">
        <Button
          disabled={project.status !== 'SOLVING'}
          onClick={cancelSimulation}
        >
          {t('Cancel Simulation')}
        </Button>
        <Button
          type="primary"
          disabled={disableRunButton}
          onClick={handleRunButton}
        >
          {t(runButtonText)}
        </Button>
      </Space>
    ),
  };

  /*
  The 'startSimulation' function makes the dependencies of useEffect Hook (at line 156)
  change on every render. To fix this, wrap the definition of 'startSimulation' in its
  own useCallback() Hook.
  */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const activeTabKey = project.status === 'SOLUTION' ? '2' : '1';
    setActiveTabKey(activeTabKey);
    if (
      initSimulation &&
      autoSaveStatus === 'SAVED' &&
      ['INCOMPLETE', 'FAILED'].includes(project.status)
    ) {
      startSimulation();
    }
  }, [project.status, initSimulation, autoSaveStatus]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    Hub.listen('autosave', ({ payload }) => {
      setAutoSaveStatus(payload.status);
    });
    return () => Hub.remove('autosave');
  }, []);

  useEffect(() => {
    if (view.id !== 'design' || view.progress !== 3) {
      notification.destroy();
    }
    return notification.destroy;
  }, [view.id, view.progress]);

  return (
    <>
      <ProgressSteps
        progress={{ ...progress, currentStep: statusStep(project.status) }}
      />

      <RunSimulation
        project={project}
        setProject={setProject}
        startSimulation={runSimulation}
        cancelSimulation={cancelSimulation}
      />
      <Row style={{ marginTop: '4px' }}>
        <Col span={18} offset={3}>
          <Row>
            <Tabs
              size="large"
              style={{ width: '100%' }}
              activeKey={activeTabKey}
              tabBarExtraContent={tabButtons}
              onChange={setActiveTabKey}
            >
              <TabPane tab="Summary" key="1" type="card">
                <ProjectSummary handleContactButton={handleContactButton} />
              </TabPane>
              <TabPane
                tab="Solution"
                key="2"
                type="card"
                disabled={project.status !== 'SOLUTION' || hasError}
              >
                <SolutionSummary />
              </TabPane>
              <TabPane tab="Support" key="3">
                <Contact handleContactButton={handleContactButton} />
              </TabPane>
              <TabPane tab="Quote" key="4">
                <Quote handleQuoteButton={handleQuoteButton} />
              </TabPane>
            </Tabs>
          </Row>
        </Col>
      </Row>
    </>
  );
};
