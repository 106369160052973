import React, { useState, useEffect } from 'react';
import API from '@aws-amplify/api';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, message, PageHeader, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { userState } from '../../state/user';
import { estimatorGetRequest } from '../../graphql/queries';
import { rtoolsApiEndpoint } from '../../utils/config';
import { Hub } from '@aws-amplify/core';
import { projectState } from '../../state/project';
import { createProject } from '../../graphql/mutations';
import { format, parseISO } from 'date-fns';
import { de, enUS, fr, hi, ja } from 'date-fns/locale';
// map date-fns locales to i18n locales - maybe rename i18n to match
const locales = { en: enUS, fr, ja, de, hi };

const { Item } = Form;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 18 },
};

const getCopyProject = async ({ url }) => {
  try {
    const response = await API.graphql({
      query: estimatorGetRequest,
      variables: { url },
    });
    return JSON.parse(response.data.estimatorGetRequest);
  } catch (error) {
    console.error('getEstimatorData', error);
    return { error };
  }
};

export const ProjectImport = ({ reset, onSuccess }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const user = useRecoilValue(userState);
  const setProject = useSetRecoilState(projectState);
  const [isLoading, setIsLoading] = useState(false);

  const dateFormatter = (date) =>
    format(date, 'PP', { locale: locales[user.language.value] });

  const catchError = (error) => {
    message.error(t('An error occurred'));
    console.error(error);
  };

  const onFinish = async ({ projectId }) => {
    setIsLoading(true);
    try {
      const res = await getCopyProject({
        url: `${rtoolsApiEndpoint}/copy/${projectId}?userEmail=${user.email}`,
      });
      // valid returns result and id, invalid returns message and name = 'Error'
      const { id, result, name } = res;
      if (name === 'Error') {
        catchError(res.message);
      }

      if (id) {
        message.success(t(`Project copied: ${result}`));
        // use hub to update project list as API not wired into gql subscriptions
        Hub.dispatch('copy-project', {
          event: 'copied',
          data: { result },
          message: result,
        });
      }
      setIsLoading(false);
      form.resetFields();
      onSuccess();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  useEffect(() => {
    form.resetFields();
  }, [reset, form]);

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const reader = new FileReader();
  reader.onload = (event) => {
    const {
      __typename,
      id,
      userId,
      createdAt,
      updatedAt,
      owner,
      simulationProgress,
      viewState,
      description,
      ...rest
    } = JSON.parse(event.target.result);

    const newDescription = `Original project details: Project Id: ${id}, User Id: ${userId}, Created: ${dateFormatter(
      parseISO(createdAt)
    )}, Updated: ${dateFormatter(parseISO(updatedAt))} ${description}`;

    const input = {
      ...rest,
      userId: user.id,
      auth: user.auth,
      description: newDescription,
    };

    setUploading(true);

    API.graphql({ query: createProject, variables: { input } })
      .then((result) => {
        const project = result.data.createProject;
        setProject(project);
        message.success(t(`Project imported: ${project.name}`));
        // use hub to update project list as API not wired into gql subscriptions
        Hub.dispatch('copy-project', {
          event: 'copied',
          data: { result: project.id },
          message: project.id,
        });
        onSuccess();
      })
      .catch((error) => {
        console.error('persistProjectState', error);
        console.info('Project Snapshot', rest);
      })
      .finally(() => {
        setFileList([]);
        setUploading(false);
      });
  };

  const handleUpload = () => reader.readAsText(fileList[0]);

  const props = {
    accept: '.json,application/json',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = [...fileList];
      newFileList.splice(index, 1);
      setFileList(newFileList);
      return {
        fileList: newFileList,
      };
    },
    beforeUpload: (file) => {
      setFileList((fileList) => [...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <PageHeader title={t('Import Project')} />
      <div style={{ margin: '0 0 16px 24px' }}>
        Import a project using the Project ID
      </div>

      <Form
        form={form}
        size="small"
        name="project-import"
        onFinish={(data) => onFinish({ ...data })}
        onFinishFailed={onFinishFailed}
        {...layout}
      >
        <Item
          name="projectId"
          label={t('Project ID')}
          rules={[
            { required: true, message: 'Please input the project Id' },
            { len: 36, message: 'Please enter a valid project Id' },
          ]}
        >
          <Input />
        </Item>

        <Item {...tailLayout}>
          <Button htmlType="submit" loading={isLoading} size="medium">
            {t('Import')}
          </Button>
        </Item>
      </Form>

      <div style={{ margin: '0 0 16px 24px' }}>
        Import a project by uploading the project file
      </div>
      <Item {...tailLayout}>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>

        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </Item>
    </>
  );
};
