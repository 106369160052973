/*
Allowing user to change their email address is not straight forward since
it is tied to their Cognito authentication and typically requires receiving
and submitting confirmation code to validate changes.

https://serverless-stack.com/chapters/allow-users-to-change-their-email.html
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';

const { Item } = Form;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 20 },
};

export const SecurityForm = ({ user, onFinish, onFinishFailed, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Form
      name="security"
      size="small"
      initialValues={user}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      {...layout}
    >
      <Item
        name="email"
        label={t('Email')}
        rules={[
          {
            required: true,
            message: 'Please input your email address',
          },
        ]}
      >
        <Input disabled={true} />
      </Item>

      <Item label={t('Password')}>
        <div>{t('Reset-Password')}</div>
      </Item>

      {user.roles && (
        <>
          <Item label={t('Roles')}>
            <div>{user.roles}</div>
          </Item>
          <Item label={t('User Id')}>
            <div>{user.id}</div>
          </Item>
        </>
      )}

      <Item {...tailLayout}>
        <Button
          type="primary"
          size="medium"
          htmlType="submit"
          loading={isLoading}
          disabled={true}
        >
          {t('Save')}
        </Button>
      </Item>
    </Form>
  );
};
