import { atom } from 'recoil';

export const editProjectState = atom({
  key: 'editProjectState',
  default: true,
});

export const validEstimateState = atom({
  key: 'validEstimate',
  value: false,
});

export const stepErrorState = atom({
  key: 'stepError',
  value: false,
});