import React from 'react';
import './fin-direction.less';

export const FinDirection = ({ label, bidirectional }) => {
  return (
    <div className="fin-direction">
      <div className="arrow arrow-up" />
      <div className="arrow-body">
        <div />
        <div className="label">
          <div className="text">{label}</div>
        </div>
      </div>
      {bidirectional && <div className="arrow arrow-down" />}
    </div>
  );
};
